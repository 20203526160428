import { React, useState } from 'react';
import { Button, H2 } from '../../reusable-components';
import useAgentActions from '../useAgentActions';
import useForm from '../useForm';
import { Wrapper } from './styles';
import { useTranslation } from "react-i18next";
import ConfirmDialog from '../confirmdialog';
import { usePlayer } from '../../player/playerContext';
import fetchApi from '../../backend/fetchApi';


function getDetailRound(roundId, player) {

  let roundsUrl = player && player._links.rounds.href;
  roundsUrl += '/';
  roundsUrl += roundId;
  if (!roundId) {
    return null;
  }
  return fetchApi(roundsUrl).then(response => response.json()).then(r => {return r._embedded.round});
}


const NewSyndicateForm = ({ agent, shallowrounds }) => {

  const { createSyndicate } = useAgentActions(agent)
  const { t } = useTranslation();
  const [response, onResponse] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [player] = usePlayer();

  const callback = () => {

    getDetailRound(inputs.roundId, inputs.player).then((round) => {

      inputs.maxNShares = parseInt(inputs.targetNShares);
      inputs.targetNShares = parseInt(inputs.targetNShares);

      if (round && inputs.targetNShares <= 100) {

        inputs.stakePerShare = round.operatorStakePerRow;

          createSyndicate(inputs).then(resp => {
            onResponse(resp);
            setShowConfirm(true);
          });
      }
      else if (inputs.targetNShares > 100) {
        onResponse("Cannot create syndicates with more than 100 shares");
        setShowConfirm(true);
      }

    });
  }

  const { inputs, handleInputChange, handleSubmit } =
    useForm({ name: "", roundId: "", stakePerShareAmount: "", targetNShares: '', maxNShares: '', player: player }, callback);

  let activeRounds = shallowrounds.filter(({ openTo }) => new Date(openTo) > Date.now())
  const moment = require('moment');
  let locale = t('locale');

  return (
    <div>
      {(showConfirm && <ConfirmDialog text={response} toggle={setShowConfirm} />)}
      <Wrapper>
        <div className="green-div">
          <H2 className="title-margin">{t('create_new_syndicate')}</H2>
          <div className="">
            <div className="">
              <div className="">
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="field">
                    <label className="">{t('name')}</label>
                    <div className="control">
                      <input className="input" type="text" name="name" onChange={handleInputChange} value={inputs.name} required />
                    </div>
                  </div>
                  <div className="field">
                    <label className="">{t('round')}</label>
                    <div className="control">
                      <select className="input" type="text" name="roundId" onChange={handleInputChange} required >
                        <option key={`rounddefault`} value='1'>{t('round')}</option>);
                        {activeRounds.map((round, i) => {
                          const openToFormated = moment(round.openTo).locale(locale).format("YYYY-MM-DD HH:mm")
                          return (<option key={`${i} round`} value={round.id}>{round.name} {openToFormated}</option>);
                        })}
                      </select>

                    </div>
                  </div>
                  <div className="field">
                    <label className="">{t('target_number_of_shares')}</label>
                    <div className="control">
                      <input className="input" type="number" name="targetNShares" onChange={handleInputChange} value={inputs.targetNShares} />
                    </div>
                  </div>
                  <Button className="button-margin" type="submit">{t('create_new_syndicate')}</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}

export default NewSyndicateForm;