import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const ProgressWrapper = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;  
  color: black;
`;

const ProgressWrapperSmall = styled(ProgressWrapper)`
  height: 100vh;
`;

const Progress = ({isSmallScreen = false}) => {

    if (isSmallScreen) {
        return (
            <ProgressWrapperSmall>
                <CircularProgress color="inherit"/>
            </ProgressWrapperSmall>
        );
    }

    return (
        <ProgressWrapper>
            <CircularProgress color="inherit"/>
        </ProgressWrapper>);
};

export default Progress;
