import React from 'react';
import { CouponInfoWrapper } from './styles.js';
import { TicketIcon } from '../../../reusable-components.js';
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive/src";
import { getReducedFiendlyName, getRoundName } from '../../../common/RoundHelper.js';
import { Link } from 'react-router-dom';
import { PoolXNumberFormat } from '../../../common/CurrencyFormatter.js';

export default function CouponInfo({ round, couponShare, isRightToLeft }) {
  const { t } = useTranslation();

  if (!couponShare || !round) {
    return <></>;
  }

  const { stake, coupon, createdAt, agentFee, fee } = couponShare || {};
  const { operatorStakePerRow, openTo} = round || {};
  const { totalRows = 0 } = coupon || {};

  let totAmount = 0;

    if (stake != null) {
        totAmount += Number(stake.amount)
    }

    if (fee != null) {
        totAmount += Number(fee.amount)
    }

    if (agentFee != null) {
        totAmount += Number(agentFee.amount)
    }

  let roundName = getRoundName(round, t);

  const downloadTxtFile = (coupon) => {

    let outString = "";

    outString += coupon.roundId.toString() + '\r\n';

    coupon.coupon.bets.forEach(bet => {
      bet.rows.forEach(row => {
        outString += 'E,';
        row.signs.forEach(sign => {
          let text = "1";
          if (sign === "TWO") {
            text = "2";
          }
          if (sign === "X") {
            text = "X";
          }
          outString += text + ',';
        });
        outString = outString.substring(0, outString.length - 1);
        outString += '\r\n';
      });
    });
    const element = document.createElement("a");
    const file = new Blob([outString], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'poolx_rows_' + coupon.id + '.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const intlOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  const datef = Intl.DateTimeFormat('sv-SV', intlOptions);
  const couponBets = coupon.coupon.bets;

  let betType = t('single_rows');
  let reducedSystemName = "";
  if (couponBets.length === 1 && couponBets[0].system != null) {
    let name = couponBets[0].system.type;
    if (name === "MATHEMATICAL") {
      betType = t('mathematical_system')
    }
    if (name === "REDUCED") {
      betType = t('reduced_system')
    }
    reducedSystemName = getReducedFiendlyName(couponBets[0].system.reducedSystemId);
  }

  const share = Math.round(couponShare.stakeUnits / couponShare.totalStakeUnits * 100);

  

  let locale = t('locale');
  const nf = PoolXNumberFormat(locale, { style: 'currency', currency: operatorStakePerRow.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });  

  return (
    <CouponInfoWrapper>
      <div className="orange-div">
        {TicketIcon}
        <span className="margin-top">{isRightToLeft ? (`${t('rows')} ${totalRows}`) : (`${totalRows} ${t('rows')}`)}</span>
        
        <div className="flex-div">
          <span>{isRightToLeft ? `${nf.format(totAmount)} :${t('total_cost')}` : `${t('total_cost')}: ${nf.format(totAmount)}`}</span>
          <span>{isRightToLeft ? `${datef.format(new Date(createdAt))} :${t('paid')}` : `${t('paid')}: ${datef.format(new Date(createdAt))}`}</span>
        </div>
        <div className="flex-div">
          <span>{isRightToLeft ? `${roundName} :${t('round')}` : `${t('round')}: ${roundName}`}</span>
          <span>{isRightToLeft ? `${datef.format(new Date(openTo))} :${t('started')}` : `${t('started')}: ${datef.format(new Date(openTo))}`}</span>
        </div>
        {(share < 100 && <div className="flex-div">
          <span>{t('share_of_syndicate')}: {share}%</span>
        </div>)}
        <div className="flex-div">
          <span>{betType}</span>
          <span>{reducedSystemName}</span>
        </div>
        <div className="flex-div">
          <span>{isRightToLeft ?
            `${couponShare.id} :${t('id')}`
            : `${t('id')}: ${couponShare.id}`}
          </span>
        </div>
        <MediaQuery minWidth={950}>
          <div className="button-div">
            <button onClick={() => downloadTxtFile(coupon)}>
              {t('print')}
            </button>
            <div>
              <Link to={"/advancedCouponView/" + couponShare.id}>
                <button>
                  {t('show_rows')}
                </button>
              </Link>
            </div>
          </div>
        </MediaQuery>
      </div>
    </CouponInfoWrapper>
  );
}