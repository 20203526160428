import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: var(--background_1);
  color: var(--text-black-dark-background);

  .menu-item {
    text-align: center;
    padding: 12px 10px;
    cursor: pointer;
  }

  .menu {
    display: flex;
    flex-direction: var(--flex-direction); 
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 13px;
  }  

  .fixture-component {
    overflow-y: auto;
    background-color: var(--fixtureinformation-background);
  }

  ul {
    display: flex;
    justify-content: center;
    padding: 0 64px;
    list-style: none;
    font-size: 13px;
  }

  ul li {
    cursor: pointer;
    padding: 0 20px;
  }

  .underline-menu {
    text-decoration: underline;
  }

  li:hover {
    text-decoration: underline;
  }
`;