import React, { useState } from 'react';
import keyBy from "lodash/keyBy";
import useAgents from "./useAgents";
import useAgentActions from "./useAgentActions";


const useAgent = id => {
  let [agents, setAgents] = useState(undefined);  
  let [loading, setLoading] = useState(false);
  useAgents(setAgents, setLoading, loading);
  const agentsById = React.useMemo(() => keyBy(agents, 'id'), [agents])

  const agent = agentsById[id];

  const actions = useAgentActions(agent)

  return [agent && {
    ...actions,
    ...agent
  }];
}

export default useAgent;