import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Button } from '../../../../../reusable-components';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Progress from '../../../../../common/progress';
import { operatorAdviceScrollToTop } from "../../../../../App";
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { PoolXNumberFormat } from '../../../../../common/CurrencyFormatter';
import { themeC } from '../../../../../theme/themehelper';

const getErrorMessage = (errorCode, phrases) => {
  if (errorCode === 102) {
    return phrases.insufficientFunds;
  }
  if (errorCode === 105) {
    return phrases.gamingLimitsReached;
  }

  return '';
}

const RenderSwitch = ({
  submitting,
  submitFailed,
  amount,
  currency,
  phrases,
  handleClick,
  submitError,
  t
}) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  let locale = t('locale');
  const nf = PoolXNumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });

  if (submitFailed) {
    const errorMessage = getErrorMessage(submitError.errorCode, phrases);
    return (
      <div>
        <p style={{ marginTop: '30px', textAlign: 'center' }}>
          {phrases.unableToCompletePurchase}, {phrases.errorCode}: {submitError.errorCode}
        </p>
        <p style={{ textAlign: 'center' }}> {errorMessage}</p>
      </div>
    );
  }

  if (submitting) {
    return <Progress isSmallScreen={false} />
  }


  return (
    <>
      <span>{phrases.confirmPurchase} {nf.format(amount)}?</span>
      <Button type="submit" width="200px" onClick={handleClick}>{phrases.pay}</Button>
    </>
  );
}

const ConfirmPayment = ({
  amount,
  currency,
  toggle,
  submitting,
  submitFailed,
  resetValues,
  submitError
}) => {
  const [shouldRenderConfirm, setShouldRenderConfirm] = useState(false);
  const { t } = useTranslation();
  const phrases = {
    confirmPurchase: t('do_you_want_to_place_bet'),
    pay: t('pay'),
    insufficientFunds: t('insufficient_funds'),
    gamingLimitsReached: t('gaming_limits_reached'),
    unableToCompletePurchase: t('unable_to_complete_purchase'),
    errorCode: t('error_code')
  };

  const handleClose = () => {
    resetValues();
    toggle(false);
  }

  const handleClick = () => {
    setShouldRenderConfirm(true);
    operatorAdviceScrollToTop(500);
  }

  return (
    <Wrapper>
      <div className="onclick-div" onClick={() => { }} />
      <div className="confirm-window">
        <div className="flex-end">
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>

        <div className='icon-holder'>
          {submitFailed ?
            <ErrorOutlineIcon fontSize='inherit' /> :
            (window.theme === themeC.Kung || window.theme === themeC.Dbet) ?
              //<Grid>{KungReceiptIcon}</Grid> :
              <ReceiptOutlinedIcon fontSize='inherit' /> :
              <ReceiptOutlinedIcon fontSize='inherit' />
          }
        </div>
        {RenderSwitch({
          submitting,
          submitFailed,
          amount,
          currency,
          phrases,
          handleClick,
          submitError,
          t
        })}

      </div>
    </Wrapper>
  );
}

export default ConfirmPayment;