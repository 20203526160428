import NavBar from '../navbar';
import SyndicateInfoBox from './syndicateinfobox';
import { Grid, SxProps, Theme } from '@mui/material';
import useSyndicates from './useSyndicates';
import useShallowRounds from '../rounds/useShallowRounds';
import { usePlayer } from '../player/playerContext';
import SyndicateList from './syndicatelist';

export default function PlayAgent({ agentId }: { agentId: number }) {

  const [syndicates = []] = useSyndicates();
  const [shallowRoundsResp] = useShallowRounds(true, false);
  
  let shallowRounds = shallowRoundsResp?.rounds;

  let playerResp: any = usePlayer();
  if (!playerResp[1].agentSyndicateBetting) {
    return <div />;
  }

  const theme = getComputedStyle(document.documentElement).getPropertyValue("--theme-name");

  if (!syndicates || !shallowRounds) return null;
  const filteredRounds = shallowRounds.filter(({ status, openTo }) => status === "ACTIVE" && new Date(openTo) > new Date(Date.now()));
  let filteredSyndicates = syndicates.filter(({ roundId }) => filteredRounds.filter(({ id }) => id === roundId.toString()).length > 0);
  let agentPage = false;

  if (agentId !== undefined && agentId !== 0) {
    filteredSyndicates = filteredSyndicates.filter((s) => s.agentId === agentId);
    agentPage = true;
  }

  if (!filteredRounds || !filteredSyndicates) return <div />;

  const style: SxProps<Theme> = {
    background: "var(--background_1)",
    paddingTop: "20px"
  }


  return (
    <>
      <NavBar whichToUnderline="2" balanceUpdateId="0" />
      <SyndicateInfoBox theme={theme} />
      <Grid sx={style} container direction="column">
        <SyndicateList syndicates={filteredSyndicates} shallowRounds={filteredRounds} agentPage={agentPage} theme={theme} />
      </Grid>
    </>
  );
}