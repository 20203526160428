import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background: rgba(6,6,6,0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 90;
  
  span {
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0;
  }

  .confirm-window {
    background: var(--background_4);
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 17px 15px;
    width: 380px;
    min-height: 160px;
    position: relative;
    z-index: 99;
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .close-icon {
    cursor: pointer;
  }

  .onclick-div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;