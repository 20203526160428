import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background: rgba(6,6,6,0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
 
    .onclick-div {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    

  @media screen and (min-width: 951px) {
    z-index: 90;
    height: 100%;
    width: 100%;

    .confirm-window {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: var(--background_2);

      padding: 17px 15px;
      width: 380px;
      min-height: 160px;
      position: absolute;
      top: 250px;
      z-index: 99;
    }

    .flex-end {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 950px) {
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 75px;
    left: 0;
    position: absolute;

    .onclick-div {
      background-color: var(--background_2);
    }
    
    .confirm-window {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 120px;
      z-index: 1001;
    }
  } 
  
  span {
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0;
  }

  .close-icon {
    cursor: pointer;
  }

  .cancel-anchor {
    margin-top: 18px;
    text-decoration: underline;
    cursor: pointer;
  }
  
`;