import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Grid, Skeleton, SxProps, Theme, useMediaQuery } from '@mui/material';
import { SlimCouponRowStyles } from "./slimfixturedetail";
import { Fixture } from "../models/fixture";
import { FixtureDetail } from "../models/fixturedetail";
import { getDrawText } from "./couponhelpers";
import { formatFraction } from "../common/RoundHelper";

function MatchInformation({ fixtureDetails, fixture, isRightToLeft }: { fixtureDetails: FixtureDetail, fixture: Fixture, isRightToLeft: boolean }) {

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 950px)');

  if (!fixtureDetails) {
    return (
      <Grid container sx={{ margin: "0px", padding: "20px" }} justifyContent="center">
        <Skeleton variant="rectangular" width="100%" height="200px" component="table" />
      </Grid>
    );
  }

  const venue = fixtureDetails.fixture.meta.venueData;

  const startingDateTime = new Date(fixtureDetails.fixture.startingAt);
  let locale = t('locale');

  let listStyle: SxProps<Theme> = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  if (!isSmallScreen) {
    listStyle = {
      ...listStyle, ...{
        maxWidth: "400px",
        marginBottom: "100px",
        marginTop: "20px"
      }
    };
  }

  return (
    <Grid container direction="column" sx={listStyle}>
      <Grid container sx={SlimCouponRowStyles.rowOdd}>
        <span>{t('date')}</span>
        <span><Moment format={"yyyy-MM-DD"} locale={locale}>{startingDateTime}</Moment></span>
      </Grid>
      <Grid container sx={SlimCouponRowStyles.rowEven}>
        <span>{t('event_kickoff')}</span>
        <span><Moment format={"HH:mm"} locale={locale}>{startingDateTime}</Moment></span>
      </Grid>
      <Grid container sx={SlimCouponRowStyles.rowOdd}>
        <span>{t('league')}</span>
        <span>{fixtureDetails?.league?.name}</span>
      </Grid>
      {venue &&
        <Grid container sx={SlimCouponRowStyles.rowEven}>
          <span>{t('venue')}</span>
          <span>{venue.name}</span>
        </Grid>
      }

      {!isSmallScreen &&
        <Grid container sx={venue ? SlimCouponRowStyles.rowOdd : SlimCouponRowStyles.rowEven}>
          <span>{t('row_distribution')}</span>
          <span>1: {formatFraction(fixture.signs.ONE?.rowsFraction ?? 0)}</span>
          <span>X: {formatFraction(fixture.signs.X?.rowsFraction ?? 0)}</span>
          <span>2: {formatFraction(fixture.signs.TWO?.rowsFraction ?? 0)}</span>
        </Grid>}

      {fixture.draw &&
        <Grid container sx={(venue || (!isSmallScreen && !venue)) ? SlimCouponRowStyles.rowOdd : SlimCouponRowStyles.rowEven}>
          <span>{t('draw_disclaimer')}</span>
          <span>{getDrawText(fixture.draw, t)}</span>
        </Grid>}

    </Grid>


  );
}

export default MatchInformation;
