export const RULES_SV = {    
    faq: {
        title: "FAQ",
        q1: {
            question: "Vad är poolbetting?",
            answer: "Poolbetting innebär att spelarnas insatser läggs samman i en pott. Efter att en avgift dragits delas alla medel mellan de vinnande spelarna. Du vinner om du har valt rätt resultat för matcherna i omgången."
        },
        q2: {
            question: "Vad är en omgång?",
            answer: "En omgång är en samling matcher där du ska välja utfall. Matcherna i en omgång startar ofta samma dag. En omgång kan innehålla 8, 12 eller 13 matcher."
        },
        q3: {
            question: "Hur många vinstgrupper finns det?",
            answer: "Det finns 3 vinstgrupper i omgångar med 12 eller 13 matcher. Det finns 1 vinstgrupp i omgångar med 8 matcher."
        },
        q4: {
            question: "Hur lägger jag ett spel?",
            answer: "Välj utfall i varje match i omgången. Om du vill kan du välja mer än ett utfall i varje match. Insatsen ökar då i enlighet med detta."
        },
        q5: {
            question: "Hur vinner jag?",
            answer: "Om du har så många korrekta val som krävs enligt vinstplanen vinner du. Det kan finnas en eller flera vinstgrupper i omgången. Alla vinnare i en vinstgrupp delar på potten i den gruppen."
        },
        q6: {
            question: "Vad är Pro Pick?",
            answer: "Pro Pick är ett automatiserat sätt att lägga ditt spel. En robot väljer då ut resultatet i matcherna åt dig till en bestämd kostnad. Du kan låta roboten välja mer sannolika spel eller mindre sannolika spel med chans att vinna mer."
        }
    },
    terms: {
        title: "Regler",
        p1: {
            title:"Lägga spel",
            p11:"Operatören agerar som agent för PoolX och alla spel ('Spel') placeras direkt i PoolX potter via operatörens webbplats ('Webbplats'). I de fall där spel inte når potten, får berörda operatörskunder ('Spelare') sina insatser tillbaka, och varken operatören eller PoolX är ansvariga för någon förlust av vinster som skulle ha betalats ut om spelet nått potten. Operatören håller spelarnas medel och ansvarar för att betala ut vinster. Eventuella frågor du har om din spelaktivitet i PoolX potter ska riktas till operatören. I den mån operatören inte kan lösa ditt ärende, kommer operatören att kontakta PoolX.",
            p12:"Det är ditt ansvar att kontrollera att de spelinstruktioner du lämnar in är korrekta innan du bekräftar spelet. PoolX förbehåller sig rätten att neka placering av ett spel av vilken anledning som helst.",
            p13:"Om det uppstår en oenighet om detaljer eller status för ett spel, kommer PoolX register att vara den primära källan för bevis om spelets detaljer/status.",
            p14:"PoolX kan välja att tillfälligt stoppa spel på en runda när som helst. PoolX förbehåller sig också rätten att ogiltigförklara en pott i sin helhet eller något/några spel: för att korrigera uppenbara fel, för att upprätthålla integriteten och rättvisan i PoolX potter, om det har skett en förändring i formatet eller evenemangen i en pott eller om det tror att något spel har placerats i strid med dessa spelregler eller reglerna för ett idrottsförbund eller annan relevant professionell organisation.",
            p15:"Spelare spelar på utfallet av flera relaterade evenemang som förvalts av PoolX (Matcher).",
            p16:"Matcherna väljs av PoolX enligt eget gottfinnande.",
            p17:"Spelare kan välja att placera ett eller flera spel, där varje spel består av minst ett val i varje match. Varje spel består av tre val. Hemmavinst ('1'), Oavgjort ('X') eller Bortavinst ('2'). Varje spel identifieras med ett unikt referensnummer när spelet accepteras.",
            p18:"För varje spel kan en spelare göra ett eller flera alternativ av möjliga utfall. Till exempel, om du väljer 'Hemmavinst' och 'Oavgjort' i spel 1 och Hemmavinst i alla andra spel – detta skulle utgöra två rader. Varje rad har en full enhetskostnad, till exempel $0.1 (eller motsvarande valutavärde), och PoolX summerar antalet rader multiplicerat med insatsen per rad för att visa det totala beloppet satsat på ditt spel.",
            p19:"Alla spel placeras för det korrekta resultatet under ordinarie speltid enligt matchtjänstemän, inklusive stopptid, men exklusive förlängning, eventuella golden point-perioder, straffar eller liknande (om de inträffar).",
            p110:"Med förbehåll för andra bestämmelser i denna paragraf 1, kommer alla spel att avgöras baserat på det officiella resultatet av det relevanta evenemanget, oavsett eventuell senare diskvalificering eller ändring av resultatet.",
            p111:"Vid osäkerhet kring något officiellt resultat, förbehåller sig PoolX rätten att tillfälligt stoppa reglering av någon pott tills osäkerheten kan lösas till rimlig belåtenhet.",
            p112:"PoolX förbehåller sig rätten att ompröva regleringen av en pott om den har reglerats felaktigt (t.ex. ett mänskligt eller tekniskt fel). Om PoolX omreglerar en pott kan detta leda till ändringar i spelarnas kontosaldon för att återspegla förändringar i pottregleringen.",
            p113:"Oaktat något annat i dessa spelregler, anses en pott vara slutgiltigt reglerad efter 72 timmar.",
            p114:"Om en match inte spelas klart – det vill säga full speltid (t.ex. 90 minuter för fotboll), enligt matchtjänstemän, plus eventuell stopptid, inom 4 timmar efter den senaste schemalagda starttiden för någon match på kupongen, kommer utfallet att avgöras genom lottning.",
            p115:"Lottningen kommer att ske med sannolikheter för varje utfall i matchen. Dessa sannolikheter kan hittas under speldetaljerna på PoolX webbplats. Sannolikheterna definieras som: sannolikheten för hemmavinst, sannolikheten för att matchen slutar lika och sannolikheten för bortavinst och publiceras när omgången skapas. Efter detta ändras de aldrig.",
            p116a:"Det slumpmässiga talet som används i lottningen tas från den första matchen i ordning i omgången som inte är föremål för lottning och som inte används i någon annan lottning i omgången. Sista siffran i minuten för något av följande händelser används:",
            p116b:"För fotbollsmatcher:\n\nMål\nGult kort\nRött kort\n\nAlla andra sporter:\n\nMål",
            p116c:"Tiden som används kommer vara den minut som fastställs av arrangörsorganet för turneringen som spelet ingår i. \n\nFör fotboll och bandy matcher kommer händelser i tilläggstid i första halvlek att betraktas som minut 45 och händelser i tilläggstid i andra halvlek att betraktas som minut 90. \n\nSiffran kommer vara mellan 0 och 9. Denna siffra divideras med 10 för att ge ett tal mellan 0 och 1. Detta tal definieras som Slumptal.",
            p116d:"Resultatet av spelet i lottningen avgörs genom:",
            p116e:"Om Slumptalet är mindre än sannolikheten för hemmavinst: \n\tResultatet av spelet är Hemmavinst \nOm Slumptalet är mindre än sannolikheten för hemmavinst + sannolikheten för att matchen slutar lika: \n\tResultatet av spelet är oavgjort\nAnnars:\n\t Resultatet av spelet är Bortavinst",
            p117:"Lottningarna för omgången genomförs i samma ordning som spelen visas i omgången. (Om både spel 3 och 6 måste lottas, lottas spel 3 först med händelsen från spel 1. Spel 6 lottas senare med händelsen från spel 2).",
            p118:"Om mer än hälften av matcherna i en omgång måste lottas, kommer omgången att annulleras och insatserna återbetalas.",
            p119:"Om den schemalagda spelplatsen för en match ändras efter att rundan har skapats av PoolX, kommer matchen att lottas endast om den nya spelplatsen är hemmaarenan för det ursprungliga bortalaget. För matcher som spelas på neutral plan gäller spelet oavsett vilket lag som listas som hemmalag.",            
            p120:"PoolX tar inget ansvar för skrivfel, överföringsfel och/eller utvärderingsfel. PoolX tar inte heller något ansvar för felaktighet eller ofullständighet eller felaktighet i informationen som tillhandahålls via Webbplatsen, inklusive (utan begränsning) några livesiffror och resultat.",
            p121:"Om det finns någon motsägelsefullt i dessa spelregler mellan en bestämmelse av allmän tillämpning och en bestämmelse som gäller en specifik sport eller en specifik speltyp, kommer den senare att ha företräde."
        },
        p2: {
            title:"Vinster",
            p21:"Alla spel är poolspel där vinster bestäms genom att hänvisa till de satsade pengarna och antalet vinnande rader i potten.",
            p22:"Varje pott kommer att ha en eller flera vinstgrupper. Var och en av dessa grupper har specificerat hur många korrekta val ett spel behöver för att kvalificera sig som vinnare i den gruppen.",
            p23:"Medlen i en vinstgrupp delas mellan antalet korrekta rader i den vinstgruppen. Om det inte finns några vinnare i en vinstgrupp överförs medlen i den gruppen till nästa vinstgrupp i ordning med mindre och mindre erforderliga korrekta val.",
            p24:"Om vinsten per vinnare i en vinstgrupp är under minimivinsten, annulleras den gruppen och medlen överförs till den vinstgrupp som kräver flest korrekta val.",
            p25:"Minimivinsten definieras som 1 EUR. Om du placerar spel i andra valutor varierar minsta insatsen beroende på valutakurs.",
            p26:"Om medel återstår efter att åtgärderna i 2.3 och 2.4 har tillämpats överförs de till Pottfonden. Pottfonden kommer att användas för att öka potten i kommande omgångar.",
            p27:"20% av de satsade medlen i potten dras av som en rake. De återstående 80% delas mellan vinstgrupperna som anges på den specifika omgången på PoolX webbplats.",
            amendment:"Datum för senaste ändring: 25 oktober 2025"            
        }
    }
}