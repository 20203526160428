import styled from 'styled-components';

export const ProPickWrapper = styled.div`
  .grid-wrapper {
    background: var(--background_2);
    padding: 20px;
    margin: 10px 0 16px 0;
  }
  
  .centered-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    width: 100%;
    margin-top: 18px;
    text-align: center;
  }

  .grid-wrapper .bold {
    text-align: center;
  }

  .system.cursor {
    text-align: center;    
  }

  .mobile-top-elem {
    width: 100px;
  }

  .mobile-top-elem span {
    display: flex;
  }

  .bold {
    color: var(--text-black);
  }

  .close-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 26px;
    cursor: pointer;
  }
  
  .bold {
    font-weight: bold;
  }
  
  @media screen and (max-width: 950px) {
    position: absolute;
    z-index: 1000;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    margin-top: var(--pro-pick-margin-top);
    background: var(--background_2);
    
    .flex {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  @media screen and (min-width: 951px) {
    position: absolute;
    top: 250px;
    
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .no-system {
      padding-left: 20px;
    }

    .system {
    }
    
    .grid-wrapper {
      width: 310px;
    }
  }
  
  .grid {
    display: grid;
    //grid-template-columns: 33% 3
    grid-template-columns: 50% 50%;
    gap: 4px;
    margin-top: 0px;
  }

  .system {
    border: solid 1px var(--off-black);
    font-weight: bold;
    padding: 11px 11px 9px 11px;
  }

  .cursor {
    cursor: pointer;
  }

  .selected {
    background: var(--background_4);
  }

  .no-system {
    height: 50px;
    width: 330px;
    display: flex;
    align-items: center;
    background: var(--off-black);
    color: var(--text-black);
  }

  #go-back-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    font-weight: 600;
    cursor: pointer;
    padding-left: 8px;
  }

  #go-back-text {
    padding-left: 8px;
  }
`;