import { formatStringCurrency } from "../common/CurrencyFormatter";
import { getDateString, getJackpot, getRoundName } from "../common/RoundHelper";
import useDetailRound from "../rounds/useDetailRound";
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import pridePick13 from '../images/PridePick13.png';
import pridePick12 from '../images/PridePick12.png';
import pridePick8 from '../images/PridePick8.png';
import pridePick13Logo from '../images/opeimujackpot13.png';
import pridePick12Logo from '../images/opeimujackpot12.png'; 
import pridePick8Logo from '../images/opeimujackpot8.png';
import leoPick13 from '../images/LeoPick13.png';
import leoPick12 from '../images/LeoPick12.png';
import leoPick8 from '../images/LeoPick8.png';
import CountdownTimer from "../common/CountdownTimer";


function RoundBoxSlim({ round, t }) {
    const link = `/rounds/${round.id}`;

    const detailRoundResponse = useDetailRound(round.id);

    const turnoverAmount = round.operatorTurnover ? round.operatorTurnover.amount : 'NA';
    const turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
    const dateString = getDateString(round, t);

    const smallText = {
        fontSize: "14px",
        lineHeight: "21px",
        color: "#000000",
        fontWeight: "400",
        fontFamily: 'var(--font-title)'
    };

    const smallTitle = {
        fontSize: "16px",
        color: "#000000",
        lineHeight: "20px",
        fontWeight: "700",
        fontFamily: 'var(--font-title)',
    }

    let jackpot = getJackpot(turnoverCurrency, detailRoundResponse.round);

    let roundName = getRoundName(round, t);

    let roundImage = pridePick8;
    let roundLogo = pridePick8Logo;
    let strokeColor = "#FFB183";
    let borderRadius = "12px";

    if (roundName.indexOf("13") > 0) {
        roundImage = pridePick13;
        roundLogo = pridePick13Logo;
        strokeColor = "#FFD700";
    }

    if (roundName.indexOf("12") > 0) {
        roundImage = pridePick12;
        roundLogo = pridePick12Logo;
        strokeColor = "#DCDCDC";
    }

    roundImage = `url(${roundImage})`;

    let strokewidth = "1px";

    if (window.theme === "tomato") {
        strokewidth = "0px";
        borderRadius = "0px";
        roundLogo = leoPick8;
        roundImage = 'linear-gradient(0deg,#17CB6F,#1DAB63)';
        if (roundName.indexOf("13") > 0) {
            roundLogo = leoPick13;
            roundImage = 'linear-gradient(0deg,#00A6FF,#0085FF)';
        }

        if (roundName.indexOf("12") > 0) {
            roundLogo = leoPick12;
            roundImage = 'linear-gradient(0deg,#EE44EE,#CC00FF)';
        }
    }

    const start = new Date(round.openTo);
    const cutoff = new Date(Date.now());
    cutoff.setHours(cutoff.getHours() + 5);

    let showCountDown = start < cutoff;

    const reportSelection = () => {

        window.gtag("event", "select_content", {
            content_type: "round",
            content_id: "round_box"
        });
    }

    return (

        <Link to={link} style={{ textDecoration: 'none' }} >
            <div onClick={() => reportSelection()}>
                <Grid container alignItems="flex-start" direction="column" sx={{
                    width: "360px", margin: '7px', borderRadius: borderRadius,
                    backgroundImage: roundImage
                }}>


                    <Grid container justifyContent="center" sx={{ marginTop: "11px", marginBottom: "11px" }}>
                        <Grid item sx={{
                            width: "145px",
                            height: "71px",
                            backgroundImage: `url(${roundLogo})`,
                            backgroundPosition : 'center',
                            backgroundSize: 'contain'
                        }}>
                        </Grid>
                    </Grid>

                    {jackpot &&
                        <Grid container alignItems="center" direction="column" sx={{ marginLeft: "0px" }}>
                            <Typography sx={{
                                fontSize: "38px",
                                color: "#1C1818",
                                lineHeight: "57px",
                                fontWeight: "900",
                                fontFamily: 'var(--font-title)',
                                WebkitTextStrokeWidth: strokewidth,
                                WebkitTextStrokeColor: strokeColor
                            }}>
                                {formatStringCurrency(jackpot, turnoverCurrency, t, true)}
                            </Typography>
                            <Typography sx={{
                                fontSize: "16px",
                                color: "#000000",
                                lineHeight: "24px",
                                fontWeight: "600",
                                fontFamily: 'var(--font-title)',
                                marginTop: "-10px"
                            }}>{t('jackpot')}</Typography>
                        </Grid>
                    }

                    <Grid container direction="row" justifyContent="space-evenly" sx={{ marginTop: "5px", marginBottom: "11px" }}>
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Typography sx={smallText}>{t('turnover')}</Typography>
                                <Typography sx={smallTitle}>{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" direction="column">
                                <Typography sx={smallText}>{t('starts')}</Typography>
                                {
                                    showCountDown ?
                                        <CountdownTimer width="68px" targetTime={new Date(round.openTo)} fontSize="16px"/> :
                                        <Typography sx={smallTitle}>{dateString}</Typography>
                                }
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </div>
        </Link>

    );
}

export default RoundBoxSlim;