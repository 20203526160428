export const DbetEightIcon = (height = 65, width = 48) => (
    <svg width={width} height={height} viewBox={"0 0 48 65"} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M47.6834 45.1293C47.6834 47.8593 47.0642 50.3782 45.8259 52.686C44.5875 54.9938 42.8848 56.9921 40.7177 58.6807C38.5787 
                60.3412 36.0739 61.6359 33.2032 62.5646C30.3325 63.5215 27.2647 64 24 64C20.7353 64 17.6675 63.5215 14.7968 62.5646C11.9261 61.6359 9.40721 
                60.3412 7.2401 58.6807C5.10114 56.9921 3.41249 54.9938 2.17414 52.686C0.935793 50.3782 0.31662 47.8593 0.31662 45.1293C0.31662 43.5532 0.527702 
                42.0193 0.949865 40.5277C1.37203 39.0079 1.97713 37.5866 2.76517 36.2639C3.58135 34.9411 4.55233 33.7309 5.6781 32.6332C6.80387 31.5075 8.07036 
                30.5506 9.47757 29.7625C8.6051 29.0026 7.81706 28.1302 7.11345 27.1451C6.43799 26.1601 5.84696 25.1187 5.34037 24.0211C4.86191 22.9235 4.49604 
                21.7977 4.24274 20.6438C3.98944 19.4617 3.86279 18.2938 3.86279 17.1398C3.86279 14.7757 4.38346 12.5664 5.4248 10.5119C6.49428 8.4292 7.94371 6.6139 
                9.77308 5.06596C11.6025 3.48989 13.7414 2.25154 16.19 1.35092C18.6385 0.450308 21.2419 0 24 0C26.7863 0 29.4037 0.450308 31.8522 1.35092C34.3008 
                2.25154 36.4398 3.48989 38.2691 5.06596C40.1266 6.6139 41.5761 8.4292 42.6174 10.5119C43.6869 12.5664 44.2216 14.7757 44.2216 17.1398C44.2216 18.2938 44.0809 
                19.4617 43.7995 20.6438C43.5462 21.7977 43.1803 22.9376 42.7018 24.0633C42.2234 25.1609 41.6324 26.2023 40.9288 27.1873C40.2533 28.1724 39.4793 29.0589 38.6069 
                29.847C39.9859 30.635 41.2383 31.5778 42.3641 32.6755C43.4899 33.7731 44.4468 34.9833 45.2348 36.3061C46.0229 37.6288 46.628 39.0361 47.0501 40.5277C47.4723 42.0193 
                47.6834 43.5532 47.6834 45.1293ZM32.3588 18.4485C32.3588 17.4635 32.1337 16.5488 31.6834 15.7045C31.2612 14.832 30.6702 14.0721 29.9103 13.4248C29.1785 12.7775 28.3061 
                12.2709 27.2929 11.905C26.2797 11.5391 25.2102 11.3562 24.0844 11.3562C22.9305 11.3562 21.847 11.5391 20.8338 11.905C19.8206 12.2709 18.934 12.7775 18.1741 
                13.4248C17.4424 14.0721 16.8514 14.832 16.4011 15.7045C15.9789 16.5488 15.7678 17.4635 15.7678 18.4485C15.7678 19.4336 15.9789 20.3483 16.4011 21.1926C16.8514 
                22.0369 17.4424 22.7687 18.1741 23.3879C18.934 24.007 19.8206 24.4996 20.8338 24.8654C21.847 25.2313 22.9305 25.4142 24.0844 25.4142C25.2102 25.4142 26.2797 25.2313 
                27.2929 24.8654C28.3061 24.4996 29.1785 24.007 29.9103 23.3879C30.6702 22.7687 31.2612 22.0369 31.6834 21.1926C32.1337 20.3483 32.3588 19.4336 32.3588 18.4485ZM35.6517 
                44.7916C35.6517 43.4406 35.3421 42.1882 34.723 41.0343C34.1319 39.8522 33.3017 38.825 32.2322 37.9525C31.1909 37.08 29.9525 36.3905 28.5171 35.8839C27.1099 
                35.3773 25.6042 35.124 24 35.124C22.3958 35.124 20.8901 35.3773 19.4828 35.8839C18.0756 36.3905 16.8373 37.08 15.7678 37.9525C14.7265 38.825 13.8962 
                39.8522 13.277 41.0343C12.686 42.1882 12.3905 43.4406 12.3905 44.7916C12.3905 46.1425 12.686 47.3808 13.277 48.5066C13.8962 49.6324 14.7265 50.6033 
                15.7678 51.4195C16.8373 52.2357 18.0756 52.883 19.4828 53.3615C20.8901 53.8118 22.3958 54.0369 24 54.0369C25.6042 54.0369 27.1099 53.8118 28.5171 
                53.3615C29.9525 52.883 31.1909 52.2357 32.2322 51.4195C33.3017 50.6033 34.1319 49.6324 34.723 48.5066C35.3421 47.3808 35.6517 46.1425 35.6517 44.7916Z"
                fill="url(#paint0_linear_37087_1292)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_37087_1292" x1="24" y1="64" x2="24" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7C00" />
                <stop offset="1" stopColor="#FFDC5B" />
            </linearGradient>
        </defs>
    </svg>
);

export const DbetTwelveIcon = (height = 64, width = 76) => (
    <svg width={width} height={height} viewBox="0 0 76 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.014 64H7.20502V12.8089H0.822876V0H20.014V64Z" fill="url(#paint0_linear_37087_463)" />
        <path d="M75.1771 19.1911C75.1771 21.8391 74.6713 24.3384 73.6597 26.689C72.6481 29.0098 71.2645 31.0479 69.5091 32.8033C67.7834 34.5291 65.7601 35.8977 63.4393 36.9093C61.1186 37.921 58.6341 38.4268 55.9861 38.4268H49.5593C48.6667 38.4268 47.8336 38.5904 47.06 38.9177C46.2864 39.245 45.6021 39.7062 45.007 40.3013C44.4417 40.8666 43.9954 41.536 43.6681 42.3096C43.3408 43.0832 43.1771 43.9163 43.1771 44.8089V51.1911H73.4365V64H30.3682V44.8089C30.3682 42.1609 30.874 39.6764 31.8856 37.3556C32.8973 35.0051 34.2659 32.967 35.9916 31.2413C37.7471 29.4858 39.7852 28.1172 42.106 27.1353C44.4268 26.1237 46.9112 25.6179 49.5593 25.6179H55.9861C56.8787 25.6179 57.7118 25.4542 58.4854 25.1269C59.259 24.7996 59.9284 24.3533 60.4937 23.788C61.0888 23.1929 61.55 22.5086 61.8773 21.735C62.2046 20.9317 62.3682 20.0837 62.3682 19.1911C62.3682 18.2985 62.2046 17.4654 61.8773 16.6918C61.55 15.9182 61.0888 15.2487 60.4937 14.6834C59.9284 14.0883 59.259 13.6271 58.4854 13.2999C57.7118 12.9726 56.8787 12.8089 55.9861 12.8089H33.1353V0H55.9861C58.6341 0 61.1186 0.505811 63.4393 1.51743C65.7601 2.52906 67.7834 3.9126 69.5091 5.66806C71.2645 7.39377 72.6481 9.41702 73.6597 11.7378C74.6713 14.0586 75.1771 16.543 75.1771 19.1911Z" fill="url(#paint1_linear_37087_463)" />
        <defs>
            <linearGradient id="paint0_linear_37087_463" x1="38" y1="64" x2="38" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7C00" />
                <stop offset="1" stopColor="#FFDC5B" />
            </linearGradient>
            <linearGradient id="paint1_linear_37087_463" x1="38" y1="64" x2="38" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7C00" />
                <stop offset="1" stopColor="#FFDC5B" />
            </linearGradient>
        </defs>
    </svg>
);

export const DbetThirteenIcon = (height = 65, width = 76) => (
    <svg width={width} height={height} viewBox="0 0 76 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M19.7462 64H6.93732V12.8089H0.555176V0H19.7462V64Z" fill="url(#paint0_linear_37087_2120)" />
            <path d="M75.445 44.8089C75.445 47.457 74.9392 49.9414 73.9276 52.2622C72.9159 54.583 71.5324 56.6211 69.7769 58.3766C68.0512 60.1023 66.028 61.4709 63.7072 62.4826C61.3864 63.4942 58.902 64 56.2539 64H30.6361V51.1911H56.2539C57.1465 51.1911 57.9796 51.0274 58.7532 50.7001C59.5268 50.3729 60.1963 49.9265 60.7616 49.3612C61.3567 48.7662 61.8178 48.0818 62.1451 47.3082C62.4724 46.5346 62.6361 45.7015 62.6361 44.8089C62.6361 43.9163 62.4724 43.0832 62.1451 42.3096C61.8178 41.536 61.3567 40.8666 60.7616 40.3013C60.1963 39.7062 59.5268 39.245 58.7532 38.9177C57.9796 38.5904 57.1465 38.4268 56.2539 38.4268H38.8034V25.6179H56.2539C57.1465 25.6179 57.9796 25.4542 58.7532 25.1269C59.5268 24.7996 60.1963 24.3533 60.7616 23.788C61.3567 23.1929 61.8178 22.5086 62.1451 21.735C62.4724 20.9317 62.6361 20.0837 62.6361 19.1911C62.6361 18.2985 62.4724 17.4654 62.1451 16.6918C61.8178 15.9182 61.3567 15.2487 60.7616 14.6834C60.1963 14.0883 59.5268 13.6272 58.7532 13.2999C57.9796 12.9726 57.1465 12.8089 56.2539 12.8089H30.6361V0H56.2539C58.902 0 61.3864 0.505811 63.7072 1.51743C66.028 2.52906 68.0512 3.9126 69.7769 5.66806C71.5324 7.39377 72.9159 9.41702 73.9276 11.7378C74.9392 14.0586 75.445 16.543 75.445 19.1911C75.445 21.5416 75.0136 23.8326 74.1507 26.0642C73.2879 28.2659 72.068 30.2445 70.491 32C72.068 33.7555 73.2879 35.749 74.1507 37.9805C75.0136 40.1822 75.445 42.4584 75.445 44.8089Z" fill="url(#paint1_linear_37087_2120)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_37087_2120" x1="38.0001" y1="64" x2="38.0001" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7C00" />
                <stop offset="1" stopColor="#FFDC5B" />
            </linearGradient>
            <linearGradient id="paint1_linear_37087_2120" x1="38.0001" y1="64" x2="38.0001" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#CF7C00" />
                <stop offset="1" stopColor="#FFDC5B" />
            </linearGradient>
        </defs>
    </svg>

);

export const DbetArrowRight = (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.10005 5.9204L1.67596 3.48498C1.03235 2.83931 1.03441 1.79306 1.68111 1.15048C2.32678 0.506875 3.372 0.508935 4.01561 1.15563L10.3364 7.50419C10.9789 8.14986 10.9779 9.19302 10.3343 9.83663L3.98574 16.1862C3.66342 16.5085 3.24019 16.6692 2.81798 16.6692C2.39577 16.6692 1.97356 16.5085 1.65124 16.1862C1.00557 15.5416 1.00557 14.4964 1.65124 13.8517L4.08357 11.4184" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const DbetThirteenLadder = (height = 27, width = 16) => (
    <svg width={width} height={height} viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 2V1H7V2H9Z" fill="#FFD542" />
        <path d="M8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289L0.928932 15.6569C0.538408 16.0474 0.538408 16.6805 0.928932 17.0711C1.31946 17.4616 1.95262 17.4616 2.34315 17.0711L8 11.4142L13.6569 17.0711C14.0474 17.4616 14.6805 17.4616 15.0711 17.0711C15.4616 16.6805 15.4616 16.0474 15.0711 15.6569L8.70711 9.29289ZM9 11V10H7V11H9Z" fill="#FFD542" />
        <path d="M8.70711 18.2929C8.31658 17.9024 7.68342 17.9024 7.29289 18.2929L0.928932 24.6569C0.538408 25.0474 0.538408 25.6805 0.928932 26.0711C1.31946 26.4616 1.95262 26.4616 2.34315 26.0711L8 20.4142L13.6569 26.0711C14.0474 26.4616 14.6805 26.4616 15.0711 26.0711C15.4616 25.6805 15.4616 25.0474 15.0711 24.6569L8.70711 18.2929ZM9 20V19H7V20H9Z" fill="#FFD542" />
    </svg>

);

export const DbetTwelveLadder = (height = 27, width = 16) => (
    <svg width={width} height={height} viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 2V1H7V2H9Z" fill="#544305" />
        <path d="M8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289L0.928932 15.6569C0.538408 16.0474 0.538408 16.6805 0.928932 17.0711C1.31946 17.4616 1.95262 17.4616 2.34315 17.0711L8 11.4142L13.6569 17.0711C14.0474 17.4616 14.6805 17.4616 15.0711 17.0711C15.4616 16.6805 15.4616 16.0474 15.0711 15.6569L8.70711 9.29289ZM9 11V10H7V11H9Z" fill="#FFD542" />
        <path d="M8.70711 18.2929C8.31658 17.9024 7.68342 17.9024 7.29289 18.2929L0.928932 24.6569C0.538408 25.0474 0.538408 25.6805 0.928932 26.0711C1.31946 26.4616 1.95262 26.4616 2.34315 26.0711L8 20.4142L13.6569 26.0711C14.0474 26.4616 14.6805 26.4616 15.0711 26.0711C15.4616 25.6805 15.4616 25.0474 15.0711 24.6569L8.70711 18.2929ZM9 20V19H7V20H9Z" fill="#FFD542" />
    </svg>
);

export const DbetEightLadder = (height = 27, width = 16) => (
    <svg width={width} height={height} viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 2V1H7V2H9Z" fill="#544305" />
        <path d="M8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289L0.928932 15.6569C0.538408 16.0474 0.538408 16.6805 0.928932 17.0711C1.31946 17.4616 1.95262 17.4616 2.34315 17.0711L8 11.4142L13.6569 17.0711C14.0474 17.4616 14.6805 17.4616 15.0711 17.0711C15.4616 16.6805 15.4616 16.0474 15.0711 15.6569L8.70711 9.29289ZM9 11V10H7V11H9Z" fill="#544305" />
        <path d="M8.70711 18.2929C8.31658 17.9024 7.68342 17.9024 7.29289 18.2929L0.928932 24.6569C0.538408 25.0474 0.538408 25.6805 0.928932 26.0711C1.31946 26.4616 1.95262 26.4616 2.34315 26.0711L8 20.4142L13.6569 26.0711C14.0474 26.4616 14.6805 26.4616 15.0711 26.0711C15.4616 25.6805 15.4616 25.0474 15.0711 24.6569L8.70711 18.2929ZM9 20V19H7V20H9Z" fill="#FFD542" />
    </svg>
);

