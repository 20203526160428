import styled from 'styled-components';

export const PinkWinWrapper = styled.div`
  background: var(--background_4);
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: var(--flex-direction); 
  color: var(--text-black-dark-background);

  .main-wrapper {
    background: var(--background_4);
    display: flex;
    padding-right: 20px;
    flex-direction: var(--flex-direction); 
  }

  .h1 {
    line-height: 140px;
    margin: 0;
  }

  .title-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid var(--text-black);
    margin: 12px 0px 12px 0px;
    padding-right: 20px;
  }

  .title-flex-ltr {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 1px solid var(--text-black);
    margin: 12px 0px 12px 0px;
    padding-left: 20px;
  }

  .title-flex span {
    font-size: 11px;
    font-weight: bold;
  }

  .right-flex {
    margin: 12px 0px 12px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: var(--flex-direction); 
  }

  .your-win {
    font-size: 11px;
    margin-bottom: 7px;
    display: block;
    white-space: nowrap;
  }
  
  @media screen and (max-width: 950px) {
    .your-win-wrapper {
      margin-right: 18px;
    }
  }

  .margin-left {
    margin-left: 6px;
  }

  .margin-right {
    margin-right: 6px;
    transform: rotate(-180deg);
  }
`;