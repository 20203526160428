import { useState } from "react";
import { IsRightToLeftTheme, themeC } from "../theme/themehelper";
import { Grid, Skeleton, Typography } from "@mui/material";
import useDetailedFixture from "../fixtures/useDetailedFixture";
import { useTranslation } from "react-i18next";
import MatchInformation from "./matchinformation";
import MatchStatistics from "./matchstatistics";
import LeagueTable from "./leaguetable";
import { Fixture } from "../models/fixture";

export const SlimCouponStyles = {

    rowStyle: {
        flexDirection: "var(--flex-direction)",
        justifyContent: "space-between",
        alignItems: "center"
    },

    infoTextStyle: {
        fontSize: "12px",
        lineHeight: "19px",
        fontWeight: "400",
        fontFamily: "var(--font-body)",
        marginTop: "2px",
        padding: "3px",
        color: "var(--text-black)"
    },

    teamStyle: {
        fontFamily: "var(--font-body)",
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "500",
        marginTop: "10px",
        marginBottom: "10px"
    }
}

let oddBackground = "var(--background_1)";
let evenBackground = "var(--background_2)";

if (window.theme === themeC.Dbet) {
    oddBackground = "var(--background_2)";
    evenBackground = "#DBDBDB";
}

export const SlimCouponRowStyles = {
    rowOdd: { ...SlimCouponStyles.rowStyle, ...{ background: oddBackground }, ...SlimCouponStyles.infoTextStyle, ...{ color: "var(--text-black-dark-background)" } },
    rowEven: { ...SlimCouponStyles.rowStyle, ...{ background: evenBackground }, ...SlimCouponStyles.infoTextStyle }
}

function SlimFixtureDetail({ theme, fixture }: { theme: string, fixture: Fixture }) {

    let [menuIndex, setMenuIndex] = useState(0);
    let isRightToLeft = IsRightToLeftTheme(theme);
    const { t } = useTranslation();

    const { fixtureDetails } = useDetailedFixture(fixture.id);

    if (!fixtureDetails) {
        return (
            <Grid container sx={{ margin: "0px", padding: "20px" }} justifyContent="center">
                <Skeleton variant="rectangular" width="100%" height="200px" component="table" />
            </Grid>

        );
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, index: number) => {
        setMenuIndex(index);
    };

    let ComponentToRender;

    switch (menuIndex) {
        case 0:
            ComponentToRender = MatchInformation;
            break;
        case 1:
            ComponentToRender = MatchStatistics;
            break;
        case 2:
            ComponentToRender = LeagueTable;
            break;
        default:
            ComponentToRender = MatchInformation;
            break;
    }

    const menu = {
        fontFamily: "var(--font-body)",
        marginTop: "2px",
        cursor: "pointer",
        marginLeft: "5px",
        marginRight: "5px",
        fontSize: "13px",
        lineHeight: "19px",
        fontWeight: "500"
    }

    const underlined = { ...menu, ...{ textDecoration: "underline" } }

    return (
        <Grid>
            <Grid>
                <Grid sx={{ marginBottom: "20px" }} container justifyContent="center">
                    <Typography sx={menuIndex === 0 ? underlined : menu} onClick={(event) => handleMenuClick(event, 0)}>
                        {t('game_info')}
                    </Typography>
                    <Typography sx={menuIndex === 1 ? underlined : menu} onClick={(event) => handleMenuClick(event, 1)}>
                        {t('statistics')}
                    </Typography>
                    <Typography sx={menuIndex === 2 ? underlined : menu} onClick={(event) => handleMenuClick(event, 2)}>
                        {t('table')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <ComponentToRender fixture={fixture} fixtureDetails={fixtureDetails} isRightToLeft={isRightToLeft} />
            </Grid>
        </Grid>
    );

}

export default SlimFixtureDetail;
