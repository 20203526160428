import { React, useState } from 'react';
import NavBar from '../../navbar';
import image from '../../images/footballfield.jpg';
import { SyndicateWrapper } from './styles';
import { BackgroundImage, H2, Button } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import useSyndicates from '../useSyndicates';
import SyndicateCoupon from '../syndicatecoupon';
import useDetailRound from '../../rounds/useDetailRound';
import SyndicateConfirm from '../syndicateconfirm';
import Moment from "react-moment";
import { usePlayer } from "../../player/playerContext";
import { Redirect } from "react-router-dom";
import { getRoundName } from '../../common/RoundHelper';
import { ShowImageOnSyndicatePage, themeC } from '../../theme/themehelper';
import { PoolXNumberFormat } from '../../common/CurrencyFormatter';
import kungImage from '../../images/kung_syndicates.png';
import dbetImage from '../../images/dbet_syndicate.jpeg';

export default function Syndicate() {
  const { syndicateId } = useParams();
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [player, config] = usePlayer();

  const [syndicates] = useSyndicates();
  const syndicate = syndicates?.filter(({ id }) => id === syndicateId)[0];
  const { round } = useDetailRound(syndicate?.roundId);

  if (!syndicates) return null;

  if (!syndicate) {
    return <Redirect to={"/clear"} />
  }

  if (!round) return null;

  let theme = window.theme;
  let showImage = ShowImageOnSyndicatePage(theme);

  const openToDate = new Date(round.openTo);
  let locale = t('locale');

  let imageToShow = image;

  if(theme === themeC.Dbet){
    imageToShow = dbetImage;
  }

  if(theme === themeC.Kung){
    imageToShow = kungImage;
  }

  const nf = PoolXNumberFormat(locale, { style: 'currency', currency: syndicate.stakePerShare.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let formattedStakePerShare = nf.format(syndicate.totalAmountPerShare.amount);
  let feePercentage = Math.round((syndicate.agentFeePerShare.amount / syndicate.stakePerShare.amount * 100)) + "%";

  if (!(config.syndicateBetting || config.agentSyndicateBetting)) {
    return <div />;
  }

  let isRealMode = player && player._embedded && player._embedded.mode === 'real';

  return (
    <>
      <NavBar  whichToUnderline="2"/>
      <SyndicateWrapper>
        {showImage &&
          <BackgroundImage height={250} image={imageToShow}>
            <H2 color="#fff">
              {t('bet_with_syndicates')}
            </H2>
          </BackgroundImage>
        }
        <div className="green-div-column">
          <H2 className="title-margin">{syndicate.name}</H2>
          <span className="title-under">{getRoundName(round, t)} <Moment format={"L"} locale={locale}>{openToDate}</Moment></span>
        </div>
        <div className="green-div-row">
          <div className="info-item">
            <span>{t('share_price')}:</span>
            <span className="bold">{formattedStakePerShare} </span>
          </div>
          <div className="info-item">
            <span>{t('shares')}:</span>
            <span className="bold">{syndicate.targetNShares} </span>
          </div>
          <div className="info-item">
            <span>{t('sold_shares')}:</span>
            <span className="bold">{syndicate.nShares} </span>
          </div>
          <div className="info-item">
            <span>{t('fee')}:</span>
            <span className="bold">{feePercentage}</span>
          </div>
        </div>
        <div className="white-div">
          {showConfirm && (
            <SyndicateConfirm
              toggle={setShowConfirm}
              syndicate={syndicate}
            />
          )}
          {syndicate.nShares < syndicate.targetNShares && (<Button disabled={!isRealMode} onClick={() => setShowConfirm(true)}>{t('pay') + ' ' + formattedStakePerShare}</Button>)}
          <SyndicateCoupon syndicate={syndicate} round={round}></SyndicateCoupon>
        </div>
      </SyndicateWrapper>
    </>
  );
}