import { ClickAwayListener, Grid, IconButton, SxProps, Theme, ToggleButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { themeC } from "../theme/themehelper";

function StakeMultiplier({ t, stakeMultiplier, setStakeMultiplier, isRightToLeft, theme }:
    { t: any, stakeMultiplier: number, setStakeMultiplier: (newValue: number) => void, isRightToLeft: boolean, theme: string }) {

    const [stakeMultiplierTooltipOpen, setStakeMultiplierTooltipOpen] = useState(false);
    const isMobileScreen = useMediaQuery('(max-width: 950px)');

    const handleStakeMultiplierTooltipClose = () => {
        setStakeMultiplierTooltipOpen(false);
    };

    const handleStakeMultiplierTooltipOpen = () => {
        setStakeMultiplierTooltipOpen(true);
    };

    const handleStakeMultiplier = (newStakeMultiplier: number) => {
        setStakeMultiplier(newStakeMultiplier);
    };

    const button: SxProps<Theme> = {
        padding: "0px 5px",
        textAlign: "center",
        height: "40px",
        fontFamily: "var(--font-body)",
        minWidth: "40px",
        maxWidth: "40px",
        marginRight: "10px",
        border: 'var(--sign-border-width) solid var(--off-black)',
        borderRadius: 'var(--sign-border-radius)',
        background: 'var(--sign-background-not-selected)',
        color: 'var(--sign-color)',
        "&:hover": {
            backgroundColor: 'var(--sign-hover-background)',
        },
        "&.Mui-selected": {
            color: 'var(--sign-color-selected)',
            backgroundColor: 'var(--sign-background)'
        },
        "&.Mui-selected:hover": {
            color: 'var(--sign-color)',
            backgroundColor: 'var(--sign-hover-background)',
        }
    }
    let signText: SxProps<Theme> = {
        fontSize: "13px",
        lineHeight: "18px",
        fontFamily: "var(--font-body)"
    }

    if (theme === themeC.Orange) {
        signText.fontWeight = "bold";
    }

    return (
        <Grid>

            <Grid container sx={{ marginTop: "10px", paddingBottom: "10px", justifyContent: "center", alignItems: "center", flexDirection: 'var(--flex-direction)' }}>
                <Typography sx={{ color: "var(--text-black-dark-background)", marginLeft: "31px", fontWeight: "bold", fontSize: "13px", marginTop: "5px", fontFamily: "var(--font-body)", flexDirection: "var(--flex-direction)" }}>{t('win_multiplier')}</Typography>
                <ClickAwayListener onClickAway={handleStakeMultiplierTooltipClose}>
                    <div>
                        <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleStakeMultiplierTooltipClose}
                            open={stakeMultiplierTooltipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement={isRightToLeft ? "bottom-start" : "top-end"}
                            title={t('win_multiplier_information')}
                        >
                            <IconButton onClick={handleStakeMultiplierTooltipOpen}>
                                <InfoOutlinedIcon sx={{ fontSize: "15px", color: 'var(--off-black)' }} height={isMobileScreen ? "15" : "20"} width={isMobileScreen ? "15" : "20"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </ClickAwayListener>

            </Grid>

            <Grid container sx={{ justifyContent: "center" }}>
                <Grid item sx={{ minHeight: '100%' }}>
                    <Grid container alignItems="center" direction="row" sx={{ minHeight: '100%', flexDirection: 'var(--flex-direction)' }}>
                        <ToggleButton onChange={() => handleStakeMultiplier(1)} value="1" selected={stakeMultiplier === 1} sx={button}>
                            <Typography sx={signText}>1x</Typography>
                        </ToggleButton>
                        <ToggleButton onChange={() => handleStakeMultiplier(2)} value="2" selected={stakeMultiplier === 2} sx={button}>
                            <Typography sx={signText}>2x</Typography>
                        </ToggleButton>
                        <ToggleButton onChange={() => handleStakeMultiplier(3)} value="3" selected={stakeMultiplier === 3} sx={button}>
                            <Typography sx={signText}>3x</Typography>
                        </ToggleButton>
                        <ToggleButton onChange={() => handleStakeMultiplier(5)} value="5" selected={stakeMultiplier === 5} sx={button}>
                            <Typography sx={signText}>5x</Typography>
                        </ToggleButton>
                        <ToggleButton onChange={() => handleStakeMultiplier(10)} value="10" selected={stakeMultiplier === 10} sx={button}>
                            <Typography sx={signText}>10x</Typography>
                        </ToggleButton>

                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    );
}

export default StakeMultiplier;