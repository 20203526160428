export const RULES_FA = {    
    faq: {
        title: "سوالات",
        q1: {
            question: "پیش بینی شراکتی به چه صورت است؟",
            answer: "در پیش بینی شراکتی همه مبلغ پرداخت شده توسط بازیکنان یک جا جمع می شود. پس از کسر کمیسیون این مبلغ بین برندگان پخش خواهد شد. اگر به تعداد کافی انتخاب درست داشته باشید جز برندگان خواهید بود"
        },
        q2: {
            question: "راند چیست؟",
            answer: "هر راند متشکل از تعدادی مسابقه فوتبال است که شما باید نتیجه آن را پیش بینی کنید. بازی ها معمولا در یک روز انجام می شوند. در هر راند 8، 12 یا 13 مسابقه وجود دارد."
        },
        q3: {
            question: "چه تعداد گروه جوایز وجود دارد؟",
            answer: "برای راند های 12 و 13 تایی سه گروه جایزه و برای راند 8 تایی 1 گروه جایزه وجود دارد."
        },
        q4: {
            question: "چگونه پیش بینی خود را ثبت کنیم؟",
            answer: "در هر راند برای هر بازی یک نتیجه را پیش بینی کنید. می توانید بیشتر از یک نتیجه برای یک بازی را پیش بینی کنید ولی مبلغ شرط شما به همان نسبت بیشتر خواهد شد"
        },
        q5: {
            question: "چگونه برنده خواهم شد؟",
            answer: "اگر تعداد پیش بینی های صحیح شما به حد نصاب برسد برنده خواهید شد. ممکن است بیش از یک جایزه برای یک راند وجود داشته باشد. همه برندگان سهم خود از جایزه ای که در آن سهم دارند را دریافت می کنند."
        },
        q6: {
            question: "پیش بینی هوشمند چیست؟",
            answer: "یک روش خودکار برای ثبت پیش بینی است. ربات نتیجه ها را برای شما پیش بینی می کند. شما می توانید احتمال برد بیشتر یا احتمال برد کمتر با مبلغ بیشتر را انتخاب کنید."
        }
    },
    terms: {
        title: "قوانین",
        p1: {
            title:"ثبت شرط",
            p11:"سایت در واقع نماینده ای برای PoolX می باشد که مبالغ را به استخر جوایز بین المللی منتقل می کند. در صورتی که مبلغ شرط به استخر جوایز واریز نشود مبلغ آن برگشت داده خواهد شد و سایت و PoolX مسوولیتی در قبال نتیجه شرط ندارند. سایت موظف به نگهداری از مبالغ ثبت شده توسط کاربران و واریز جوایز آن ها می باشد، هر گونه موردی که در ارتباط با استخرهای جوایز PoolX داشته باشید باید از طریق سایت پیگیری شود. در صورت نیاز سایت با گردانندگان PoolX ارتباط خواهد گرفت.",
            p12:"مسوولیت بررسی و انتخاب های شرط بر عهده شما بوده و پس از ثبت نهایی آن امکان هیچ گونه دخل و تصرفی در آن وجود ندارد. PoolX این حق را دارد که از ثبت هر شرطی به هر دلیلی خودداری نماید.",
            p13:"در صورتی که اختلاف یا اشتباهی در اطلاعات یا وضعیت شرط ها وجود داشته باشد، PoolX تنها با تکیه بر منابع خود نتیجه نهایی را مشخص خواهد کرد و تصمیم گیرنده نهایی است.",
            p14:"PoolX ممکن است در هر زمانی ثبت شرط در یک استخر جوایز را متوقف یا لغو کند. PoolX همچنین این حق را دارد که شرط یا کل استخر جوایز را برای تصحیح اشتباه پیش آمده لغو نماید.",
            p15:"بازیکن نتایج یک سری از بازی های مربوطه به یکدیگر، به انتخاب PoolX را پیش بینی می کند.",
            p16:"PoolX بازی ها را به صلاح دید خود انتخاب می کند",
            p17:"بازیکنان می توانند یک یا چند شرط ثبت نمایند، برای ثبت هر شرط می بایست برای هر بازی حداقل یک نتیجه را پیش بینی نمایید. هر بازی متشکل از سه نتیجه برد میزبان (1)، مساوی(X) یا برد میهمان(2) است. هر پیش بینی پس از ثبت با شناسه خاص خود شناسایی می شود.",
            p18:"برای هر بازی، بازیکنان می توانند یک یا چند نتیجه را انتخاب نمایند. برای مثال اگر شما هم برد میزبان و هم مساوی را ثبت نمایید برای شما دو ردیف شرط ثبت می شود.\n هزینه هر ردیف از پیش تعیین شده است، شما به ازای هر ردیف شرطی که ثبت می کند می بایست این مبلغ را بپردازید. برای مثال اگر هزینه ثبت شرط 5000 تومان باشد و شما 40 ردیف شرط ثبت کرده باشید، می بایست 200 هزار تومان پرداخت نمایید",
            p19:"تمامی شرط ها برای وقت قانونی بازی به علاوه وقت های تلف شده در نظر گرفته می شوند و وقت های اضافی یا ضربات پنالتی و دیگر موارد در نتیجه شرط محاسبه نخواهند شد.",
            p110:"تمامی شرط ها با توجه به نتیجه منتشر شده توسط برگزارکننده رسمی بازی تسویه می شوند.",
            p111:"در صورتی که در نتیجه رسمی منتشر شده از یک بازی شک یا شبهه ای وجود داشته باشد، PoolX این حق را دارد که تسویه استخر جوایز را تا تایید شدن نهایی جوایز به تعویق بیاندازد.",
            p112:"PoolX این حق را دارد که تمامی شرط ها را در صورت تسویه اشتباه برگشت بزند (اعم از خطای فنی یا انسانی). در صورتی که تسویه شرطی تغییر کند ممکن است موجودی حساب کاربری یا استخر جوایز تحت تاثیر قرار بگیرند.",
            p113:"با وجود تمامی موارد ذکر شده، پس از گذشت 72 ساعت از تسویه شرط ها، نتیجه ثبت شده نهایی و غیر قابل تغییر محسوب می شود.",
            p114:"اگر مسابقه ای ناتمام بماند، منظور وقت قانونی بازی (برای مثال در فوتبال 90 دقیقه به علاوه وقت های تلف شده) با توجه به منابع معتبر، به علاوه وقت های تلف شده است و از ساعت شروع آن در برگه شرط بیش از 4 ساعت گذشته باشد، نتیجه آن بازی با قرعه کشی مشخص می شود.",
            p115:"قرعه کشی با توجه به احتمال وقوع هر نتیجه انجام می شود. درصد این احتمالات را می توانید برای هر بازی در اطلاعات آن بازی در PoolX ببینید. این درصد ها با اسم های: احتمال میزبان، احتمال تساوی، احتمال میهمان مشخص شده و با قرار گرفتن بازی ها روی سایت درصد آن ها نیز مشخص می شوند. پس از قرار گرفتن روی سایت تغییر نخواهند کرد.",
            p116a:"عدد تصادفی انتخاب شده برای قرعه کشی از اولین بازی در همان برگه شرط انتخاب شده و جای دیگری استفاده نمی شود. آخرین عدد دقیقه ای که یک از اتفاقات زیر در آن بازی افتاده است به عنوان عدد تصادفی انتخاب می شود:",
            p116b:"گل\nکارت زرد\nکارت قرمز",
            p116c:"دقیقه انتخابی از اطلاعات منتشر شده توسط برگزارکننده رسمی بازی استخراج می شود. اگر اتفاقات بازی با دقیقه و ثانیه ثبت شده باشند، اتفاقات بین دقیقه 0:00 و 0:59، دقیقه یک بازی و به همین صورت دیگر دقایق بازی محاسبه می شوند. وقایع رخداده در وقت های تلف شده نیمه اول دقیقه 45 و وقایع رخ داده در وقت های تلف شده نیمه دوم دقیقه 90 بازی محاسبه می شوند. عدد به دست آمده بین 0 تا 9 خواهد بود. این عدد بر ده تقسیم می شود تا عددی صفر تا یک به دست بیاید. این عدد همان عدد تصادفی برای قرعه کشی خواهد بود.",
            p116d:"نتیجه بازی با قرعه کشی به صورت زیر محاسبه می شود",
            p116e:"عدد تصادفی کمتر از احتمال برد میزبان باشد\n\tمیزبان برنده اعلام می شود\nاگر عدد تصادفی کمتر از جمع احتمال برد میزبان و تساوی باشد\n\tنتیجه بازی مساوی اعلام می شود\nدر غیر این صورت\n\tمیهمان برنده بازی اعلام خواهد شد",
            p117:"قرعه کشی برای بازی ها به همان ترتیبی که در برگه شرط بندی آمده اند انجام می شود. (اگر هم بازی سوم و هم بازی ششم در برگه نیاز به قرعه کشی داشته باشند، بازی سوم با توجه به عدد تصادفی به دست آمده از بازی اول و بازی ششم با توجه به عدد تصادفی به دست آمده از بازی دوم قرعه کشی می شوند)",
            p118:"اگر بیش از نیمی از بازی های یک برگه نیاز به قرعه کشی داشته باشند آن برگه کلا لغو شده و مبالغ برگشت داده خواهند شد.",
            p119:"اگر محل برگزاری یک بازی پس از قرار گرفتن آن روی سایت تغییر کند، در صورتی که محل برگزاری بازی جدید خانه یکی از دو تیم باشد نتیجه بازی با قرعه کشی مشخص می شود. برای مسابقاتی که در محلی بی طرف برگزار می شوند بدون در نظر گرفتن این که کدام تیم میزبان در نظر گرفته شده است شرط ها تسویه خواهند شد.",            
            p120:"سایت هیچ گونه مسوولیتی در قبال اشتباهات احتمالی در اطلاعات ثبت شده درباره مسابقات مانند اطلاعات زنده و نتایج ندارد.",
            p121:"اگر هر یک از قوانین ذکر شده در سایت با قوانین مربوط به یک رشته ورزشی یا لیگ در تضاد باشد، قوانین سایت ارجعیت دارند"
        },
        p2: {
            title:"برد ها",
            p21:"همه شرط های ثبت شده جزو استخر جوایز هستند و مبلغ برد با توجه به مبلغ جمع آوری شده و ردیف های برنده مشخص خواهد شد.",
            p22:"هر استخر می تواند یک یا چند گروه برنده داشته باشد: هر یک از این گروه ها با تعداد انتخاب های درست برای برنده شدن مشخص می شوند.",
            p23:"مبلغ جایزه هر گروه بر تعداد ردیف های درست تقسیم می شود. اگر در یک گروه هیچ برنده ای وجود نداشته باشد جایزه آن به گروه بعدی که بردهای کمتری نیاز دارد منتقل می شود.",
            p24:"اگر مبلغ برد هر برنده در یک گروه کمتر از حداقل مبلغ برد باشد، آن گروه لغو شده و جایزه آن به گروهی که بیشترین انتخاب صحیح را نیاز دارد منتقل می شود.",
            p25:"حداقل مبلغ برد یک یورو یا معادل ارزی آن در نظر گرفته شده است.",
            p26:"اگر در دو مرحله قبلی جوایز به برندگان نرسد، برای بیشتر کردن استخر جایزه در راند های پیش رو استفاده خواهد شد.",
            p27:"20 درصد از جایزه کلی به عنوان کمیسیون کسر خواهد شد. مابقی مبلغ بین برندگان با توجه به تعداد پیش بینی های صحیح آن ها پخش خواهد شد.",
            amendment:"تاریخ آخرین به روز رسانی 23 مارس 2023"
            
        }
    }
}