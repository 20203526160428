import fetchApi from "../backend/fetchApi";
import { usePlayer } from "../player/playerContext";

const useApiBetting = () => {

  const [player] = usePlayer();

  return {
    uploadFile: (file, info) => {
      const playerUrl = player && player._links.coupons.href;

      if (!playerUrl) return;

      const formData = new FormData();

      formData.append('file', file, "orders.txt");

      return fetchApi(playerUrl, {
        method: 'POST',
        body: formData
      }).then(response => {
        if (response.ok) {
          return {
            success:true
          };
        } else {
          return {
            success:false,
            status:response.status,
            statusText:response.statusText,
            errorCode:response.errorCode
          };
        }
      })
    },
  }
}

export default useApiBetting;