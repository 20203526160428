import { Button, Grid, Typography } from "@mui/material";
import { getRoundName } from "../common/RoundHelper";
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import { PoolXNumberFormat } from "../common/CurrencyFormatter";
import { useTranslation } from "react-i18next";

function CouponShareListItemTomato({ couponShare, active }: { couponShare: any, active: boolean }) {
    const { t } = useTranslation();
    let locale = t('locale');
    const createdAtDate = new Date(couponShare.createdAt);
    const nf = PoolXNumberFormat(locale, { style: 'currency', currency: couponShare.stake.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let result = couponShare?.result;

    let color = "#fcbe31";
    let text = t('bet_status_open');

    if (!active) {
        color = "#1DAA63";
        text = t('bet_status_won');
        if (result.amount === '0') {
            color = "#e60000";
            text = t('bet_status_lost');
        }
    }

    return (

        <Grid container direction='column'>
            <Grid sx={{
                backgroundColor: '#FFF',
                padding: '12px 16px 12px 16px',
                borderWidth: '4px 0px 0px 0px',
                borderStyle: 'solid',
                borderColor: color,
                borderRadius: '4px',
                marginTop: '24px',
                width: '576px'
            }}>
                <Grid container direction='row' sx={{ justifyContent: 'space-between' }}>
                    <Typography sx={{
                        color: 'rgba(0,0,0,.87)',
                        fontSize: '14px',
                        fontWeight: '500'
                    }}>
                        {getRoundName(couponShare.coupon.round, t)}
                    </Typography>
                    <Typography sx={{
                        color: '#fff',
                        backgroundColor: color,
                        fontSize: '12px',
                        fontWeight: '500',
                        borderRadius: '13px',
                        marginLeft: '8px',
                        padding: '4px 8px'
                    }}>
                        {text}
                    </Typography>
                </Grid>


                <Grid container direction='row' sx={{ alignItems: 'flex-start', marginTop: '12px' }}>
                    <Typography sx={{
                        color: 'rgba(0,0,0,.87)',
                        fontSize: '14px',
                        flexBasis: 0,
                        flexGrow: 1
                    }}>
                        {<Moment format={'DD MMM. YYYY HH:mm:ss'} locale={locale}>{createdAtDate}</Moment>}
                    </Typography>

                    <Grid item >
                        <Grid container direction='column' >

                            <Grid container direction='row' sx={{
                                width: '100%', justifyContent: 'flex-end'
                            }}>
                                <Typography sx={{
                                    fontSize: '14px',
                                    color: "rgba(0,0,0,.54)",
                                    marginRight: "2px"
                                }}>
                                    {t('stake') + ":"}
                                </Typography>
                                <Typography sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: "rgba(0,0,0,.87)"
                                }}>
                                    {nf.format(couponShare.stake.amount)}
                                </Typography>
                            </Grid>

                            {result?.amount !== undefined && result.amount !== '0' ?

                                <Grid container direction='row' sx={{
                                    textAlign: 'right'
                                }}>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        color: "rgba(0,0,0,.54)",
                                        marginRight: "2px"
                                    }}>
                                        {t('payout') + ":"}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color: "rgba(0,0,0,.87)"
                                    }}>
                                        {nf.format(result.amount)}
                                    </Typography>
                                </Grid> : <></>

                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{
                backgroundColor: '#FFF',
                padding: '12px 0px 16px 0px',
                marginTop: '2px',
                justifyContent: 'center'

            }}>
                <Link to={`/coupon/${couponShare.id}`}>
                    <Button sx={{
                        color: "#fd5c3c",
                        fontWeight: 500,

                    }}>
                        {t('show_details')}
                    </Button>
                </Link>
            </Grid>
        </Grid>
    )



}

export default CouponShareListItemTomato;