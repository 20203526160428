import React from 'react';
import fetchPlayer from "../backend/fetchApi";
import {useQuery} from "react-query";
import {useApi} from "../api/apiContext";

const PlayerContext = React.createContext(undefined)

const PlayerProvider = props => {
  const [api] = useApi();

  const playerUrl = api && api._links && api._links.me.href;
  const config = api && api._embedded && api._embedded.config;
  const info = useQuery(['player', playerUrl], () => {
    return fetchPlayer(playerUrl)
      .then(response => response.json())
  }, {
    enabled: api
  })

  return <PlayerContext.Provider value={[info.data, config]} {...props} />
}

function usePlayer() {
  const context = React.useContext(PlayerContext)
  if (!context) {
    throw new Error(`usePlayer must be used within a PlayerProvider`)
  }
  return context
}

export {PlayerProvider, usePlayer}
