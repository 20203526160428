import { Grid, SxProps, Table, TableBody, TableCell, TableRow, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { SlimCouponRowStyles, SlimCouponStyles } from "./slimfixturedetail";
import { FixtureDetail, StatFixture } from "../models/fixturedetail";
import { H3 } from "../reusable-components";

function MatchStatistics({ fixtureDetails, isRightToLeft }:
  { fixtureDetails: FixtureDetail, isRightToLeft: boolean }) {
  const { teamStats } = fixtureDetails;
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 950px)');
  if (!teamStats) {
    return <div></div>
  }
  const teamStatsByTeamId = teamStats.team_stats_by_team_id;

  if (!teamStatsByTeamId) {
    return <div></div>
  }

  let listStyle: SxProps<Theme> = {
  };

  if (!isSmallScreen) {
    listStyle = {
      ...listStyle, ...{
        maxWidth: "400px",
        marginBottom: "100px",
        marginTop: "20px"
      }
    };
  }

  // Extract the latest fixtures for the home team
  const homeTeamStats = teamStatsByTeamId[`${fixtureDetails.fixture.homeTeam.id}`];
  const homeTeamLatestFixtures = homeTeamStats.latest_fixtures;

  // Same for the away team
  const awayTeamStats = teamStatsByTeamId[`${fixtureDetails.fixture.awayTeam.id}`];
  const awayTeamLatestFixtures = awayTeamStats.latest_fixtures;

  return (

    <Grid container direction="column" sx={listStyle}>

      <div className="subheader">
        <H3>{t('latest_games')}</H3>
      </div>

      <Typography sx={SlimCouponStyles.teamStyle}>
        {fixtureDetails.fixture.homeTeam.name}
      </Typography>

      <Table>
        <TableBody>
          {renderPreviousFixtureStats(homeTeamLatestFixtures, t, isRightToLeft)}
        </TableBody>
      </Table>


      <Typography sx={{ ...SlimCouponStyles.teamStyle, ...{ marginTop: "30px" } }}>
        {fixtureDetails.fixture.awayTeam.name}
      </Typography>

      <Table>
        <TableBody>
          {renderPreviousFixtureStats(awayTeamLatestFixtures, t, isRightToLeft)}
        </TableBody>
      </Table>

    </Grid>


  );
}

const fixtureInformationPredicate = (fixture: StatFixture) => (fixture.home_team.team_name &&
  fixture.away_team.team_name &&
  fixture.starting_at);

const renderPreviousFixtureStats = (previousFixtures: Array<StatFixture>, t: any, isRightToLeft: boolean) => {

  if (!previousFixtures) {
    return <div />;
  }

  let locale = t('locale');

  let filteredFixtures = previousFixtures.filter(fixture => fixtureInformationPredicate(fixture));
  if (filteredFixtures.length > 5) {
    filteredFixtures = filteredFixtures.slice(0, 5);
  }

  return filteredFixtures.map((fixture, index) => {

    if (!fixture.away_team.score) {
      fixture.away_team.score = 0;
    }

    if (!fixture.home_team.score) {
      fixture.home_team.score = 0;
    }

    const leagueName = fixture?.game_round?.league?.name;

    const even = (index % 2 === 0);

    return (
      <TableRow key={"row" + index} sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
        <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
          <Moment className={isRightToLeft ? 'no-linebreak rtl-no-linebreak' : 'no-linebreak ltr-no-linebreak date'} format={'MMM DD'} locale={locale}>{fixture.starting_at.seconds * 1000}</Moment>
        </TableCell>
        <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
          <Typography sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd} className={isRightToLeft ? 'no-linebreak date rtl-no-linebreak' : 'no-linebreak ltr-no-linebreak'}>{leagueName ?? leagueName}</Typography>
        </TableCell>
        <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
          <Grid container direction="column">
            <span>
              {fixture.home_team.team_name}
            </span>
            <span>
              {fixture.away_team.team_name}
            </span>
          </Grid>
        </TableCell>
        <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
          <Grid container direction="column">
            <span>
              {fixture.home_team.score}
            </span>
            <span>
              {fixture.away_team.score}
            </span>
          </Grid>
        </TableCell>
      </TableRow>
    );
  });
}

export default MatchStatistics;
