import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { gradientsC, themeC } from "../theme/themehelper";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import image from '../images/footballfield.jpg';
import prideImage from '../images/PridePick13.png';
import kungImage from '../images/kung_syndicates.png';
import kungGroup from '../images/kung_people.png';
import kungValid from '../images/kung_valid.png';
import kungWin from '../images/win_kung.png';
import dbetImage from '../images/dbet_syndicate.jpeg';
import orangeStadium from '../images/orange_background.png';

function SyndicateInfoBox({ theme }: { theme: string }) {
    const { t } = useTranslation();

    let backgroundImage = `url(${image})`;

    let borderColor = "";

    if (theme === themeC.Blue2) {
        backgroundImage = gradientsC.Blue2.Landing;
    }

    if (theme === themeC.Gold) {
        backgroundImage = `url(${prideImage})`;
    }

    if (theme === themeC.Kung) {
        backgroundImage = `url(${kungImage})`;
        borderColor = "#249CEC";
    }

    if (theme === themeC.Dbet) {
        backgroundImage = `url(${dbetImage})`;
        borderColor = "#F4C004";
    }

    if (theme === themeC.Orange) {
        backgroundImage = `url(${orangeStadium})`;
    }

    let boxInPicture: SxProps<Theme> = {
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--text-white)",
        padding: "10px",
        "::before": {
            content: '""',
            opacity: "0.7",
            background: "var(--title-bar-background)",
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            borderRadius: 'var(--radius)'
        }
    };

    if (window.theme === themeC.Orange) {
        boxInPicture = {
            position: "relative",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--text-white)",
            padding: "10px",
            "::before": {
                content: '""',
                opacity: "0.7",
                background: "#18192D",
                position: "absolute",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
                borderRadius: 'var(--radius)'
            }
        }
    }

    let textColor: SxProps<Theme> = {
        color: "var(--text-white)"
    }

    let betFollowWin: SxProps<Theme> = {
        fontFamily: "var(--font-title)",
        fontSize: "var(--font-title-size)",
        letterSpacing: "var(--font-title-letter-spacing)",
        lineHeight: "30px",
        fontWeight: "var(--font-title-weight)",
        margin: 0,
        textTransform: "uppercase"
    }

    let marginLeft = "0px";
    let backgroundHeight = "376px";

    let betFollowWinText: SxProps<Theme> = {
        display: 'inline',
        fontFamily: "var(--font-body)"
    }

    if (window.theme === themeC.Dbet) {
        betFollowWin.fontFamily = "Righteous";
        betFollowWin.fontSize = "14px";
        betFollowWin.fontWeight = 400;
        betFollowWin.lineHeight = "17.38px";
    }

    if (window.theme === themeC.Orange) {
        marginLeft = "-20px";
        backgroundHeight = "260px";
        betFollowWin.fontSize = "16px";
        betFollowWin.fontWeight = 700;
        betFollowWin.lineHeight = "19px";
        betFollowWinText = {
            display: 'inline',
            fontSize: "12px",
            lineHeight: "14px"
        }
    }

    let groupIcon = `url(${kungGroup})`;
    let validIcon = `url(${kungValid})`;
    let winIcon = `url(${kungWin})`;
    let text = 'white';

    return (

        <Grid container sx={{
            width: "100%",
            height: backgroundHeight,
            background: backgroundImage,
            justifyContent: "center",
            alignItems: "center",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "var(--text-white)"
        }}>

            <Grid container justifyContent="center" >
                <Grid item sm={5} height="100%">
                    <Grid container direction="column" alignItems="center" sx={boxInPicture}>

                        <List className='list-item'>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {(theme === themeC.Kung || theme === themeC.Dbet) ? <Box sx={{ backgroundImage: groupIcon, width: "47px", height: "28px" }} /> :
                                        <GroupsIcon sx={textColor} />}

                                </ListItemIcon>
                                <Grid container direction="column" sx={{ color: text, marginLeft: { marginLeft }, marginTop: "5px", marginBottom: "5px" }}>

                                    <Typography
                                        sx={betFollowWin}
                                    >
                                        {t('join_title')}
                                    </Typography>

                                    <Typography
                                        sx={betFollowWinText}
                                        component="span"
                                        variant="body2"
                                    >
                                        {t('join_text')}
                                    </Typography>
                                </Grid>
                            </ListItem>
                            {(theme === themeC.Kung || theme === themeC.Dbet) &&
                                <ListItem>
                                    <Grid container direction="row" justifyContent="center" sx={{ marginTop: "10px" }}>
                                        <Box sx={{ border: "2px solid " + borderColor, width: "18px", height: "0px" }} />
                                    </Grid>
                                </ListItem>
                            }
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {(theme === themeC.Kung || theme === themeC.Dbet) ? <Box sx={{ backgroundImage: validIcon, width: "40px", height: "40px" }} /> :
                                        <TaskAltIcon sx={textColor} />}
                                </ListItemIcon>
                                <Grid container direction="column" sx={{ color: text, marginTop: "5px", marginBottom: "5px", marginLeft: { marginLeft } }}>
                                    <Typography

                                        sx={betFollowWin}
                                    >
                                        {t('valid_title')}
                                    </Typography>
                                    <Typography
                                        sx={betFollowWinText}
                                        component="span"
                                        variant="body2"

                                    >
                                        {t('valid_text')}
                                    </Typography>
                                </Grid>

                            </ListItem>

                            {(theme === themeC.Kung || theme === themeC.Dbet) &&
                                <ListItem>
                                    <Grid container direction="row" justifyContent="center" sx={{ marginTop: "10px" }}>
                                        <Box sx={{ border: "2px solid " + borderColor, width: "18px", height: "0px" }} />
                                    </Grid>
                                </ListItem>
                            }

                            <ListItem disablePadding>
                                <ListItemIcon>
                                    {(theme === themeC.Kung || theme === themeC.Dbet) ? <Box sx={{ backgroundImage: winIcon, width: "40px", height: "40px" }} /> :
                                        <EmojiEventsIcon sx={textColor} />}

                                </ListItemIcon>
                                <Grid container direction="column" sx={{ color: text, marginTop: "5px", marginBottom: "5px", marginLeft: { marginLeft } }}>
                                    <Typography

                                        sx={betFollowWin}
                                    >
                                        {t('win_together_title')}
                                    </Typography>

                                    <Typography
                                        sx={betFollowWinText}
                                        component="span"
                                        variant="body2"

                                    >
                                        {t('win_together_text')}
                                    </Typography>
                                </Grid>

                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );

}
export default SyndicateInfoBox;