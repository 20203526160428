import { usePlayer } from "../player/playerContext";
import { QueryResult, useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";
import * as types from './types';

const useCouponShares = (showActive: boolean, showSettled: boolean, page: number = 0, size: number = 10) => {
  const [player] = usePlayer() as any;

  const queryCache = useQueryCache();
  let couponSharesUrl = player && player._links.couponShares.href;
  couponSharesUrl += '?showActive=' + showActive.toString() + '&showSettled=' + showSettled.toString();
  couponSharesUrl += '&size=' + size + '&page=' + page;

  const key = 'couponShares' + showActive.toString() + showSettled.toString() + page + size;

  let result = useQuery<types.CouponSharesResponse, unknown>([key, couponSharesUrl], () => {

    return fetchApi(couponSharesUrl)
      .then(response => response.json()).then(r => {
        return {
          couponShares: r._embedded.couponShares,
          page: r.page,
          totalPages: r.totalPages,
          pageSize: r.pageSize,
          totalSize: r.totalSize
        }
      })
  }, {
    enabled: !!player,
    initialData: player && player._embedded.couponShares,
    initialStale: true,
  })

  const reloadCouponShares = () => queryCache.invalidateQueries(key, couponSharesUrl);
  return [
    result.data,
    { ...result, reloadCouponShares }
  ] as [types.CouponSharesResponse | undefined, QueryResult<types.CouponSharesResponse> & {
    reloadCouponShares: () => Promise<any>
  }]
}

export default useCouponShares;