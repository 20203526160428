import React, {useEffect, useRef} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Redirect
} from "react-router-dom";

import {AuthProvider} from "./auth/authContext";
import {QueryCache, ReactQueryCacheProvider} from 'react-query';
import {ApiProvider} from "./api/apiContext";
import {PlayerProvider} from "./player/playerContext";
import LandingPage from './landing/landing';
import RoutedRound from './routedround';
import CouponView from './couponview';
import RoundHistory from './roundhistory/roundhistory';
import "./translations/i18n";
import Syndicate from './syndicates/syndicate';
import AgentAdmin from './agents';
import AgentDetail from './agents/agentdetail';
import UpdateSyndicateForm from './agents/updatesyndicateform';
import AdvancedCouponView from './advancedcouponview';
import CouponShareList from './couponsharelist/couponsharelist';
import Help from './help/help';
import ApiBetting from './apibetting';
import {ThemeProvider, createTheme} from '@mui/material';
import {getTheme} from './theme/themehelper';
import PlayAgent from './syndicates/playagent';
import {getLanguage} from './translations/languagehelper';
import {initTheme} from './theme/inittheme';
import {operatorC} from "./common/operatorhelpers";
import i18n from "i18next";

const OPERATOR_MESSAGE_SCROLL_TO_TOP = 'scroll_to_top';
const OPERATOR_MESSAGE_SCROLL_TO_TOP_DEF_DELAY = 200

const OPERATOR_MESSAGE_BET_SUCCESS = 'bet_success';

export const operatorAdviceScrollToTop = (delay) => {
    // advise operator that it is desirable to scroll the page to the top
    window.parent.postMessage(OPERATOR_MESSAGE_SCROLL_TO_TOP, '*');

    if (delay && delay < 0) {
        // Explicitly negative delay indicate no resend
        return
    }

    let actualDelay = OPERATOR_MESSAGE_SCROLL_TO_TOP_DEF_DELAY;
    if (delay) {
        actualDelay = delay
    }

    console.debug('sending scroll to top(' + actualDelay + ')');
    setTimeout(() => {
        window.parent.postMessage(OPERATOR_MESSAGE_SCROLL_TO_TOP, '*');
    }, actualDelay)
}

export const operatorAdviceBetSuccess = (data) => {
    //console.info("posting 'bet_success' message to the parent window", data)
    window.parent.postMessage(data, '*');

    setTimeout(() => {
        window.parent.postMessage(data, '*');
    }, 200)
}

export const operatorAdviceContentResize = (data) => {
    //console.info("posting 'resize' message to the parent window", data)
    window.parent.postMessage(data, '*');

    setTimeout(() => {
        window.parent.postMessage(data, '*');
    }, 200)
}

const handleRouteChange = () => {
    console.debug('handleRouteChange calling scroll to top');
    operatorAdviceScrollToTop(500);
}

// improvised component to hook into router context
const RouteSwitchListener = () => {
    let location = useLocation();
    useEffect(() => {
        handleRouteChange(location);
    }, [location])

    return <div/>
}

function App() {
    const urlParams = new URLSearchParams(window.location.search);
    let languageSetTo = getLanguage(urlParams);
    i18n.changeLanguage(languageSetTo);

    let operatorId
    let operatorToken
    let operatorGameId
    let operatorPlayerId
    let operatorCurrency
    let operatorLanguage
    let operatorChannel
    let operatorJurisdiction
    let operatorExtraData
    let homeUrl
    let roundId
    let syndicateId

    let navigationKey = urlParams.get("navigationKey");
    let navigationId = urlParams.get("navigationId");

    switch (navigationKey) {
        case "round":
            roundId = navigationId;
            break;
        case "syndicate":
            syndicateId = navigationId;
            break;
        default:
            break;
    }


    operatorId = urlParams.get("operatorId");
    let compatMode = operatorId && (operatorId === operatorC.Mockop || operatorId === operatorC.EuroOperator);
    if (compatMode) {
        operatorId = urlParams.get("operatorId");
        operatorToken = urlParams.get("operatorToken");
        operatorPlayerId = urlParams.get("operatorPlayerId");
        homeUrl = urlParams.get('homeUrl');
        operatorExtraData = urlParams.get("extraData");
    } else {
        operatorId = urlParams.get("operatorid");
        operatorToken = urlParams.get("token");
        operatorGameId = urlParams.get("gameid");
        operatorPlayerId = urlParams.get("playerid");
        operatorCurrency = urlParams.get("currency");
        operatorLanguage = urlParams.get("language");
        operatorChannel = urlParams.get("channel");
        operatorJurisdiction = urlParams.get("jurisdiction");
    }


    if (!operatorGameId) {
        operatorGameId = 'poolx';
    }
    if (!operatorPlayerId) {
        operatorPlayerId = '';
    }
    if (!operatorCurrency) {
        operatorCurrency = '';
    }
    if (!operatorLanguage) {
        operatorLanguage = '';
    }
    if (!operatorChannel) {
        operatorChannel = 'online';
    }
    if (!operatorJurisdiction) {
        operatorJurisdiction = '';
    }
    if (!operatorExtraData) {
        operatorExtraData = '';
    }
    if (!homeUrl) {
        homeUrl = '';
    }

    let pTheme = getTheme(urlParams, languageSetTo, operatorId)
    initTheme(pTheme);

    const theme = createTheme({
        name: pTheme,
        palette: {
            primary: {
                main: '#2196F3',
            },
            secondary: {
                main: '#FFC107',
            },
        },
    });

    window.operatorId = operatorId;
    window.theme = pTheme;

    const appRef = useRef(null)
    // Posting resize messages to the parent window for operators that don't want to use 'iframe-resizer`
    useEffect(() => {
        const element = appRef?.current;
        if (!element) return;

        const observer = new ResizeObserver((entries) => {
            // Do something when the element is resized
            const width = entries[0]?.contentBoxSize[0]?.inlineSize;
            const height = entries[0]?.contentBoxSize[0]?.blockSize;
            if (width || height) {
                let contentResizeEvent = {
                    type: "content_resize",
                    height: height,
                    width: width,
                    units: "px",
                }
                operatorAdviceContentResize(contentResizeEvent)
            }
        });

        observer.observe(element);
        return () => {
            // Cleanup the observer by unobserving all elements
            observer.disconnect();
        };
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <ReactQueryCacheProvider queryCache={new QueryCache()}>

                <AuthProvider
                    operatorId={operatorId}
                    operatorGameId={operatorGameId}
                    operatorPlayerId={operatorPlayerId}
                    operatorChannel={operatorChannel}
                    operatorLanguage={operatorLanguage}
                    operatorToken={operatorToken}
                    operatorCurrency={operatorCurrency}
                    operatorJurisdiction={operatorJurisdiction}
                    operatorExtraData={operatorExtraData}
                    homeUrl={homeUrl}
                >
                    <ApiProvider>
                        <PlayerProvider>
                            <div className="wrapper" ref={appRef}>
                                <Router>
                                    <RouteSwitchListener/>
                                    <Switch>
                                        <Route path={"/agents/:agentId/syndicates/:syndicateId"}>
                                            <UpdateSyndicateForm/>
                                        </Route>
                                        <Route path={"/agents/:agentId"}>
                                            <AgentDetail/>
                                        </Route>
                                        <Route path={"/agentadmin"}>
                                            <AgentAdmin/>
                                        </Route>
                                        <Route path={"/apibetting"}>
                                            <ApiBetting/>
                                        </Route>
                                        <Route path="/:tipsId/rounds/:roundId">
                                            <RoutedRound/>
                                        </Route>
                                        <Route path="/rounds/:roundId">
                                            <RoutedRound/>
                                        </Route>
                                        <Route path="/mycoupons">
                                            <CouponShareList/>
                                        </Route>
                                        <Route path="/roundhistory">
                                            <RoundHistory/>
                                        </Route>
                                        <Route path="/coupon/:couponId">
                                            <CouponView/>
                                        </Route>
                                        <Route path="/playagent">
                                            <PlayAgent agentId={0}/>
                                        </Route>
                                        <Route path="/advancedCouponView/:couponShareId">
                                            <AdvancedCouponView/>
                                        </Route>
                                        <Route path="/syndicate/:syndicateId">
                                            <Syndicate/>
                                        </Route>
                                        <Route path="/syndicatesforagent/:agentId"
                                               render={(props) => {
                                                   return (
                                                       <PlayAgent agentId={props.match.params.agentId}/>
                                                   )
                                               }}>
                                        </Route>
                                        <Route path="/help">
                                            <Help/>
                                        </Route>
                                        <Route
                                            path="/clear"
                                            render={() => {
                                                roundId = undefined;
                                                syndicateId = undefined;
                                                return (
                                                    <LandingPage/>
                                                )
                                            }}
                                        />
                                        <Route
                                            path="/"
                                            render={() => {

                                                if (syndicateId) {
                                                    return (
                                                        <Redirect to={"/syndicate/" + syndicateId}/>
                                                    );
                                                }

                                                return (
                                                    roundId ?
                                                        <Redirect to={"/rounds/" + roundId}/> :
                                                        <LandingPage/>
                                                )
                                            }}
                                        />

                                    </Switch>
                                </Router>
                            </div>
                        </PlayerProvider>
                    </ApiProvider>
                </AuthProvider>

            </ReactQueryCacheProvider>
        </ThemeProvider>
    );
}

export default App;
