import {useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";
import { useTranslation } from "react-i18next";

export function createAgentSyndicateActions(syndicate, queryCache, t) {
  
  const patch = values =>
      fetchApi(syndicate._links.self.href, {
        method: 'PATCH',
        body: JSON.stringify(values)
      }).then(response => {
        if (response.ok) {         
          queryCache.invalidateQueries('syndicates');
          return t('syndicate_submitted');
        } else {
          return response.statusText;
        }
      })

  return {
    update: values => {
      return fetchApi(syndicate._links.self.href, {
        method: 'PUT',
        body: JSON.stringify(values)
      }).then(response => {
        if (response.ok) {          
          queryCache.invalidateQueries('syndicates');
          return t('syndicate_updated');
        } else {
          return response.statusText;          
        }
      })
    },
    patch,
    submit: syndicate.status === 'DRAFT' && (() => patch({status: 'SUBMITTED'})),
    pause: syndicate.status === 'SUBMITTED' && (() => patch({status: 'PAUSED'})),
    activate: syndicate.status === 'PAUSED' && (() => patch({status: 'SUBMITTED'}))
  }
}

const useAgentSyndicateActions = (syndicate) => {  
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  return createAgentSyndicateActions(syndicate, queryCache, t);
}

export default useAgentSyndicateActions;