export const API_SV = {    
    format: {        
        title:"Filformat",
        p1: "Filen ska vara en textfil",
        p2: "Första raden ska innehålla id för omgången. Du hittar id under information på omgångens sida eller i listan ovan",
        p3: "Filen ska innehålla enkelrader. Första tecknet är E",
        p4: "Varje rad i filen innehåller en systemrad",        
        p5: "Matcherna separeras med kommatecken" 
    },
    example:{
        title:"Exempel",
        row1:"123",
        row2:"E,1,2,X,1,2,1,2,X,1,1,1,1,1",
        row3:"E,1,2,X,1,2,1,2,X,1,1,1,1,1"
    },
    info:"Ladda upp en fil med rader du skapat i ett tipsprogram eller manuellt"
}