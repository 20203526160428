import { useState } from 'react';
import { useParams } from "react-router-dom";
import NavBar from '../../navbar';
import image from '../../images/footballfield.jpg';
import { BackgroundImage, H2 } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import useAgent from '../useAgent';
import useAgentSyndicate from '../useAgentSyndicate';
import SyndicateCoupon from '../../syndicates/syndicatecoupon';
import { usePlayer } from "../../player/playerContext";
import useDetailRound from '../../rounds/useDetailRound';
import { Redirect } from "react-router-dom";
import SlimCoupon from '../../slimcoupon/slimcoupon';
import { Button, Grid } from '@mui/material';
import { betTotal } from '../../slimcoupon/couponhelpers';

export default function UpdateSyndicateForm() {
  const { t } = useTranslation();
  const { agentId, syndicateId } = useParams();
  const [agent] = useAgent(agentId);
  const [syndicate] = useAgentSyndicate(agent, syndicateId);

  const round = useDetailRound((syndicate ? syndicate.roundId : undefined));
  const [updateSyndicateMessage, onUpdateSyndicateMessage] = useState(undefined);
  const [submitSyndicateMessage, onSubmitSyndicateMessage] = useState(undefined);
  const [player, config] = usePlayer();
  let [showProPick, setShowProPick] = useState(false);

  let form = <div />;

  if (!config.agentSyndicateBetting) {
    return <div />;
  }

  if (!syndicate) {
    return <div />;
  }

  const onSubmit = async (coupon) => {
    syndicate.pendingRoundCoupon = coupon;
    var totalStake = betTotal([], coupon.bets[0]) * coupon.stakePerRow.amount;
    const stake = Math.round(totalStake * 100 / syndicate.targetNShares) / 100;
    syndicate.stakePerShare = { currency: coupon.stakePerRow.currency, amount: '' + stake + '' };
    syndicate.update(syndicate).then(response => {
      onUpdateSyndicateMessage(response);
      syndicate.submit().then(submitResp => {
        onSubmitSyndicateMessage(submitResp);
      })
    }
    );
  }

  switch (syndicate.status) {
    case "DRAFT":
      form = <SlimCoupon round={round.round} syndicate={syndicate} onSubmit={onSubmit} theme={window.theme}
        showProPick={showProPick} setShowProPick={setShowProPick} onChangeFixture={()=>{}} stakeMultiplier={1}></SlimCoupon>
      break;
    case "SUBMITTED":
      form = <SyndicateCoupon syndicate={syndicate} round={round.round}></SyndicateCoupon>
      break;
    default:
  }

  const mainBox = {
    background: "var(--background_1)",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    paddingTop: "42px",
    marginBottom: "42px",
    justifyContent: "space-between"
  }

  let borderWidth = "1px";

  const button = {
    padding: '38px',
    paddingTop: '6px',
    paddingBottom: '6px',
    gap: '4px',
    color: 'var(--button-text-color)',
    height: '32px',
    textTransform: 'var(--text-transform-button)',
    fontWeight: 'var(--font-weight-button)',
    fontSize: '12px',
    lineHeight: '18px',
    border: borderWidth + ' solid #DADADA',
    borderRadius: '5px',
    backgroundColor: "var(--button-color)",
    "&:hover": {
      backgroundColor: "var(--button-hover-color)",
      border: borderWidth + ' solid #DADADA',
    }
  }

  if (submitSyndicateMessage !== undefined) {
    return <Redirect to={"/agents/" + agentId} />
  }

  return (
    <>
      <NavBar whichToUnderline="5" />

      <BackgroundImage height={250} image={image}>
        <H2 color="#fff">
          {t('bet_with_syndicates')}
        </H2>
      </BackgroundImage>
      <Grid container sx={mainBox} direction="column">
        <Grid container sx={{ justifyContent: "center", marginBottom: "20px" }}>
          <H2>{syndicate.name}</H2>
        </Grid>

        {syndicate.status === "DRAFT" &&

          <Grid container justifyContent="center" sx={{ marginTop: '10px', marginBottom: '20px' }}>
            <Button variant="outlined" onClick={() => setShowProPick(true)} sx={button}>
              {t('pro_pick')}
            </Button>
          </Grid>

        }


        {form}
        {(updateSyndicateMessage && <span>{updateSyndicateMessage}</span>)}
        {(submitSyndicateMessage && <span>{submitSyndicateMessage}</span>)}
      </Grid>

    </>
  );

}