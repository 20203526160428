import { usePlayer } from "../player/playerContext";
import { useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";

const useDetailRound = (roundId) => {
    const [player] = usePlayer();
    let roundsUrl = player && player._links.rounds.href;
    roundsUrl += '/';
    roundsUrl += roundId;
    const { status, error, data } = useQuery(['detailRound', roundsUrl], () => {
        if (!roundId) {
            return null;
        }
        if (player === undefined) {
            return null;
        }
        return fetchApi(roundsUrl).then(response => response.json()).then(r => r._embedded.round)
    }, {

    })

    const queryCache = useQueryCache();
    const reloadDetailRound = () => queryCache.invalidateQueries(['detailRound', roundsUrl]);

    const round = data;
    return { status, error, round, reloadDetailRound };
}

export default useDetailRound;