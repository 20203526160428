import NavBar from '../navbar';
import image from '../images/footballfield.jpg';
import { ApiBettingWrapper } from './styles';
import { BackgroundImage, Button, H2, H3, Label } from '../reusable-components';
import { useTranslation } from "react-i18next";
import useShallowRounds from '../rounds/useShallowRounds';
import { useState } from 'react';
import useApiBetting from './useApiBetting';
import { getDateString, getRoundName } from '../common/RoundHelper';
import Moment from 'react-moment';
import ConfirmPaymentApi from './confirmpaymentapi';
import moment from 'moment';

export default function ApiBetting() {
  const { t } = useTranslation();
  const [shallowRounds] = useShallowRounds(true,false);
  const [file, setFile] = useState();
  const [info, setInfo] = useState();
  const { uploadFile } = useApiBetting();
  const [response, setResponse] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  let rounds = shallowRounds?.rounds;

  let locale = t('locale');

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setFile();
    setInfo();
  }

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    setShowConfirm(true);
  }

  const uploadFileAfterConfirm = () => {
    setSubmitting(true);
    uploadFile(file, info)
      .then(response => {
        setResponse(response);
        setSubmitting(false);
      }
      )
  }

  let fileReader = new FileReader();
  let round = undefined;
  let count = undefined;

  if (file) {
    fileReader.onload = function () {
      setInfo(fileReader.result);
    };
    fileReader.readAsText(file)
  }

  if (info) {
    var roundId = info.split('\n')[0];
    roundId = roundId.replace('\r', '');
    round = rounds.filter(r => r.id === roundId)[0];
    let lines = info.split('\n');
    count = lines.filter(l => l.indexOf('E') >= 0).length;
  }

  let roundName = getRoundName(round, t);

  let futureRounds = [];
  if (rounds !== undefined) {
    futureRounds = rounds.filter(r => moment(r.openTo) > new moment());
  }

  return (
    <>
      <NavBar whichToUnderline="7" />
      <ApiBettingWrapper>
        <BackgroundImage height={250} image={image}>
          <H2 color="#fff">
            {t('api_betting')}
          </H2>
        </BackgroundImage>
        <div className="background-div">
          <div className='format-box'>
            <div className='info-row-title'>
              <H3>{t('api:info')}</H3>
            </div>

            {
              <div className='info-box'>

                <div className='info-row'>
                  <div className='info-title'>{t('file')}:</div>
                  <div className='info-value'>{file && `${file.name}`}</div>
                </div>
                <div className='info-row'>
                  <span className='info-title'>{t('round')}:</span>
                  <span className='info-value'>{round && round.id}</span>
                </div>
                <div className='info-row'>
                  <span className='info-title'>{t('name')}:</span>
                  <span className='info-value'>{round && roundName}</span>
                </div>
                <div className='info-row'>
                  <span className='info-title'>{t('starts')}:</span>
                  <span className='info-value'>{round && <Moment format={"yyyy-MM-DD"} locale={locale}>{round.openTo}</Moment>}</span>
                </div>
                <div className='info-row'>
                  <span className='info-title'>{t('number_of_rows')}:</span>
                  <span className='info-value'>{count}</span>
                </div>
              </div>
            }

            <br />
            <br />

            <div>
              <input className="file" id="file" type="file" onChange={handleFileChange} />
              <Label htmlFor="file">
                {t('select_file')}
              </Label>

              <Button className="btn-upload" disabled={round === undefined || file === undefined || count === 0} onClick={() => handleUploadClick()}>{t('upload')}</Button>
            </div>
            <br />

          </div>
          <div className='format-box'>
            <div className='table-rounds'>
              <table>
                <thead>
                  <tr>
                    <td className='table-head'>{t('id')}</td>
                    <td className='table-head'>{t('starts')}</td>
                    <td className='table-head'>{t('name')}</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    futureRounds.map((round, index) => {

                      return (
                        <tr key={'row' + index}>
                          <td className='table-cell'>{round.id}</td>
                          <td className='table-cell'>{getDateString(round, t)}</td>
                          <td className='table-cell'>{getRoundName(round, t)}</td>
                        </tr>);

                    })
                  }
                </tbody>
              </table>
            </div>

          </div>
          <div className='format-box'>
            <div className='format-title'>
              <H3>{t('api:format.title')}</H3>
            </div>
            <ul className='format-text'>
              <li>{t('api:format.p1')}</li>
              <li>{t('api:format.p2')}</li>
              <li>{t('api:format.p3')}</li>
              <li>{t('api:format.p4')}</li>
              <li>{t('api:format.p5')}</li>

              <br />
              <div className='example'>
                <H3>{t('api:example.title')}:</H3>
                {t('api:example.row1')}<br />
                {t('api:example.row2')}<br />
                {t('api:example.row3')}
              </div>
            </ul>

            <br />


          </div>



        </div>

        {showConfirm && round && (
          <ConfirmPaymentApi
            toggle={closeConfirm}
            submitting={submitting}
            response={response}
            uploadFile={uploadFileAfterConfirm}
            roundId={round.id}
            count={count}
          />
        )}
      </ApiBettingWrapper>
    </>
  );
}