import styled from 'styled-components';

export const ApiBettingWrapper = styled.div`
  .background-div {
    background: var(--list-background);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 42px;    
  }

  .file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }

  .info-box {
    background: var(--list-boxes-primary);    
    width: 300px;
    display: flex;
    flex-direction: column;        
  }

  .info-row {       
    display: flex;
    flex-direction: row;   
    justify-content: space-between;     
  }

  .info-row-title {       
    display: flex;
    flex-direction: row;   
    justify-content: flex-start; 
    width: 100%;
  }

  .info-row-value {       
    font-family: var(--font-title-3);
    font-size: 15px;          
    margin: 10px 0px 10px 0px;
    
  }

  .info-title {
    font-family: var(--font-title-3);
    font-size: 15px;      
    font-weight: bold;
    margin: 10px 10px 10px 0px;    
  }

  .info-value {    
    font-family: var(--font-title-3);
    font-size: 15px;          
    margin: 10px 0px 10px 0px;
  }

  .example {
    display: flex;
    flex-direction: column;   
    justify-content: flex-start; 
    font-family: var(--font-title-3);
    font-size: 15px;          
    margin: 3px 0px 3px 0px;
  }

  .format-box {
    width: 600px;
    padding: 10px 10px 10px 30px;    
    background: var(--list-boxes-primary);    
    display: flex;
    flex-direction: column;   
    justify-content: flex-start;     
    margin: 10px 0px 60px 0px;
  }

  .format-text {        
    margin: 10px 0px 10px 0px;
    padding: 0px;
  }

  .format-text li{            
    padding: 5px;
  }

  .format-title {    
    width: 100%;
    margin: 10px 0px 0px 0px;
  }

  .btn-upload {
    margin: 0px 0px 0px 50px;
  }

  .table-rounds {    
    width: 100%;
    margin: 0px 0px 10px 0px;
  }

  .table-cell {        
    font-family: var(--font-title-3);
    font-size: 15px;          
    padding: 5px 30px 5px 0px;
  }

  .table-head {        
    font-family: var(--font-title-3);
    font-size: 15px;          
    padding: 5px 5px 5px 0px;
    font-weight: bold;
  }
`;