import React from 'react';
import { TeamWrapper } from './styles';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from "react-i18next";
import useAgent from '../useAgent';
import useAgentSyndicates from '../useAgentSyndicates';

const AgentDiv = ({
  shallowAgent
}) => {

  const [agent] = useAgent(shallowAgent ? shallowAgent.id : undefined);
  const [syndicates] = useAgentSyndicates(agent);
  const { t } = useTranslation();

  if(!shallowAgent) return null;  

  if(!agent) return null;  
  
  return (
    <TeamWrapper>
      <div className="top-div">
        <span className="bold-span">{agent.name}</span>        
        <Link className="bold-span link-div" to={`/agents/${agent.id}`}>{t('to_agent')}<ArrowForwardIosIcon className="icon" /></Link>
      </div>
      <div className="bottom-div">        
        <span className="bold-span">{t('syndicates')}: {(syndicates && syndicates.length)}</span>        
      </div>
    </TeamWrapper>
  );
}

export default AgentDiv;