import styled from 'styled-components';

export const RoundInformationWrapper = styled.div`
display: flex;    
flex-direction: column;
align-items: center;
bottom-margin:40px;
white-space: pre-wrap;

H2 {
    padding: 60px 0px; 10px 0px;    
}

.flex-start{
    display: flex;
    flex-direction: column;
    align-items: flex-start;       
    width: 80%;    
}

.help-paragraph{    
    display: flex;
    width: 100%;
    flex-direction: row;
    font-family: var(--font-body);
    color: var(--text-black-dark-background);        
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;    
}

.rtl{
    text-align: right;
    direction: rtl;
}

.help-header{
    font-family: var(--font-title-3);
    color: var(--font-title-color);
    font-size: '15'px;  
    line-height: 16px;
    font-weight: bold;
    margin: 0px 0px 0px 0px;
    display: flex;
    width: 100%;
    flex-direction: var(--flex-direction);
}

.flex-list{
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
    margin-bottom: 100px;
}

.go-back{
    display: flex;
    flex-direction: row;    
    margin: 10px 0px 10px 0px;
    align-items: flex-start;
    width:90%;
}

.go-back-button{
    display: flex;
    flex-direction: row;    
    margin: 10px 0px 10px 0px;
}

@media screen and (min-width: 601px) {
    .flex-list {
      width: 400px;
    }
    
    .subheader {
      width: 400px;
    }
}

@media screen and (max-width: 600px) {
    .flex-list {
      width: 90%;
    }
  
    .subheader {
      width:90%;
    }  
}

.subheader{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
}

.header{
    margin: 10px 0 0 0;
}

.flex-row-odd{
    display: flex;    
    flex-direction: row;
    background: #FFFFFF;
    justify-content: space-between;    
    align-items: center;    
    height: 30px;    
    padding: 0 5px; 0 5px;
}

.flex-row-even{
    display: flex;    
    flex-direction: row;    
    justify-content: space-between;    
    align-items: center;    
    height: 30px;
    padding: 0 5px; 0 5px;
}

.flex-row-header span {
    font-weight: bold;
}

    
    
`;