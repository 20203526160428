import styled from 'styled-components';

export const Wrapper = styled.div`

  .event-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
  }

  .event-row.home-team-event {
    align-items: flex-start;
  }

  .event-row.centered-event {
    align-items: center;
  }

  .event-row-wrapper {
    margin: 0 15px;
  }

  .event-row-wrapper.centered-event {
    width: 30%;
  }

  @media screen and (max-width: 600px) {
    .event-row-wrapper {
      width: 70%;
    }
  }

  @media screen and (min-width: 601px) {
    .event-row-wrapper {
      width: 50%;
    }
  }
`;