import styled from 'styled-components';

export const CouponViewWrapper = styled.div`
  display: flex;
  margin-left:var(--coupon-left-margin);
  flex-direction: var(--flex-direction); 

  .top-border {
    border-bottom: 1px solid #060707;
    height: 1px;
  }

  @media screen and (min-width: 951px) {
    .left-flex {
      min-width: 402px;
    }
    
    .right-flex {
      width: 100%;
    }
  }
  
  
  @media screen and (max-width: 950px) {
    flex-direction: column;
    margin-top: 0px;
  }
  
`;