import React from 'react';
import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../../common/CurrencyFormatter";
import { Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import { themeC } from '../../../theme/themehelper';

function ResultRowTr({
  index,
  payoutPerRow,
  maxNum,
  isRightToLeft,
  rows,
}) {
  const { t } = useTranslation();
  const { money } = payoutPerRow || {};
  let actualPayout = money.amount;
  const numCorrect = maxNum - index;
  let theme = window.theme;

  let rowSx = {
    fontSize: '11px',
    padding: '5px 14px 5px 14px',
    color: 'var(--text-black)'
  }

  if (index % 2 === 0) {
    rowSx = {
      fontSize: '11px',
      padding: '5px 14px 5px 14px',
      color: 'var(--text-black-dark-background)'
    }
  }

  const alternating = {
    '&:nth-of-type(odd)': {
      backgroundColor: 'var(--background_1)'
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'var(--background_2)'
    },
  }

  if (theme === themeC.Betbeto && actualPayout > 1_000_000) {
    actualPayout = 1_000_000
  }

  return (
    <TableRow sx={alternating}>
      {isRightToLeft ? (
        <>
          <TableCell align='left' sx={rowSx}>{formatStringCurrency(actualPayout, money.currency, t)}</TableCell>
          <TableCell align='right' sx={rowSx}>{rows ? Math.round(rows) : 'NA'} {t('st')}</TableCell>
          <TableCell align='right' sx={rowSx}>{numCorrect}</TableCell>
        </>
      ) : (
        <>
          <TableCell sx={rowSx}>{numCorrect}</TableCell>
          <TableCell sx={rowSx}>{rows ? Math.round(rows) : 'NA'} {t('st')}</TableCell>
          <TableCell align='right' sx={rowSx}>{formatStringCurrency(actualPayout, money.currency, t)}</TableCell>
        </>
      )}
    </TableRow>
  );
}

export default function WinInfoRound({ round, isRightToLeft }) {
  const { t } = useTranslation();
  const { operatorPrizePlan, fixtures = [] } = round || {};
  let groups = {}

  if (operatorPrizePlan) {
    groups = operatorPrizePlan.payoutGroups
  }

  const headerSx = {
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'var(--text-white)',
    padding: '5px 14px 5px 14px'
  }

  return groups ? (

    <Table>
      <TableHead sx={
        {
          background: 'var(--off-black)',
        }
      }>
        {isRightToLeft ?
          <TableRow>
            <TableCell align='left' sx={headerSx}>{t('prize')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('winners')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('correct_rows')}</TableCell>
          </TableRow>
          :
          <TableRow>
            <TableCell sx={headerSx}>{t('correct_rows')}</TableCell>
            <TableCell sx={headerSx}>{t('winners')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('prize')}</TableCell>
          </TableRow>
        }
      </TableHead>
      <TableBody>
        {groups && groups.map((group, i) => (
          <ResultRowTr
            key={`${i} result row`}
            maxNum={fixtures.length}
            index={i}
            unitRate={group.unitsPerRow}
            isRightToLeft={isRightToLeft}
            {...group}
          />
        ))}
      </TableBody>
    </Table>

  ) : <></>;
}