import React from 'react';
import { Wrapper } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { IkonIcon, Button } from '../../reusable-components';

const ConfirmDialog = ({
  text,
  toggle
}) => {

  const handleClose = () => {
    toggle(false);
  }

  return (
    <Wrapper>
      <div className="onclick-div" onClick={handleClose} />
      <div className="confirm-window">
        <div className="flex-end">
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        {IkonIcon(32, 30)}
        <div className="text-objects">
          <span>{text}</span>
          <Button width={350} onClick={() => toggle(false)}>Ok</Button>
        </div>
      </div>
    </Wrapper>
  );
}

export default ConfirmDialog;