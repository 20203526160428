export const RULES_NO = {
    faq: {
        title: "FAQ",
        q1: {
            question: "Hva er pool-betting?",
            answer: "Pool-vedding er når spillerens innsatser blir satt sammen i en pott. Etter at en rake er trukket, vil alle midler bli delt mellom de vinnende spillere. Du vinner hvis du har det nok riktige utfall for runden.",
        },
        q2: {
            question: "Hva er en runde?",
            answer: "En runde er en samling fotballkamper der du velger resultatene. Spillene i en runde starter ofte samme dag. En runde kan ha 8, 12 eller 13 kamper.",
        },
        q3: {
            question: "Hvor mange prisgrupper er det?",
            answer: "Det er 3 premiegrupper i runder med 12 eller 13 kamper. Det er 1 prisgruppe i runder med 8 kamper.",
        },
        q4: {
            question: "Hvordan setter jeg en innsats?",
            answer: "Velg utfallet i hver av kampene i runden. Hvis du vil, kan du velge mer enn ett utfall i hvert spill. Bettet blir økt deretter.",
        },
        q5: {
            question: "Hvordan vinner jeg?",
            answer: "Hvis du har nok riktig valg i følge vinstplanen, vinner du. Det kan være en eller mange vinstgrupper i runden. Det kan være en eller mange vinstgrupper i runden. Alle vinnere i en prisgruppe deler potten i den gruppen.",
        },
        q6: {
            question: "Hva er Pro Pick?",
            answer: "Pro Pick gjør veddemålet for deg. Roboten velger resultatene i spillet for deg til den definerte prisen. Du kan la roboten velge mer sannsynlig spill eller mindre sannsynlige spill, som gir mer eller mindre vinst. ",
        }
    },
    terms: {
        title: "Regler",
        p1: {
            title: "Plassere spill",
            p11: "Lilibet fungerer som en agent for PoolX og alle spill (\"spill\") plasseres direkte i PoolXs potter via Lilibet  (no.lilibet.com). Under omstendigheter der spill ikke når pottens relevante spillere (bruker på Lilibet) får de ikke tilbakebetalt innsatsen. Verken operatøren eller PoolX tar ansvar for tap av gevinster. Operatøren har kontroll over spillernes midler og er betaler ut gevinster. Dersom du har spørsmål i forhold til spillaktiviteten din til potten på Poolxs, bør det rettes til Lilibet. I den grad Lilibet ikke er i stand til å løse eventuelle ulemper, vil Lilibet kontakte PoolX.",
            p12: "Det er ditt ansvar å sjekke at veddemålene du sender inn er riktige før du bekrefter veddemålet. PoolX forbeholder seg retten til å nekte plassering av enhver innsats.",
            p13: "Der det er uenighet om detaljene eller statusen til veddemål, vil PoolX-historikken være den viktigste beviskilden for detaljene/statusen til veddemålet.",
            p14: "PoolX forbeholder seg retten for å suspendere spill på en pool når som helst. PoolX forbeholder seg retten til å annullere en pool i sin helhet eller ethvert spill, for å korrigere åpenbare feil.",
            p15: "Spillerne satser på utfallet av flere relaterte hendelser som er forhåndsvalgt av Poolx (spill).",
            p16: "Spillene skal velges av PoolX som opptrer etter eget skjønn.",
            p17: "Spillerne kan velge å plassere en eller flere spill bestående av minimum minst ett utvalg i hvert spill. Hvert spill består av tre valg. Hjemmeseier (“1”) uavgjort (“X”) eller  borteseier (“2”). Hver innsats identifiseres med et unikt referansenummer når innsatsen blir akseptert.",
            p18: "For et gitt spill kan en spiller gjøre ett eller flere utvalg av alternative resultater. For eksempel hvis du velger \"Home Win\" og \"Draw\" i spill 1 og hjemmeseier i alle andre kamper - vil dette utgjøre to rader. Hver rad har en full enhetskostnad for eksempel 10kr og PoolX BET -plasseringsmotoren vil legge opp mengden rader multiplisert med veddet per rad for å vise det totale beløpet som er satt på innsatsen.",
            p19: "Alle spill er plassert for riktig resultat i det ordinære spillet i samsvar med kampens tjenestemenn inkludert stoppetid, men ekskluderer ekstra tid på noen gyldne punktperiode -straffer eller lignende (hvis de oppstår).",
            p110: "Med forbehold om de andre bestemmelsene i paragraf 1 nedenfor, vil alle spill bli avgjort basert på det offisielle resultatet av den aktuelle hendelsen uavhengig av påfølgende inhabilitet eller endring av resultatet.",
            p111: "I tilfelle det oppstår usikkerhet om et offisielt resultat, forbeholder PoolX seg retten til å suspendere oppgjør av enhver pool inntil usikkerheten kan løses til rimelig tilfredshet.",
            p112: "PoolX forbeholder seg retten til å reversere avgjørelsen av en pott hvis det har blitt avgjort (for eksempel en menneskelig eller teknisk feil). Hvis PoolX avgjør en pott på nytt, kan dette føre til at det blir gjort endringer i spillernes kontosaldo for å gjenspeile endringer i pottens oppgjør.",
            p113: "Dersom ikke nevnt i disse vilkårene, når en pott er avgjort i 72 timer, vil avgjørelse anses som endelig.",
            p114: "Hvis en kamp ikke er fullført - dvs. hele spillperioden (så for eksempel 90 minutter i tilfelle av fotball) i henhold til kampens tjenestemenn pluss noen stoppetid innen innen 4 timer etter den siste planlagte startdatoen for ethvert spill på kupongen, vil det bli satt som uavgjort.",
            p115: "Trekningen vil bli ordnet med sannsynligheter for hvert utfall i spillet. Disse sannsynlighetene finner du under spilldetaljene på potten. Sannsynligheter er definert som: HomeProbability DrawProbability AwayProbability og blir publisert når runden opprettes. Etter dette blir de aldri endret.",
            p116a: "Det tilfeldige tallet som brukes i trekningen vil bli hentet fra det første spillet som ikke er uavgjort og ikke brukes i noen annen trekning i runden. Det siste antallet av minuttet for noen av følgende hendelser vil bli brukt:",
            p116b: "Mål gult kort rødt kort",
            p116c: "Minuttet som brukes vil være det øyeblikket som er oppgitt av det organiserende organet i turneringen spillet er en del av. Hvis hendelsestiden blir gitt som minutter og sekunders arrangementer mellom 0:00 og 0:59, vil bli ansett for å være minutt 1 og så videre. Hendelser i første omgang vil bli betraktet som minutt 45. Hendelser i andre omgang vil ekstra tid bli betraktet som minutt 90. Antallet vil være mellom 0 og 9. Dette tallet er delt med 10 for å gi et tall mellom 0 og 1. Dette tallet er definert som tilfeldig.",
            p116d: "Utfallet av spillet i trekningen blir valgt av",
            p116e: "Hvis tilfeldig er mindre enn hjemmeprobabilitetsresultatet av spillet er hjemme\n\t Hvis tilfeldig er mindre enn HomeProbability + DrawProbability -utfallet av spillet er trekning Ellers utfallet av spillet er borte",
            p117: "Trekningene for runden vil bli gjennomført i samme rekkefølge som spillene vises i runden. (Hvis både spill 3 og 6 må trekkes, blir spill 3 bli trukket først ved å bruke hendelser fra spill 1. Spill 6 vil senere bli trukket med hendelser fra spill 2)",
            p118: "Hvis mer enn halvparten av antallet spill i en runde må trekkes, vil runden bli kansellert og innsatser refunderes.",
            p119: "Hvis det planlagte stedet for en etappe endres etter at en pott er blitt lastet av PoolX, vil etappen bare bli trukket hvis det nye arenaen er hjemmebane for det originale borteteamet. For kamper som spilles på et nøytralt spillespill vil stå uavhengig av hvilket lag som er oppført som hjemmelaget",            
            p120: "PoolX påtar seg ikke noe ansvar for å skrive overførings- og/eller evalueringsfeil. PoolX påtar seg heller ikke noe ansvar for feilhet eller ufullstendighet eller unøyaktighet av informasjonen gitt via nettstedet inkludert (uten begrensning) noen live -score og resultater.",
            p121: "Hvis det er inkonsekvent spill mellom en bestemmelse om generell anvendelse og en bestemmelse om en spesifikk sport eller en spesifikk veddemålstype, vil sistnevnte seire.",
       },
        p2: {
            title: "Gevinster",
            p21: "Alle spill er pottinnsats der gevinster bestemmes med henvisning til pengene som er satt og antall vinnende rader i bassenget.",
            p22: "Hver pott vil ha en eller flere prisgrupper. Hver av disse gruppene har spesifisert hvor mange riktige tegn et spill trenger for å kvalifisere som en vinner i den gruppen",
            p23: "Midlene i en prisgruppe er delt på antall riktige rader i den prisgruppen. Hvis det ikke er noen vinnere i en prisgruppe, blir midlene i den gruppen overført til neste prisgruppe i rekkefølge av mindre og mindre nødvendige riktige skilt.",
            p24: "Hvis prisen til hver vinner i en prisgruppe er under den minste seieren at gruppen blir kansellert og midlene overføres til prisgruppen med mest nødvendige riktige skilt.",
            p25: "Minimumsseieren er definert som 10kr. Hvis du plasserer spill i andre valutaer, vil minimumsinnsatsen variere på grunn av valutakurs.",
            p26: "Hvis midler er igjen etter at handlingene i 2.3 og 2.4 er blitt brukt, blir de overført til bassengfondet. Bassengfondet vil bli brukt til å øke bassenget i kommende runder.",
            p27: "20% av midlene som er satt i bassenget blir trukket som kommisjon. De resterende 80% er delt mellom prisgruppene som angitt på den spesifikke runden på bassenget",
            amendment: "Dato for siste endring 10. desember 2021",
            
        }
    }
}


