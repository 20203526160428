import styled from 'styled-components';

export const SyndicateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  padding-bottom: 120px;

  .green-div-column {
    background: var(--background_1);
    display: flex;
    align-items: center;
    padding-top: 42px;
    padding-bottom: 10px;
    flex-direction: column;
    width: 595px;
  }

  .green-div-row {
    background: var(--background_1);
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;    
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;    
  }

  .white-div {
    background: var(--background_2);
    display: flex;
    align-items: center;    
    padding-top: 42px;
    padding-bottom: 42px;    
    flex-direction: column;
  }

  .info-item {
    margin: 5px;
  }

  .title-margin {
    margin-bottom: 10px;

  }

  .title-under {
    font-weight: bold;
  }

  .bold {
    font-weight: bold;
  }
`;