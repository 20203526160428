export const API_NO = {
    format: {
        title: "Filformat",
        p1: "Filen skal være en tekstfil",
        p2: "Den første raden skal inneholde ID for runden. Du kan finne ID på informasjonssiden for runden.",
        p3: "Filen skal inneholde enkeltrader. Det første tegnet skal være E",
        p4: "Hver rad i filen inneholder en systemrad.",
        p5: "Spillene er separert av kommategn",
    },
    example:{
        title: "Eksempel",
        row1: "123",
        row2: "E,1,2,X,1,2,1,2,X,1,1,1,1,1",
        row3: "E,1,2,X,1,2,1,2,X,1,1,1,1,1",
    },
    info: "Her kan du laste opp en fil med rader du lagt for systemtipping."
}