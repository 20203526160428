import { TeamWrapper } from './styles';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Checkbox from '@mui/material/Checkbox';
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../common/CurrencyFormatter";

const AgentSyndicateDiv = ({
  agent,
  syndicate,
  round,
  settled
}) => {

  const { t } = useTranslation();
  const { targetNShares, stakePerShare } = syndicate || {};
  const { amount, currency } = stakePerShare || {};
  const total = targetNShares * amount;
  const { openTo } = round || {};
  const openToDate = new Date(openTo);

  const syndicateStatusChanged = (syndicate) =>{
    (syndicate.status === "SUBMITTED") 
    ? syndicate.pause()
    : syndicate.activate();

  };

  let locale = t('locale');

  return (
    <TeamWrapper>
      <div className="top-div">
        <span className="bold-span">{(round && round.name)}</span>
        <span className="bold-span"><Moment format={"yyyy-MM-DD"} locale={locale}>{openToDate}</Moment></span>

        <Link className="bold-span link-div" to={'/agents/' + agent.id + '/syndicates/' + syndicate.id}>{t('to_syndicate')}<ArrowForwardIosIcon className="icon" /></Link>
      </div>

      <div className="bottom-div">
        <div className="column">
          <span className="bold-span">{syndicate.name}</span>
          <span className="bold-span">{syndicate.status}</span>
          <span className="total-span">{t('total_cost')} {formatStringCurrency(total, currency, t)}</span>
        </div>
        <span className="numb-members">{t('shares')}: ({syndicate.nShares}/{syndicate.targetNShares})</span>
        <div className="column-right">
          {((syndicate.status === "SUBMITTED" || syndicate.status === "PAUSED") && settled == false)  && (
            <span className="bold-span">
              <span className="bold-span">{t('round_status_open')}</span>
              <Checkbox title='Active' name="Active" checked={syndicate.status === "SUBMITTED"} onChange={() => syndicateStatusChanged(syndicate)}/>
            </span>
          )}        
          <span className="bold-span">{t('share_price')}: {formatStringCurrency(amount, currency, t)}</span>
        </div>
      </div>

    </TeamWrapper>
  );
}

export default AgentSyndicateDiv;