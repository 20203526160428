import { usePlayer } from "../player/playerContext";
import fetchApi from "../backend/fetchApi";

const useAgents = (setAgents, setLoading, loading) => {
  const [player] = usePlayer();
  const agentsUrl = player && player._links && player._links.agents && player._links.agents.href;

  if (agentsUrl === undefined || loading) {
    return undefined;
  }
  
  setLoading(true);

  return fetchApi(agentsUrl).then(response => response.json()).then(r => {
    setAgents(r._embedded.agents);
    return r._embedded.agents;
  }).catch(ex => {
    console.log(ex);
  })
}

export default useAgents;