import styled from 'styled-components';

export const GreenDiv = styled.div`
  background-color: var(--landing_background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .square-grid {
    display: flex;
    margin: 10px 5px 0px 10px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .twitter-box-left {
    padding: 10px 150px 10px 0px;
    width: 250px;
    display: var(--display-twitter-landing);
  }

  .twitter-box-right {
    padding: 10px 0px 10px 150px;
    width: 250px;
    display: var(--display-twitter-landing);
  }

  .twitter-box-mobile {
    padding: 10px 0px 10px 0px;
    display: var(--display-twitter-landing);
  }

  .round-box {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    background: var(--background_4);
    width: 250px;
    margin: 10px 10px 10px 10px;
    border-radius: var(--radius);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  } 

  .list-item {    
    position: relative; 
  }

  .round-box H3 {
    font-size: 20px;
    margin: 10px 10px 5px 10px;
  }

  .pro-pick-box H3 {
    font-size: 20px;
    margin: 10px 10px 5px 10px;    
  }

  .icon-holder {
    margin: 5px 0px 5px 0px;
  }

  .color-plate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }

  .pro-pick-box {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    background: var(--background_4);
    width: 150px;
    margin: 10px 10px 10px 10px;
    border-radius: var(--radius);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  }


  .landing-header {
    margin: 40px 10px 0px 10px;
  }

  .round-box-white {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #060707;
    margin: 0px 0px 10px 0px;
  }

  .pro-pick-box-white {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #060707;
    margin: 0px 0px 10px 0px;
  }

  .starts {
    display: flex;
    flex-direction: row;
  }


  .white-background {
    background-color: var(--background_2);
    width: 100%;
  }

  .image-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .round-box-details {
    width: 250px;
    display: flex;
    flex-direction: column;
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 0px 10px;
    justify-content: space-evenly;
  }

  .round-box-details h3 {
    margin: 0px 0px 0px 0px;
  }

  .detail-title {
    font-size: 12px;
    color: var(--font-title-color);
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
  }

  .round-box h2 {
    margin: 10px 0px 10px 0px;
  }

  .pro-pick-box h2 {
    margin: 10px 0px 10px 0px;
  }

  .round-box-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .propick-box-detail {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 10px 0px;
  }

  @media screen and (max-width: 426px) {    
    .logo-image {
      margin: 10px 0px 10px 0px;
    }
  }

  .logo-image {
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 20px 0px;
    display: var(--display-logo-landing);
    background-color: var(--background_1);
    width: 100%;    
  }
`;