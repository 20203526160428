import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 15px;

  .green-div {
    background: var(--list-boxes-primary);
    margin: 13px 0;
    padding: 16px 26px 20px 19px;
    font-size: 15px;
    width: 540px;
    background: var(--list-boxes-primary);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 42px;
  }

  .title-margin {
    margin-bottom: 10px;
  }

  .button-margin {
    margin: 33px 0;
  }
`;

