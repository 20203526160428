import styled from 'styled-components';

export const CouponInfoWrapper = styled.div`
  .orange-div {
    background: var(--background_3);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 24px 24px;
    font-size: 11px;
    color: var(--text-black-dark-background);
    font-weight: bold;
  }

  .flex-div {
    width: calc(100% - 33px);
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    padding: 8px 33px 8px 0;
    border-bottom: 1px solid var(--text-black-dark-background);
    flex-direction: var(--flex-direction); 
  }

  .b-top {
    border-top: 1px solid #060707;
  }

  .margin-top {
    margin: 9px 0 14px 0;
  }

  .button-div {
    display: flex;
    margin-top: 20px;
    display:flex;
    flex-direction: var(--flex-direction);
  }

  .button-div button {
    padding: 12px 32px;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
    margin: 0 12px;
    border: 1px solid var(--text-black-dark-background);
    border-radius: 4px;
    font-size: 11px;
    color: var(--text-black-dark-background);
  }
`;