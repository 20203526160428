export const TRANSLATIONS_PL = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    my_bets: "Moje typy",
    my_coupons: "Moje kupony",
    rounds: "Rundy",
    bet_with_syndicates: "Syndykaty",
    syndicates: "Syndykaty",
    turnover: "Aktualna pula",
    single_rows: "Pojedyncze rzędy",
    pro_pick: "Pro Pick",
    often: "Wygrywaj często",
    a_lot: "Wygyrwaj dużo",
    system: "System",
    row_distribution: "Rozkład wierszy",
    overview: "Przegląd",
    full_cover: "Full cover",
    half_cover: "Half cover",
    row_price: "Cena rzędu",
    pay: "Typuj",
    unsettled_coupons: "Nierozliczone kupony",
    settled_coupons: "Rozliczone kupony",
    stake: "Stawka",
    coupon_share_status_cancelled: "Odwołany",
    your_winnings_right_now: "Twoja obecna wygrana",
    correct_rows_now: "Właściwe obecne wybory",
    starts: "Rozpoczyna się",
    correct_rows: "Poprawne rzędy",
    prize: "Nagroda",
    your_rows: "Twoje rzędy",
    profit: "Zysk",
    st: "",
    total: "Wynik",
    rows: "Rzędy",
    row: "Rząd",
    price_per_row: "Cena za rząd",
    total_cost: "Całkowity koszt",
    paid: "Zapłacone",
    settled: "Rozliczone",
    channel_internet: "Kanał: Internet",
    print: "Drukuj",
    share: "Udział",
    clear_bets: "Wyczyść typy",
    round_history: "Historia rund",
    no_profit: "Bez zysku",
    no_coupons: "Brak kuponów",
    started: "Rozpoczęto o",
    round: "Runda",
    winners: "Zwycięscy",
    your_bet_is_paid: "Twój typ został postawiony!",
    do_you_want_to_place_bet: "Czy chcesz postawić zakład",
    highest_win: "Najwyższa wygrana",
    insufficient_funds: "Niewystarczające środki",
    cancel: "Anuluj",
    unable_to_complete_purchase: "Nie można dokończyć zakupu",
    error_code: "Kod błędu",
    event_goal: "Bramka",
    event_penalty: "Rzut karny",
    event_missed_penalty: "Niewykorzystany rzut karny",
    event_own_goal: "Własna bramka",
    event_var: "VAR",
    event_yellowcard: "Żółta kartka",
    event_yellowred: "Druga żółta kartka",
    event_substitution: "Podstawienie",
    event_redcard: "Czerwona kartka",
    event_pen_shootout_goal: "Bramka z rzutu karnego",
    event_pen_shootout_miss: "Niewykorzystany rzut karny",
    event_full_time: "Full time",
    event_half_time: "Half time",
    event_kickoff: "Rozpoczęcie",
    event_finished_after_extra: "Zakończono dogrywkę",
    out: "Out",
    table: "Tabela",
    game_info: "Informacje o grze",
    statistics: "Statystyki",
    team_name: "Drużyna",
    table_abbreviation_num_games: "G",
    table_abbreviation_wins: "W",
    table_abbreviation_draws: "D",
    table_abbreviation_losses: "L",
    table_abbreviation_points: "P",
    round_active_after_deadline: "Zamknięte",
    full_time: "Full time",
    round_status_finished: "Skończone",
    half_time: "Half time",
    current_rounds: "Aktualne rundy",
    settled_rounds: "Rozliczone rundy",
    no_rounds_available: "Brak dostępnych rund",
    row_statistics: "Statystyka rzędów",
    showing: "Seans",
    settings: "Ustawienia",
    match: "Mecz",
    result: "Wynik",
    bet_type: "Typ zakładu",
    mathematical_system: "System matematyczny",
    select: "Wybierz",
    is_draw: "Będzie zremisowany z",
    is_drawn: "Zremisowany",
    draw_disclaimer: "Jeżeli podlega remisowi:",
    num_correct_win: "Liczba poprawna/wygrana",
    more_info: "Więcej informacji",
    in_running: "W ciągu",
    go_back: "Wróć",
    to_syndicate: "Syndykować",
    shares: "Udziały",
    share_price: "Cena udziału",
    sold_shares: "Sprzedane udziały",
    agent_admin: "Administrator agenta",
    agents: "Agenci",
    to_agent: "Do agenta",
    create_new_syndicate: "Utwórz nowy syndykat",
    name: "Nazwa",
    target_number_of_shares: "Docelowa liczba akcji",
    max_number_of_shares: "Maksymalna liczba udziałów",
    submit_syndicate: "Prześlij syndykat",
    update_syndicate: "Zaktualizuj syndykat",
    show_sv_percent: "Pokaż % graczy",
    show_coupon_percent: "Pokaż % kuponu",
    number_of_rows: "Liczba rzędów",
    share_of_syndicate: "Udział syndykatu",
    syndicate_created: "Utworzono syndykat",
    syndicate_submitted: "Syndykat przesłany",
    syndicate_updated: "Syndykat zaktualizowany",
    finished: "Skończone",
    started_games: "Rozpoczęte",
    potential: "Potencjał",
    sort_by_finished: "Sortuj według zakończenia",
    game_summary: "Podsumowanie gry",
    date: "Data",
    league: "Liga",
    venue: "Miejsce",
    weather: "Pogoda",
    temperature: "Temperatura",
    wind: "Wiatr",
    moisture: "Wilgotność",
    general_info: "Ogólne informacje",
    locale: "pl-PL",
    show: "Pokaż",
    show_round: "Pokaż rundę",
    play_on: "Graj dalej",
    today: "Dzisiaj",
    latest_games: "Najnowsze gry",
    head_to_head: "Łeb w łeb",
    round_information_short: "Informacje",
    round_information: "Informacje o rundzie",
    prize_groups: "Grupy nagród",
    bet: "Zakład",
    pro_pick_bets: "Typy Pro Pick",
    help: "Pomoc",
    round_status_open: "Otwarty",
    round_status_voided: "Odwołany",
    round_status_paused: "Wstrzymano",
    round_paused_explanation: "Runda jest chwilowo wstrzymana na typowanie",
    jackpot: "Szacowany jackpot",
    after_extra_time: "Po dogrywce",
    show_rows: "Pokaż moje rzędy",
    id: "ID",
    bet_title: "Zakład",
    follow_title: "Obserwuj",
    win_title: "Wygrana",
    bet_text: "Wybierz jeden lub więcej wyników w każdym meczu piłkarskim rundy",
    follow_text: "Obserwuj swoje wygrane na żywo na stronie",
    win_text: "Jeśli wybierzesz wystarczającą liczbę poprawnych wyników, zostaniesz jednym ze zwycięzców puli",
    share_of_prizepool: "Udział w puli nagród",
    register: "Rejestracja ",
    fee_per_row: "Opłata za rząd",
    bet_status_open: "Otwarty",
    bet_status_won: "Wygrany",
    bet_status_lost: "Przegrany",
    payout: "Wypłata",
    show_details: "Pokaż szczegóły",
    bet_now: "Typuj teraz",
    place_more: "Jeśli chcesz zwiększyć swoje szanse na wygraną, możesz wybrać więcej niż jeden wynik w każdej grze",
    active_syndicates: "Aktywne syndykaty",
    settled_syndicates: "Rozliczone syndykaty",
    win_multiplier: "Mnożnik wygranej",
    win_multiplier_information: "Twoja stawka i potencjalna wygrana zostaną pomnożone przez wybrany mnożnik wygranej",
    status: "Status",
    join_title: "Dołącz do grupy",
    join_text: "Weź udział w większym systemie razem z innymi graczami i zwiększ swoje szanse na wygranie jackpota",
    valid_title: "Zawsze aktualne",
    valid_text: "Twój zakład jest zawsze ważny, nawet jeśli syndykat nie jest wyprzedany.",
    win_together_title: "Wygrajcie razem",
    win_together_text: "Wygrane z syndykatu są dzielone pomiędzy udziały",
    balance: "Balance",
    fee:"Fee"
}
