import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { IkonIcon, Button } from '../../reusable-components';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Progress from '../../common/progress';

import { formatStringCurrency } from "../../common/CurrencyFormatter";
import { operatorAdviceScrollToTop } from '../../App';
import useDetailRound from '../../rounds/useDetailRound';

const getErrorMessage = (errorCode, phrases) => {
  if (errorCode === 102) {
    return phrases.insufficientFunds;
  }

  return '';
}

const RenderSwitch = ({
  submitting,
  shouldRenderConfirm,
  toggle,
  amount,
  currency,
  phrases,
  handleClick,
  response, 
  t
}) => {

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])

  if (response !== undefined && !response.success) {
    const errorMessage = getErrorMessage(response.errorCode, phrases);
    return (
      <div>
        <p style={{ marginTop: '30px', textAlign: 'center' }}>
          {phrases.unableToCompletePurchase}, {phrases.errorCode}: {response.errorCode}
        </p>
        <p style={{ textAlign: 'center' }}> {errorMessage}</p>
        <Button width={350} onClick={() => toggle()}>Ok</Button>
      </div>
    );
  }

  if (submitting) {
    return <Progress isSmallScreen={false} />
  }

  if (shouldRenderConfirm) {
    return (
      <>
        <span>{phrases.paymentDone}</span>
        <Button width={350} onClick={() => toggle()}>Ok</Button>
      </>
    );
  }

  return (
    <>
      <span>{phrases.confirmPurchase} {formatStringCurrency(amount, currency, t)}?</span>
      <Button type="submit" width={350} onClick={handleClick}>{phrases.pay}</Button>
    </>
  );
}

const ConfirmPaymentApi = ({
  toggle,
  submitting,
  response,
  uploadFile,
  roundId,
  count
}) => {
  const [shouldRenderConfirm, setShouldRenderConfirm] = useState(false);
  const { t } = useTranslation();
  const { round } = useDetailRound(roundId);

  const phrases = {
    confirmPurchase: t('do_you_want_to_place_bet'),
    pay: t('pay'),
    paymentDone: t('your_bet_is_paid'),
    insufficientFunds: t('insufficient_funds'),
    unableToCompletePurchase: t('unable_to_complete_purchase'),
    errorCode: t('error_code'),
  };

  const handleClose = () => {
    toggle();
  }

  const handleClick = () => {
    uploadFile();
    setShouldRenderConfirm(true);
    operatorAdviceScrollToTop(500);
  }

  let turnoverCurrency = 'NA';
  let amount = 0;

  const couponTotal = (round, count) => count * round.operatorStakePerRow.amount;

  if (round !== undefined) {
    turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
    amount = couponTotal(round, count);
  }

  let currency = turnoverCurrency;

  return (
    <Wrapper>
      <div className="onclick-div" onClick={() => { }} />
      <div className="confirm-window">

        <div className="flex-end">
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>

        {IkonIcon(32, 30)}
        {RenderSwitch({
          submitting,
          shouldRenderConfirm,
          toggle,
          amount,
          currency,
          phrases,
          handleClick,
          response,
          t
        })}
      </div>
    </Wrapper>
  );
}

export default ConfirmPaymentApi;