export const formatStringCurrency = (amount, currencyCode, t, whole) => {

    if (!amount) {
        amount = 0;
    }

    let locale = 'sv-SV'

    let decimals = 2;

    if (whole) {
        decimals = 0;
    }

    if (t !== undefined) {
        locale = t('locale');
    }

    if (!currencyCode) {
        currencyCode = 'NA';
    }

    switch (currencyCode) {
        case 'SEK':
        case 'NOK':
        case 'MAD':
            amount = floorOfNumberFormatted(amount, 1);
            break;
        default:
            amount = floorOfNumberFormatted(amount, 10);

    }

    if (currencyCode === undefined || currencyCode === '' || currencyCode === 'NA') {
        return amount + ' ?';

    }

    const nf = PoolXNumberFormat(locale, { style: 'currency', currency: currencyCode, minimumFractionDigits: decimals, maximumFractionDigits: decimals });

    let formatted = nf.format(amount);

    return formatted;
}

// precision is the minimum unit for the decimal part of the output number
// e.g. precision = 10 means that the floor calc will preserve one decimal figure
// meaning that both 1.71 and 1.79 become 1.70
// precision = 100 would mean that 1.71 and 1.79 stays 1.71 and 1.79 respectively
function floorOfNumberFormatted(number, precision) {
    number = Number(number) * precision
    number = Math.floor(number) / precision
    return number;
}

export function PoolXNumberFormat(locale, options) {

    if (options.currency === "USDT") {
        let formatNonCurrency = Intl.NumberFormat(locale, { minimumFractionDigits: options.minimumFractionDigits, maximumFractionDigits: options.maximumFractionDigits });
        return {
            format: (amount) => {
                return "USDT " + formatNonCurrency.format(amount);
            }
        }
    }

    if (options.currency === "MAD") {

        let arabic = locale === 'ar-MA';

        locale = "en-EN";
        let formatNonCurrency = Intl.NumberFormat(locale, { minimumFractionDigits: options.minimumFractionDigits, maximumFractionDigits: options.maximumFractionDigits });

        if (arabic) {
            return {
                format: (amount) => {
                    return formatNonCurrency.format(amount).replaceAll(',', ' ') + " د.م";
                }
            }
        }

        return {
            format: (amount) => {
                return formatNonCurrency.format(amount).replaceAll(',', ' ') + " DH";
            }
        }
    }

    let myFormat = Intl.NumberFormat(locale, options);

    return {
        format: (amount) => {
            return myFormat.format(amount)
        }
    }
}