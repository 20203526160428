export const TRANSLATIONS_FA = {
    max: "طلایی",
    mix: "نقره ای",
    flex: "برنزی",
    my_bets: "پیش بینی های من",
    my_coupons: "برگه های من",
    rounds: "راند ها",    
    bet_with_syndicates: "پیش بینی گروهی",
    syndicates: "گروه ها",
    turnover: "جایزه فعلی",
    single_rows: "ردیف های تکی",
    pro_pick: "انتخاب هوشمند",
    often: "احتمال برد بیشتر",
    a_lot: "جایزه بیشتر",
    system: "سیستم",
    row_distribution: "درصد انتخاب",
    overview: "بررسی",
    full_cover: "انتخاب همه",
    half_cover: "دو انتخاب",
    row_price: "قیمت هر ردیف",
    pay: "ثبت پیش بینی",
    unsettled_coupons: "برگه های تسویه نشده",
    settled_coupons: "برگه های تسویه شده",
    stake: "مبلغ",
    coupon_share_status_cancelled: "لغو شده",
    your_winnings_right_now: "برد فعلی",
    correct_rows_now: "پیش بینی های صحیح فعلی",
    starts: "شروع",
    correct_rows: "ردیفهای درست",
    prize: "جایزه",
    your_rows: "ردیف های شما",
    profit: "برد",
    st: "",
    total: "نتیجه",
    rows: "ردیف ها",
    row: "ردیف",
    price_per_row: "قیمت هر ردیف",
    total_cost: "مبلغ کل",
    paid: "پرداخت شده",
    settled: "تسویه شده",
    channel_internet: "خرید برگه از اینترنت",
    print: "چاپ",
    share: "اشتراک گذاری",    
    clear_bets: "پاک کردن انتخاب ها",
    reduce: "کاهش مبلغ",
    no_reduced_systems_available: "امکان کاهش مبلغ وجود ندارد",
    round_history: "نتیجه های قبلی",
    no_profit: "بدون برد",
    no_coupons: "بدون برگه",
    started: "شروع شده از",
    round: "راند",
    winners: "برندگان",
    your_bet_is_paid: "پیش بینی شما ثبت شد",
    do_you_want_to_place_bet: "تایید ثبت پیش بینی با مبلغ",
    highest_win: "بیشترین برد",
    insufficient_funds: "موجودی کافی نیست",
    cancel: "لغو",
    unable_to_complete_purchase: "خرید ناموفق",
    error_code: "کد خطا",
    event_goal: "گل",
    event_penalty: "پنالتی",
    event_missed_penalty: "پنالتی گل نشده",
    event_own_goal: "گل به خودی",
    event_var: "کمک داور ویدیویی",
    event_yellowcard: "کارت زرد",
    event_yellowred: "کارت زرد دوم",
    event_substitution: "تعویض ها",
    event_redcard: "کارت قرمز",
    event_pen_shootout_goal: "پنالتی گل شده",
    event_pen_shootout_miss: "پنالتی گل نشده",
    event_full_time: "وقت قانونی",
    event_half_time: "نیمه اول",
    event_kickoff: "شروع بازی",
    event_finished_after_extra: "پایان وقت اضافه",
    out: "اوت",
    table: "جدول رده بندی",
    game_info: "اطلاعات بازی",
    statistics: "آمارها",
    team_name: "تیم",
    table_abbreviation_num_games: "بازیها",
    table_abbreviation_wins: "برد",
    table_abbreviation_draws: "تساوی",
    table_abbreviation_losses: "باخت",
    table_abbreviation_points: "امتیاز",
    round_active_after_deadline: "بسته شده",
    full_time: "وقت قانونی",
    round_status_finished: "پایان یافته",
    half_time: "نیمه اول",
    current_rounds: "راندهای فعلی",
    settled_rounds: "راند های تسویه شده",
    no_rounds_available: "راندی وجود ندارد",
    row_statistics: "اطلاعات ردیف",
    showing: "نمایش دادن",
    settings: "تنظیمات",
    match: "مسابقه",
    result: "نتیجه",
    bet_type: "نوع پیش بینی",
    mathematical_system: "سیستم معمولی",
    reduced_system: "سیستم کاهشی",
    no_system: "بدون سیستم",
    select: "انتخاب",
    is_draw: "احتمالات قرعه کشی",
    is_drawn: "قرعه کشی شده",
    draw_disclaimer: "اگر قرعه کشی شود",
    num_correct_win: "تعداد انتخاب های صحیح",
    more_info: "اطلاعات بیشتر",
    in_running: "در حال انجام",
    go_back: "برگشت",
    to_syndicate: "به",
    shares: "سهم",
    share_price: "مبلغ سهم",
    sold_shares: "سهم فروخته شده",
    agent_admin: "سرگروه",
    agents: "عضو",
    to_agent: "به",
    create_new_syndicate: "ساخت گروه جدید",
    name: "نام",
    target_number_of_shares: "تعداد سهم های مورد نیاز",
    max_number_of_shares: "حداکثر سهم ها",
    submit_syndicate: "ثبت گروه",
    update_syndicate: "به روزرسانی گروه",
    show_sv_percent: "نمایش درصد سهم بازیکنان",
    show_coupon_percent: "نمایش درصد برگه",
    number_of_rows: "تعداد ردیف ها",
    share_of_syndicate: "سهم گروه",
    syndicate_created: "گروه ساخته شد",
    syndicate_submitted: "گروه ثبت شد",
    syndicate_updated: "گروه به روزرسانی شد",
    finished: "پایان یافته",
    started_games: "شروع شده",
    potential: "احتمال",
    sort_by_finished: "مرتب سازی بر اساس پایان یافته",
    game_summary: "خلاصه بازی",
    date: "تاریخ",
    league: "لیگ",
    venue: "محل بازی",
    latest_tweets: "جدیدترین توییت ها از",
    weather: "آب و هوا",
    temperature: "دما",
    wind: "باد",
    moisture: "رطوبت",
    general_info: "اطلاعات کلی",
    locale: "fa-IR",
    show: "نمایش",
    show_round: "نمایش راند",
    play_on: "بازی شده در",
    today: "امروز",
    latest_games: "آخرین بازیها",
    head_to_head: "رو در رو",
    round_information_short: "اطلاعات",
    round_information: "اطلاعات راند",
    prize_groups: "گروه های جایزه",
    bet: "پیش بینی",
    pro_pick_bets: "پیش بینی های هوشمند",
    help: "راهنما",
    round_status_open: "قابل پیش بینی",
    round_status_voided: "لغو شده",
    round_status_paused: "متوقف شده",
    round_paused_explanation: "موقتا امکان ثبت پیش بینی وجود ندارد",
    jackpot: "جک پات احتمالی",
    after_extra_time: "پس از وقت اضافه",
    system_guarantee: "ضمانت شده",
    reduced_disclaimer: "تعداد ردیف ها از سیستم معمولی کمتر است. احتمال برد شما کمتر است اما مبلغ شرط نیز کمتر خواهد شد",
    show_rows: "نمایش ردیف ها",
    api_betting: "بارگزاری فایل",
    select_file: "انتخاب فایل",
    file: "فایل",
    id: "شناسه",
    upload: "بارگزاری",
    bet_title: "پیش بینی",
    follow_title: "دنبال کردن",
    win_title: "برد",
    bet_text: "یک یا چند نتیجه برای هر بازی را پیش بینی کنید",
    follow_text: "نتیجه پیش بینی های خود را به صورت زنده در سایت پیگیری کنید",
    win_text: "اگر به تعداد کافی پیش بینی های درست داشته باشید در جایزه شریک خواهید شد",
    share_of_prizepool: "سهم از جایزه",
    register: "ثبت نام",
    fee_per_row: "هزینه هر ردیف",
    bet_status_open: "تسویه نشده",
    bet_status_won: "برد",
    bet_status_lost: "باخت",
    payout: "پرداختی",
    show_details: "نمایش جزئیات",
    bet_now: "ثبت پیش بینی",
    place_more: "اگر می خواهید احتمال برد خود را بیشتر کنید بیشتر از یک نتیجه برای هر بازی را پیش بینی کنید",
    active_syndicates: "گروه های فعال",
    settled_syndicates: "گروه های تسویه شده",
    win_multiplier: "ضریب برد",
    win_multiplier_information: "مبلغ پیش بینی و برد به نسبت ضریب انتخابی شما بیشتر خواهد شد",
    status: "وضعیت",
    balance: "موجودی",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee"
}