export const RULES_AR = {    
    faq: {
        title: "الأسئلة المتداولة",
        q1: {
            question: "ما هي الرهانات الجماعية؟",
            answer: "الرهانات الجماعية هي عندما يتم تجميع رهانات المراهن في مجموعة. بمجرد خصم العمولة، سيتم تقسيم جميع الأموال بين المراهنين الفائزين. سوف تفوز إذا اخترت العدد المطلوب من النتائج الصحيحة للمباريات في الجولة"
        },
        q2: {
            question: "ما هي الجولة؟",
            answer: "الجولة عبارة عن مجموعة من مباريات كرة القدم حيث يجب عليك اختيار النتائج. غالبًا ما تبدأ الألعاب في الجولة نفس اليوم. يمكن أن تحتوي الجولة على 8 أو 12 أو 13 مباراة"
        },
        q3: {
            question: "كم عدد مجموعات الجوائز الموجودة؟",
            answer: "هناك 3 مجموعات جوائز في جولات مكونة من 12 أو 13 مباراة. هناك مجموعة جوائز واحدة في الجولات المكونة من 8 مباريات"
        },
        q4: {
            question: "كيف أضع الرهان؟",
            answer: "اختر النتيجة في كل مباراة من مباريات الجولة. إذا كنت ترغب في ذلك يمكنك اختيار أكثر من نتيجة واحدة في كل لعبة. سيتم بعد ذلك زيادة الرهان وفقًا لذلك"
        },
        q5: {
            question: "كيف أفوز؟",
            answer: "إذا كان لديك العديد من الاختيارات الصحيحة كما هو مطلوب في خطة الجائزة فسوف تفوز. يمكن أن يكون هناك مجموعة جوائز واحدة أو أكثر في الجولة.  يتقاسم جميع الفائزين مجموع الجوائز في هذه المجموعة"
        },
        q6: {
            question: "ما هو برو بيك؟",
            answer: "Pro Pick هي طريقة آلية لوضع رهانك. يقوم الروبوت باختيار النتائج في اللعبة لك بالتكلفة المحددة. يمكنك السماح للروبوت باختيار الرهانات الأكثر احتمالا أو الرهانات الأقل احتمالا مع فرصة الفوز بالمزيد"
        }
    },
    terms: {
        title: "Rules",
        p1: {
            title:"Placing bets",
            p11:"The operator acts as an agent for PoolX and all bets (“Bets”) are placed directly into PoolXs’ pools via the operators site (“Site”). In circumstances where Bets do not reach the pool, relevant operator customers (“Players”) will have their stakes refunded and neither the operator nor PoolX will be liable for any loss of winnings that would have been due had the Bet reached the pool. The operator holds Players’ funds and is responsible for paying out any winnings and any queries you have in relation to your betting activity into PoolXs’ pools should be addressed to the operator. To the extent that it is unable to resolve your query, the operator will contact PoolX.",
            p12:"It is your responsibility to check that the Bet instructions you submit are correct before confirming the Bet. PoolX reserves the right to refuse placement of any Bet for any reason.",
            p13:"Where there is any disagreement as to the details or status of the Bet, PoolX records will be the primary source of evidence for the details/status of the bet.",
            p14:"PoolX may, in its sole and absolute discretion, decide to suspend betting on a pool at any time. PoolX also reserves the right to void a pool in its entirety or any Bet(s): to correct any obvious errors; to maintain integrity and fairness in the PoolX pools; if there has been a change to the format or events within a pool; or if it believes any Bet has been placed in contravention of these Terms of Play or the rules of a sports governing body or other relevant professional body.",
            p15:"Players bet on the outcome of multiple related events which are pre-selected by PoolX (Games).",
            p16:"The Games shall be selected by PoolX acting at its sole discretion.",
            p17:"Players may choose to place one or more Bets, each consisting of a minimum of at least one selection in each Game.  Each game consists of three selections. Home win (“1”), Draw (“X”) or Away win (“2”). Each Bet is identified with a unique reference number when the Bet is accepted.",
            p18:"For any given Game a Player may make one or more selections of alternative results. For example, if you choose “Home win” and “Draw” in Game 1 and Home win in all other Games – this would constitute two Rows. Each Row has a full unit cost, for example $0.1 (or equivalent currency value) and the PoolX bet placement engine will add up the amount of Rows multiplied by the stake per Row to show the total amount staked on your Bet.",
            p19:"All Bets are placed for the correct result in the ordinary passage of play according to the match officials including stoppage time, but excluding extra time, any golden point period, penalties or similar (if they occur).",
            p110:"Subject to the other provisions in this clause 1 below, all Bets will be settled based on the official result of the relevant event regardless of any subsequent disqualification or amendment to the result.",
            p111:"In the event of any uncertainty about any official result, PoolX reserves the right to suspend settlement of any pool until the uncertainty can be resolved to its reasonable satisfaction.",
            p112:"PoolX reserves the right to reverse the settlement of a pool if it has been settled in error (for example, a human or technical error). If PoolX resettles a pool, this may lead to amendments being made to Players’ Account balances to reflect changes in pool settlement.",
            p113:"Notwithstanding anything else in these Terms of Play, once a pool has been settled for 72 hours, its settlement will be considered full and final.",
            p114:"If a match is not completed - i.e. the full period of play (so, for example, 90 minutes in the case of soccer), according to the match officials, plus any stoppage time, within 4 hours of the latest scheduled start date for any game on the coupon, it will be subject to a draw to decide the outcome of the match.",
            p115:"The draw will be arranged with probabilities for each outcome in the game. These probabilities can be found under the game details on the PoolX site. Probabilities are defined as: HomeProbability, DrawProbability, AwayProbability and are published when the round is created. After this they are never changed.",
            p116a:"The random number used in the draw will be taken from the first game in order on the round not subject to a draw and not used in any other draw in the round. The last number of the minute for any of the following events will be used:",
            p116b:"Goal\nYellow Card\nRed Card",
            p116c:"The minute used will be the minute stated by the organizing body of the tournament the game is part of. If the time of the event is given as minutes and seconds, events between 0:00 and 0:59 will be considered to be minute 1 and so on. Events in first half added time will be considered minute 45. Events in second half added time will be considered minute 90. The number will be between 0 and 9. This number is divided by 10 to give a number between 0 and 1. This number is defined as Random.",
            p116d:"The outcome of the game in the draw is picked by",
            p116e:"If Random is less than HomeProbability\n\tOutcome of game is Home\nIf Random is less than HomeProbability + DrawProbability\n\tOutcome of game is Draw\nElse\n\tOutcome of game is Away",
            p117:"The draws for the round will be conducted in the same order the games appear in the round.(If both game 3 and 6 needs to be drawn, game 3 will be drawn first using the event from game 1. Game 6 will later be drawn with event from game 2)",
            p118:"If more than half of the number of games in a round needs to be drawn, the round will be cancelled and stakes refunded.",
            p119:"If the scheduled venue for a Leg is changed after a pool has been loaded by PoolX, the Leg will be drawn only if the new venue is a home ground of the original away team. For matches played at a neutral venue Bets will stand regardless of which team is listed as the home team",            
            p120:"PoolX accepts no responsibility for typing, transmission and/or evaluation errors. PoolX also accepts no liability for incorrectness or incompleteness or inaccuracy of the information provided via the Site, including (without limitation) any live scores and results.",
            p121:"If there is any inconsistency in these Terms of Play between a provision of general application and a provision relating to a specific sport or a specific bet type, the latter will prevail."
        },
        p2: {
            title:"Winnings",
            p21:"All Bets are pool bets where winnings are determined by reference to the money staked and the number of winning rows in the pool.",
            p22:"Each pool will have one or more prize groups. Each of these groups have specified how many correct picks a bet need to qualify as a winner in that group",
            p23:"The funds in a prize group are divided by the number of correct rows in that prize group. If there are no winners in a prize group the funds in that group are transferred to the next prize group in order of less and less required correct picks.",
            p24:"If the prize to each winner in a prize group is below  the minimum win, that group is cancelled and the funds are transferred to the prize group with most required correct picks.",
            p25:"The minimum win is defined as EUR 1. If you place bets in other currencies the min bet will vary due to exchange rate.",
            p26:"If funds are left after the actions in 2.3 and 2.4 have been applied they are transferred to the Pool Fund. The Pool fund will be used to increase the pool in coming rounds.",
            p27:"20% of the funds staked in the pool is deducted as a rake. The remaining 80% is divided between the prize groups as stated on the specific round on the PoolX site",
            amendment:"Date of last amendment 22 Febryary 2024"
            
        }
    }
}