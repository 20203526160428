import { Wrapper } from "./styles";
import { Grid, Typography } from "@mui/material";
import myVideo from "../../../../../videos/pick.mp4"
import { Button } from '../../../../../reusable-components';
import CloseIcon from '@mui/icons-material/Close';

function ShowResultOfBet({ t, toggle, amount }) {

    const handleClose = () => {
        toggle(false);
    }

    return (
        <Wrapper>
            <div className="onclick-div" onClick={() => { }} />
            <div className="confirm-window">

                <div className="flex-end">
                    <CloseIcon className="close-icon" onClick={handleClose} />
                </div>

                <Grid container direction='column' alignItems='center' sx={{ padding: '5px', width: '100%' }}>
                    <span>{t('your_bet_is_paid')}</span>

                    {window.theme === 'gold' && amount <= 2 &&
                        <Grid container direction='column' alignItems='center'>
                            <Typography sx={{ margin: '10px 30px 20px 30px', fontSize: '14px', fontFamily: 'var(--font-body)', textAlign: 'justify' }}>{t('place_more')}</Typography>
                            <Grid sx={{ marginBottom: '20px' }}>
                                <video autoPlay playsInline muted width='300px'>
                                    <source src={myVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </Grid>
                        </Grid>
                    }
                    <Button width="200px" onClick={() => toggle(false)}>Ok</Button>
                </Grid>

            </div>
        </Wrapper>
    );

}

export default ShowResultOfBet;
