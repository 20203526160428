import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState, useRef } from 'react';

function MobileNavBarOrange({ theme, realMode, agentSyndicateBetting, reportSelection, whichToUnderline }: {
    theme: string, realMode: boolean, agentSyndicateBetting: boolean, whichToUnderline: string
    reportSelection: (type: string, id: string) => void
}) {

    const useVisibility = (ref: React.RefObject<HTMLElement>): boolean => {
        const [isVisible, setIsVisible] = useState<boolean>(true);

        useEffect(() => {
            if (!ref.current) return;

            let oldRef = ref.current

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        setIsVisible(entry.isIntersecting);
                    });
                },
                { threshold: 1.0 } // Check if element is fully visible
            );

            observer.observe(oldRef);

            return () => {
                if (oldRef) observer.unobserve(oldRef);
            };
        }, [ref]);

        return isVisible;
    };

    const { t } = useTranslation();

    const isMobileScreen = useMediaQuery('(max-width: 640px)');

    let gridRef = useRef<HTMLDivElement>(null);
    let firstRef = useRef<HTMLDivElement>(null);
    let isGridVisible = useVisibility(gridRef);
    let isFirstVisible = useVisibility(firstRef);

    let textStyle: SxProps<Theme> = {
        fontSize: "12px",
        margin: "5px",
        color: "var(--title-bar-color)",
        fontFamily: 'var(--font-title-3)',
    }

    let itemContainer: SxProps<Theme> = {
        flexGrow: isMobileScreen ? 0 : 1,
        '& > a': { textDecoration: "none" }
    }

    let gridStyleContent: SxProps<Theme> = {
        flexDirection: "var(--flex-direction)",
        flexWrap: "nowrap",
        overflowX: "scroll",
        scrollbarWidth: "none",
        width: "100%",
        gap: "0px",
        justifyContent: "space-between",
        position: "relative",
        padding: "4px",
    }

    let gridStyle: SxProps<Theme> = {
        borderRadius: isGridVisible ? (isFirstVisible ? "24px 24px 24px 24px" : "0px 24px 24px 0px") : (isFirstVisible ? "24px 0px 0px 24px" : "0px 0px 0px 0px"),
        paddingTop: "10px",
        padding: "4px",
        color: "var(--title-bar-color)",
        '& > a': { textDecoration: "none" },
        background: "#DDD",
        flexDirection: "var(--flex-direction)",
        margin: "0px 10px 20px 10px",
        flexWrap: "nowrap",
        overflowX: "scroll",
        scrollbarWidth: "none",
        textWrap: "nowrap",
        maxWidth: "720px",
        width: "100%",
        gap: "0px",
        justifyContent: "space-between",
        position: "relative",
    };

    let itemStyle: SxProps<Theme> = {
        borderRadius: "24px",
        flexDirection: "var(--flex-direction)",
        padding: "8px 12px 8px 12px",
        height: "32px",
        minWidth: isMobileScreen ? "64px" : "72px",
        justifyContent: "center",
        flexGrow: 1,
        "&:hover": {
            backgroundColor: "#FFFFFF8A"
        },
        "&:active": {
            backgroundColor: "#FFFFFF"
        },
    };

    let itemStyleSelected: SxProps<Theme> = {
        borderRadius: "24px",
        flexDirection: "var(--flex-direction)",
        padding: "8px 12px 8px 12px",
        height: "32px",
        minWidth: isMobileScreen ? "64px" : "72px",
        background: "#FFF",
        boxShadow: "0px 1px 8px rgba(0,0,0,0.25)",
        justifyContent: "center"

    };

    return (
        <>
            <Grid container direction="column" alignItems="center" sx={{
                paddingRight: "20px"
            }}>
                <Grid sx={{ height: "20px" }}></Grid>
                <Grid sx={{
                    ...gridStyle, ...{
                        position: 'relative',  // Ensures that the pseudo-element is positioned relative to the Box
                        '&::after': {
                            content: '""', 
                            position: 'absolute',
                            width: isGridVisible ? "0px" : "24px",
                            height: '52px',
                            backgroundImage: 'linear-gradient(270deg, #F5F7FB 0%, rgba(237, 237, 237, 0) 100%)',
                            boxSizing: "border-box",
                            display: "block",
                            bottom: 0,
                            right: 0
                        },
                        '&::before': {
                            content: '""', 
                            position: 'absolute',
                            width: isFirstVisible ? "0px" : "24px",
                            height: '52px',
                            backgroundImage: 'linear-gradient(90deg, #F5F7FB 0%, rgba(237, 237, 237, 0) 100%)',
                            boxSizing: "border-box",
                            display: "block",    
                            bottom: 0,                        
                            left: 0
                        }
                    }
                }} container>
                    <Grid container sx={gridStyleContent}>
                        <Grid item ref={firstRef} sx={itemContainer}>
                            <Link to="/clear">
                                <Grid container sx={whichToUnderline === '0' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("home", "mobile_menu")}>
                                    <Typography sx={textStyle}>Start</Typography>
                                </Grid>
                            </Link>
                        </Grid>

                        <Grid item sx={itemContainer}>
                            <Link to="/roundhistory">
                                <Grid container sx={whichToUnderline === '4' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("rounds", "mobile_menu")}>
                                    <Typography sx={textStyle}>{t('rounds')}</Typography>
                                </Grid>
                            </Link>
                        </Grid>

                        {realMode &&
                            <Grid item sx={itemContainer}>
                                <Link to="/mycoupons">
                                    <Grid container sx={whichToUnderline === '3' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("coupons", "mobile_menu")}>
                                        <Typography sx={textStyle}>{t('my_bets')}</Typography>
                                    </Grid>
                                </Link>
                            </Grid>
                        }

                        {agentSyndicateBetting &&
                            <Grid item sx={itemContainer}>
                                <Link to="/playagent">
                                    <Grid container sx={whichToUnderline === '2' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("syndicates", "mobile_menu")}>
                                        <Typography sx={textStyle}>{t('bet_with_syndicates')}</Typography>
                                    </Grid>
                                </Link>
                            </Grid>
                        }

                        <Grid ref={gridRef} item sx={itemContainer}>
                            <Link to="/help">
                                <Grid container sx={whichToUnderline === '6' ? itemStyleSelected : itemStyle} alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("help", "mobile_menu")}>
                                    <Typography sx={textStyle}>{t('help')}</Typography>
                                </Grid>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );

}

export default MobileNavBarOrange;