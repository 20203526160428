import Moment from "react-moment";
import React from "react";

export const isFixtureAfterGameStart = (fixture) =>{
  return new Date(fixture.startingAt) < Date.now() || fixture.status === "PLAYING" || fixture.status === "FINISHED";
}

export const fixtureTime = (fixture,t, isRightToLeft) => {

    const { draw } = fixture;
    if (draw && (draw.status === 'PUBLISHED' || draw.status === 'FINISHED')) {
        return (
            <span>{t('')}</span>
        );
    }

    if(fixture.status==="FINISHED"){
      return(        
        <span>
          {t('full_time')}
        </span>
      );
    }
  
    let locale = t('locale');

    if(fixture.status==="PLAYING"){
      if(isRightToLeft){
        return(    
          <span>
            <Moment format={"HH:mm"} locale={locale}>{fixture.startingAt}</Moment> {t('started')}        
          </span>
        );
      } else {
        return(    
          <span>
            {t('started')} <Moment format={"HH:mm"} locale={locale}>{fixture.startingAt}</Moment>
          </span>
        );
      }
    }
  
    if(isRightToLeft){
    return(        
      <span>
        <Moment format={"HH:mm"} locale={locale}>{fixture.startingAt}</Moment> {t('starts')}
      </span>
    );
    } else {
      return(        
        <span>
          {t('starts')} <Moment format={"HH:mm"} locale={locale}>{fixture.startingAt}</Moment>
        </span>
      );
    }
  }