import { useState } from 'react';
import { AdvancedSettleWrapper, SignBoxWrapper } from './styles';
import NavBar from '../navbar';
import { H1, LargeArrowForward, MinusIcon, PlusIcon, ShadowDiv } from '../reusable-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import useDetailRound from '../rounds/useDetailRound';
import useCouponShare from '../couponShares/useCouponShare';
import Moment from "react-moment";
import { isFixtureAfterGameStart } from '../common/DateTimeHelper';
import { usePlayer } from "../player/playerContext";
import { getRoundName } from '../common/RoundHelper';
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import { PoolXNumberFormat } from '../common/CurrencyFormatter';
import { themeC } from '../theme/themehelper';

const correctRows = (rows, round, sortByFinished) => {

    let correctedRows = []

    function getCorrectSign(index, round) {
        if (!round.preliminaryCorrectRow) {
            return 'X';
        }
        return round.preliminaryCorrectRow[index];
    }

    rows.forEach(row => {

        let numCorrect = 0;
        let numCorrectCompleted = 0;
        let numCorrectStarted = 0;
        let numWrongCompleted = 0;

        for (let index = 0; index < row.signs.length; index++) {
            const sign = row.signs[index];
            const correctSign = getCorrectSign(index, round);
            const fixture = round.fixtures[index];
            const isAfterGameStart = isFixtureAfterGameStart(fixture);

            if (sign === correctSign) {
                numCorrect++;
                if (fixture.status === "FINISHED") {
                    numCorrectCompleted++;
                }
                if (isAfterGameStart) {
                    numCorrectStarted++;
                }
            }
            else {
                if (fixture.status === "FINISHED") {
                    numWrongCompleted++;
                }
            }

        }

        correctedRows.push({
            row, numCorrect: numCorrect, numCorrectCompleted: numCorrectCompleted,
            numCorrectStarted: numCorrectStarted, numPotential: round.fixtures.length - numWrongCompleted
        });
    });

    const sortFactor = sortByFinished ? 100000 : 0;

    correctedRows.sort((a, b) => {
        return sortFactor * (b.numCorrectCompleted - a.numCorrectCompleted) +
            (b.numCorrect - a.numCorrect);
    });

    return correctedRows;
}

const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

function AdvancedCouponView() {
    const { t } = useTranslation();
    const history = useHistory();
    const [showSettings, setShowSettings] = useState(false);
    const { couponShareId } = useParams();

    const couponShareResponse = useCouponShare(couponShareId);

    const detailRoundResponse = useDetailRound(!couponShareResponse[0] === undefined ? undefined : couponShareResponse[0].coupon.roundId);
    const round = detailRoundResponse.round;
    const couponShare = couponShareResponse[0];
    const [reduce, setReduce] = useState(!couponShare || couponShare.coupon.coupon.bets.length !== 1 ? 0 : Math.max(couponShare.coupon.coupon.bets[0].rows.length - 15, 0));
    const [moreInfo, setMoreInfo] = useState(false);
    const [sortByFinished, setSortByFinished] = useState(true);
    const [, config] = usePlayer();
    if (!config.advancedCoupons) {
        return <div />;
    }

    if (!round || !couponShare) {
        return (<div />);
    }

    const openToDate = new Date(round.openTo);

    const theme = window.theme;

    const syndicateShare = couponShare.stakeUnits / couponShare.totalStakeUnits;

    let locale = t('locale');
    let correctedRows = [];
    let groupedByCorrect = {};
    let groupedByCompleted = {};
    let groupedByStarted = {};
    let groupedByPotential = {};
    let totalRows = 0;

    let myRows = [];

    let isSystem = false;

    if (couponShare.coupon.coupon.bets.length > 0) {

        isSystem = couponShare.coupon.coupon.bets[0].system !== null;

        couponShare.coupon.coupon.bets.forEach(bet => {
            myRows = myRows.concat(bet.rows);
        });

        totalRows = myRows.length;
        correctedRows = correctRows(myRows, round, sortByFinished);
        groupedByCorrect = groupBy(correctedRows, 'numCorrect');
        groupedByCompleted = groupBy(correctedRows, 'numCorrectCompleted');
        groupedByStarted = groupBy(correctedRows, 'numCorrectStarted');
        groupedByPotential = groupBy(correctedRows, 'numPotential');
    }

    const finishedGames = round.fixtures.filter(f => f.status === "FINISHED");
    const startedGames = round.fixtures.filter(f => f.status === "FINISHED" || f.status === "PLAYING" || new Date(f.startingAt) < Date.now());

    const nf = PoolXNumberFormat(locale, { style: 'currency', currency: couponShare.stake.currency, minimumFractionDigits: 0, maximumFractionDigits: 0 });

    const MySwitch = withStyles({
        switchBase: {
            color: grey[300],
            '& $track': {
                color: "var(--text-white)",
                backgroundColor: "var(--background_2)"
            },
            '&$checked': {
                color: "var(--text-white)",
            },
            '&$checked + $track': {
                backgroundColor: "var(--background_2)",
            },
        },
        checked: {},
        track: {},
    })(Switch);

    return (
        <AdvancedSettleWrapper>
            <NavBar />
            <div className="top-div">
                <div className="icon" onClick={() => history.goBack()}>
                    {LargeArrowForward}
                </div>
                <H1 size={42}>{t('row_statistics')}</H1>
                <span />
            </div>

            {showSettings && (
                <ShadowDiv>
                    <div className="onclick-div" onClick={() => setShowSettings(false)} />
                    <div className="shadow-content start">
                        <div className="flex-end">
                            <CloseIcon className="cursor" onClick={() => setShowSettings(false)} />
                        </div>

                        <div>
                            <span>{t('show_sv_percent')}</span>
                            <Checkbox />
                        </div>
                        <div>
                            <span>{t('show_coupon_percent')}</span>
                            <Checkbox />
                        </div>
                    </div>
                </ShadowDiv>
            )}

            <div className="sort-div">
                <span className="span">{getRoundName(round, t)} <Moment format={"yyyy-MM-DD"} locale={locale}>{openToDate}</Moment></span>
                <div className="showing-div">
                    <span className="showing-click" onClick={() => {
                        if (reduce < totalRows - 20) {
                            setReduce(reduce + 5)
                        }

                        if (reduce > totalRows - 20) {
                            setReduce(totalRows - 15)
                        }
                    }
                    }>{MinusIcon}</span>
                    <span className="showing-margin">
                        {t('showing')} {totalRows - reduce}/{totalRows} {t('rows')}
                    </span>
                    <span className="showing-click" onClick={() => {

                        if (reduce > 15) {
                            setReduce(reduce - 5);
                        }

                        if (reduce < 15) {
                            setReduce(0);
                        }
                    }
                    }>

                        {PlusIcon}</span>
                </div>
                <div className="filter-div">
                    <FormGroup row>
                        <FormControlLabel label={t('sort_by_finished')} control={
                            <MySwitch className="filter-check" checked={sortByFinished} onChange={(ev) => setSortByFinished(ev.target.checked)} />
                        }
                        />
                    </FormGroup>
                </div>
                {/* <div className="settings-div" onClick={() => setShowSettings(true)}>
                    <span>{t('settings')}</span>
                    {ExpandMoreArrowIcon}
                 </div>*/}
            </div>

            <div className="grid-div">
                <div className="match-div">
                    <div className="text-div">
                        <span className="match-margin">#</span>
                        <span>{t('match')}</span>
                    </div>
                    {round.fixtures.map((fixture, i) => {
                        return (
                            <div className={`match-iteration-div ${((i % 2) > 0) ? 'green-bg' : ''}`} key={`teamName ${i}`}>
                                <span className="match-margin">{i + 1}</span>
                                <span>{fixture.homeTeam.name} - {fixture.awayTeam.name}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="results-div">
                    <div className="text-div">
                        <span className="result-margin">
                            {t('result')}
                        </span>
                    </div>
                    {round.fixtures.map((fixture, i) => {
                        let isSecond = ((i + 1) % 2) === 0;
                        const isAfterGameStart = isFixtureAfterGameStart(fixture);
                        const fixtureIsDrawn = fixture.draw !== null && fixture.draw.status === 'FINISHED';
                        const fixtureDrawIsPublished = fixture.draw !== null && fixture.draw.status === 'PUBLISHED';

                        return (
                            <div className={`match-iteration-div-result ${isSecond ? 'green-bg' : ''}`} key={`result ${i}`}>
                                {
                                    (isAfterGameStart && !fixtureIsDrawn && !fixtureDrawIsPublished ? <div className="black-div result-margin">{fixture.homeTeam.score} - {fixture.awayTeam.score}</div> :
                                        <div></div>)
                                }
                                <span className="sign">{round.preliminaryCorrectRow && (round.preliminaryCorrectRow[i]).replace('ONE', '1').replace('TWO', '2')}</span>
                            </div>
                        );
                    })}
                </div>

                {myRows.length > 0 && isSystem &&
                    (

                        <div className="systems-div">
                            <div className="text-div">
                                <span className="result-margin">
                                    {t('system')}
                                </span>
                            </div>
                            {couponShare.coupon.coupon.bets[0].legs.map((leg, i) => {
                                let isSecond = ((i + 1) % 2) === 0;

                                return (
                                    <div className={`match-iteration-div system-width ${isSecond ? 'green-bg' : ''}`} key={`sign ${i}`}>
                                        <SignBoxWrapper selected={leg.signs.includes('ONE')}>1</SignBoxWrapper>
                                        <SignBoxWrapper selected={leg.signs.includes('X')}>X</SignBoxWrapper>
                                        <SignBoxWrapper selected={leg.signs.includes('TWO')}>2</SignBoxWrapper>
                                    </div>
                                );
                            })}
                        </div>
                    )
                }

                {myRows.length > 0 &&
                    (
                        <div className="result-rows-div">
                            {correctedRows.slice(0, totalRows - reduce).map((row, i) => {
                                return (
                                    <div key={`${i} coupon`} className="rows-div">
                                        <div className="text-div">
                                            <span className="rows-margin">{i + 1}</span>
                                        </div>
                                        {row.row.signs.map((sign, j) => {
                                            let isSecond = ((j + 1) % 2) === 0;

                                            return (
                                                <div key={`${j} signbox row`} className={`match-iteration-div rows-width ${isSecond ? 'green-bg' : ''}`}>
                                                    <SignBoxWrapper selected={round.preliminaryCorrectRow && round.preliminaryCorrectRow[j] === sign}>{sign.replace('ONE', '1').replace('TWO', '2')}</SignBoxWrapper>
                                                </div>
                                            );
                                        })}
                                        <span className="result-span">{row.numCorrect}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )
                }


            </div>
            <div>
                <div className="more-info-flex">
                    <div className={!moreInfo ? "more-info-black" : "more-info-beige"} onClick={() => setMoreInfo(false)}>
                        <span>{t('num_correct_win')}</span>
                    </div>
                    <div className={moreInfo ? "more-info-black" : "more-info-beige"} onClick={() => setMoreInfo(true)}>
                        <span>{t('more_info')}</span>
                    </div>
                </div>
                {!moreInfo ?
                    (
                        <div className="more-info-table-top">
                            <span>{t('correct_rows')}</span>
                            <span>{t('winners')}</span>
                            <span>{t('prize')}</span>
                            <span>{t('your_rows')}</span>
                            <span>{t('profit')}</span>
                        </div>
                    ) :
                    (
                        <div className="more-info-table-top">
                            <span>{t('correct_rows')}</span>
                            <span>{t('finished')}</span>
                            <span>{t('started_games')}</span>
                            <span>{t('your_rows')}</span>
                            <span>{t('potential')}</span>
                        </div>
                    )
                }
                {
                    couponShare.coupon.preliminaryResult &&
                    (
                        Array.from({ length: round.fixtures.length + 1 }, (_, j) => round.fixtures.length - j).map((i) => {

                            let isSecond = ((i + 1) % 2) === 0;

                            const winIndex = round.fixtures.length - i;

                            const couponGroup = couponShare.coupon.preliminaryResult.groups.filter(g => g.nCorrect === i)
                            const roundGroup = round.operatorPrizePlan.payoutGroups.length > winIndex ? round.operatorPrizePlan.payoutGroups[winIndex] : undefined;

                            let numOwn = couponGroup.length === 1 ? couponGroup[0].rowsUnits : 0;

                            let numOwnComplete = 0;
                            let numOwnStart = 0;
                            let numPotential = 0;

                            if (groupedByCorrect[i]) {
                                numOwn = groupedByCorrect[i].length;
                            }
                            if (groupedByCompleted[i]) {
                                numOwnComplete = groupedByCompleted[i].length;
                            }
                            if (groupedByStarted[i]) {
                                numOwnStart = groupedByStarted[i].length;
                            }
                            if (groupedByPotential[i]) {
                                numPotential = groupedByPotential[i].length;
                            }

                            const showFinished = finishedGames.length + 1 > i;
                            const showStarted = startedGames.length + 1 > i;

                            const numAll = roundGroup ? roundGroup.rows : 0;
                            let winAll = roundGroup ? roundGroup.payoutPerRow.money.amount : 0;

                            if (theme === themeC.Betbeto && winAll > 1_000_000) {
                                winAll = 1_000_000
                            }

                            if (!moreInfo) {
                                return (<div key={`${i} payout row`} className={isSecond ? "more-info-table-row green-bg" : "more-info-table-row"}>
                                    <span>{i}</span>
                                    <span>{roundGroup && Math.round(numAll)} {roundGroup && t('st')}</span>
                                    <span>{roundGroup && nf.format(winAll)}</span>
                                    <span>{numOwn} {t('st')}</span>
                                    <span>{roundGroup && nf.format(numOwn * winAll * syndicateShare)}</span>
                                </div>);
                            }
                            return (<div key={`${i} payout row`} className={isSecond ? "more-info-table-row green-bg" : "more-info-table-row"}>
                                <span>{i}</span>
                                <span>{showFinished && numOwnComplete} {showFinished && t('st')}</span>
                                <span>{showStarted && numOwnStart} {showStarted && t('st')}</span>
                                <span>{numOwn} {t('st')}</span>
                                <span>{numPotential} {t('st')}</span>
                            </div>);
                        })
                    )
                }

            </div>
        </AdvancedSettleWrapper>
    );
}

export default AdvancedCouponView;