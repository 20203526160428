export const operatorC = {
    Betfa: 'BETFA',
    Elit: 'ELIT',
    EuroOperator: 'EURO_OPERATOR',
    Fsport: 'FSPORT',
    IrBet365: 'IRBET365',
    IrToto: 'IRTOTO',
    IrTotoTest: 'IRTOTO_TEST',
    Lilibet: 'LILIBET',
    LilibetNo: 'LILIBETNO',
    Manotobet: 'MANOTOBET',
    Marcbet: 'MARCBET',
    Medinabet: 'MEDINABET',
    Metrobahis: 'METROBAHIS',
    Mockop: 'MOCKOP',
    Pridebet: 'PRIDEBET_GH',
    SecondOperator: 'SECOND_OPERATOR',
    Sultanbet: 'SULTANBET_INTL',
    Takbet: 'TAKBET',
    Win90: 'WIN90'
}