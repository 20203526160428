import { useQuery, useQueryCache } from "react-query";
import fetchApi, {getApiUrl} from "../backend/fetchApi";

const useDetailedFixture = (fixtureId) => {
    const fixtureUrl = `${getApiUrl()}/fixtures/${fixtureId}`;
    const {status,error,data} = useQuery(['detailedFixture', fixtureUrl], () => {
        if(!fixtureId){
            return null;
        }
        return fetchApi(fixtureUrl).then(response => response.json()).then(r => r._embedded.fixture)
    }, {

    })

    const queryCache = useQueryCache();
    const reloadDetailedFixture = () => queryCache.invalidateQueries(['detailedFixture', fixtureUrl]);

    return {status, error, fixtureDetails: data, reloadDetailedFixture};
}

export default useDetailedFixture;