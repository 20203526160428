
import React, { useState } from "react";
import { H2 } from "../../reusable-components";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import AgentDiv from "../agentdiv";
import useAgents from "../useAgents";

const AgentList = () => {
  const { t } = useTranslation();
  let [agents, setAgents] = useState(undefined);  
  let [loading, setLoading] = useState(false);
  useAgents(setAgents, setLoading, loading);

  return <div>
    <Wrapper>
      <div className="green-div">
        <H2 className="title-margin">{t('agents')}</H2>
        {agents && agents.map((agent, i) => {          
          return (
            <AgentDiv key={`${i} agentdiv`} shallowAgent={agent} />
          )
        }
        )}
      </div>
    </Wrapper>
  </div>;

}

export default AgentList;