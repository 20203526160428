import {usePlayer} from "../player/playerContext";
import {QueryResult, useQuery, useQueryCache} from "react-query";
import fetchApi from "../backend/fetchApi";
import { Syndicate, SyndicatePurchase } from "../models/syndicate";

// This lists all available syndicates, should be replaced by more flexible search later
const useSyndicates = () => {
  const [player] = usePlayer()as any;

  const queryCache = useQueryCache();
  const syndicatesUrl = player && player._links.syndicates.href;
  const syndicates = useQuery<Syndicate[],unknown>(['syndicates', syndicatesUrl], () => {
    return fetchApi(syndicatesUrl).then(response => response.json()).then(r => r._embedded.syndicates)
  }, {
    enabled: !!player,
  })

  return [syndicates.data && syndicates.data.map(syndicate => ({
    ...syndicate,
    purchase: () => fetchApi(player._links.syndicateShares.href, {
      method: 'POST',
      body: JSON.stringify({
        syndicateId: syndicate.id,
        stake: syndicate.stakePerShare,
        operatorFee: syndicate.operatorFeePerShare,
        agentFee: syndicate.agentFeePerShare,
        totalAmount: syndicate.totalAmountPerShare,
      }),
    }).then(response => {
        if (response.ok) {
          
          setTimeout(() => {
            queryCache.invalidateQueries('couponShares')
            queryCache.invalidateQueries('syndicates')            
          }, 2000);
          return response;
        } else {
          return response;          
        }
        // Allow for eventual consistency before reload
      }),
  })), {
    ...syndicates,
    reloadSyndicates: () => queryCache.invalidateQueries('syndicates')
  }] as [SyndicatePurchase[] | undefined, QueryResult<SyndicatePurchase[]> & {
    reloadSyndicates: () => Promise<any>
  }]
}

export default useSyndicates;