import { find, reduce } from "lodash";
import { Bet, Coupon } from "../models/coupon";
import { DetailedRound, ReducedSystem } from "../models/round";
import { Leg } from "../rounds/types";
import { Draw } from "../models/fixture";

export const couponTotal = (round: DetailedRound, coupon: Coupon, stakeMultiplier: number) => {

    let operatorFeePerRow = 0;
    if (round.operatorFeePerRow !== undefined && round.operatorFeePerRow !== null) {
        operatorFeePerRow = round.operatorFeePerRow.amount;
    }

    let costPerRow = Math.round(100 * (Number(coupon.stakePerRow.amount) + Number(operatorFeePerRow))) / 100;

    return reduce((coupon.bets || []).map(b =>
        betTotal(round.reducedSystems, b)), (x, y) => x + y, 0)
        * costPerRow * stakeMultiplier

}

export const betTotal = (reducedSystems: ReducedSystem[], bet: Bet) => {
    switch (bet.system.type) {
        case 'MATHEMATICAL':
            return reduce((bet.legs || []).map(l => Math.max(l.signs.length, 1)), (x, y) => x * y, 1);

        case 'REDUCED':
            const rs = find(reducedSystems, r => r.id === bet.system.reducedSystemId);

            if (!rs) {
                return 0;
            }

            return rs.nRows;

        default:
            return 0;
    }
}

export function getWholeAndHalfPutsFromArray(arr: Leg[]) {
    let wholePuts = 0;
    let halfPuts = 0;

    for (let i = 0; i < arr.length; i++) {
        const { signs } = arr[i];
        const { length } = signs || [];

        if (length === 3) {
            wholePuts += 1;
        } else if (length === 2) {
            halfPuts += 1;
        }
    }

    return {
        wholePuts,
        halfPuts
    };
}

const formatFraction = (v: number) => `${(v * 100).toFixed(0)}%`;

export const getDrawText = (draw: Draw, t: any) => {

    if(draw.status === "FINISHED"){
        return t('is_drawn');
    }

    let home = draw.outcomes.filter(d => d.sign === "ONE");
    let x = draw.outcomes.filter(d => d.sign === "X");
    let away = draw.outcomes.filter(d => d.sign === "TWO");

    if (home === undefined || x === undefined || away === undefined) {
        return t('is_draw');
    }

    return t('is_draw') + " " + formatFraction(home[0].probability) + " " +
        formatFraction(x[0].probability) + " " + formatFraction(away[0].probability)
}

export async function get_average_rgb(src: string): Promise<Uint8ClampedArray> {
    /* https://stackoverflow.com/questions/2541481/get-average-color-of-image-via-javascript */
        return new Promise(resolve => {
            let context = document.createElement('canvas').getContext('2d');
            context!.imageSmoothingEnabled = true;
    
            let img = new Image();
            img.src = src;
            img.crossOrigin = "";
    
            img.onload = () => {
                context!.drawImage(img, 0, 0, 1, 1);
                resolve(context!.getImageData(0, 0, 1, 1).data.slice(0,3));
            };
        });
    }