import React from 'react';
import fetchApi from "../backend/fetchApi";
import {useAuth} from "../auth/authContext";
import {useQuery} from "react-query";

const ApiContext = React.createContext([])

const ApiProvider = (props) => {
  const {authenticated} = useAuth();

  const info = useQuery('api', () => {
    return fetchApi("/")
      .then(response => response.json())
  }, {
    enabled: authenticated
  })

  return <ApiContext.Provider value={[info.data]} {...props} />
}

function useApi() {
  const context = React.useContext(ApiContext)
  if (!context) {
    throw new Error(`useApi must be used within a ApiProvider`)
  }
  return context
}

export {ApiProvider, useApi}
