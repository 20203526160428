import styled from 'styled-components';

export const TeamWrapper = styled.div`
  background: var(--list-boxes-primary);
  margin: 13px 0;
  padding: 16px 26px 20px 19px;
  font-size: 15px;
  width: 540px;
  color: var(--text-black);

  .top-div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px var(--off-black) solid;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .bold-span {
    font-weight: bold;
  }

  .bottom-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .column-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: bold;
  }

  .total-span {
    margin-top: 2px;
  }

  .icon {
    height: 15px;
    width: 15px;
  }

  .link-div {
    display: flex;
    color: var(--text-black);
    text-decoration: none;
  }

  .numb-members {
    margin-left: 40px;
  }

  .centered-bottom-div {
    display: flex;
    justify-content: center;
    height: 34px;
    align-items: center;
  }
`;