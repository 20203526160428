import React, { useState } from 'react';
import { ReducedSystemSelectorWrapper } from './styles';
import { ExpandMoreArrowIcon, ExpandLessArrowIcon, Button } from '../../../../../reusable-components';
import { useTranslation } from "react-i18next";
import { getReducedFiendlyName } from '../../../../../common/RoundHelper';

const ReducedSystemSelector = ({ reducedSystems, onChange, onReset, numGames }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSystem, setSelectedSystem] = useState('');
  const { t } = useTranslation();

  function getReducedForGuarantee(reducedSystems, guarantee) {

    const systems12 = [
      'R_0_7_16',
      'R_9_0_1356',
      'R_3_3_24',
      'R_4_0_9',
      'R_4_4_144',
    ];

    const systems11 = [
      'R_5_3_36',
      'R_9_0_243',
      'R_5_5_108',
      'R_2_5_12',
      'R_10_0_567',
      'R_6_3_72',
      'R_11_0_729',
      'R_7_0_36',
      'R_4_4_24',
      'R_7_2_108',
      'R_4_5_48',
      'R_4_6_72',
      'R_8_0_81',
      'R_8_2_324',
      'R_4_7_144',
      'R_8_3_504',
      'R_9_0_222',
      'R_5_0_18'
    ];

    let systems = [];

    reducedSystems.forEach(system => {

      if (guarantee === -1 && systems12.indexOf(system.name) > -1)
        systems.push(system);

      if (guarantee === -2 && systems11.indexOf(system.name) > -1)
        systems.push(system);

    });

    return systems.sort((a, b) => a < b);
  }

  return (
    <ReducedSystemSelectorWrapper>
      <div onClick={() => {
        setSelectedSystem('')
        onReset();
      }} className="no-system cursor">
        <span className="bold white">{t('no_system')}</span>
      </div>
      <div className="grid-wrapper">

        <div className="flex">
          <span className="bold">R-system</span>
          <span onClick={() => setIsOpen(!isOpen)} className="cursor">{isOpen ? ExpandLessArrowIcon : ExpandMoreArrowIcon}</span>
        </div>
        {isOpen && (
          <div>
            <br></br>
            <span className="bold">{numGames - 1} - {t('system_guarantee')}</span>
            <div className="grid">
              {getReducedForGuarantee(reducedSystems, -1).map(({ name }, i) => <div onClick={() => setSelectedSystem(name)} className={`system cursor ${selectedSystem === name ? 'selected' : ''}`} key={`rs ${i}`}>{getReducedFiendlyName(name)}</div>)}
            </div>
            <br></br>
            <span className="bold">{numGames - 2} - {t('system_guarantee')}</span>
            <div className="grid">
              {getReducedForGuarantee(reducedSystems, -2).map(({ name }, i) => <div onClick={() => setSelectedSystem(name)} className={`system cursor ${selectedSystem === name ? 'selected' : ''}`} key={`rs ${i}`}>{getReducedFiendlyName(name)}</div>)}
            </div>
            <br></br>
            <span className="bold">R-{t('full_cover')}-{t('half_cover')}-{t('number_of_rows')}</span>
          </div>
        )}
      </div>     

      {selectedSystem !== '' && <Button type="button" onClick={() => onChange(selectedSystem)}>{t('select')}</Button>}

      <div className='info'>
        <p>
        {t('reduced_disclaimer')}
        </p>
      </div>
    </ReducedSystemSelectorWrapper>
  );
}

export default ReducedSystemSelector;