export const API_FR = {    
    format: {        
        title:"File format",
        p1: "The file should be a text file",
        p2: "The first row should contain the id for the round. You can find the id in the information page for the round or in the list above.",
        p3: "The file should contain single rows. The first sign should be E",
        p4: "Each row in the file contains one system row.",        
        p5: "The games are seperated by comma signs" 
    },
    example:{
        title:"Example",
        row1:"123",
        row2:"E,1,2,X,1,2,1,2,X,1,1,1,1,1",
        row3:"E,1,2,X,1,2,1,2,X,1,1,1,1,1"
    },
    info:"Upload a file with system rows"
}