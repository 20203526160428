import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

function CountdownTimer({targetTime, fontSize, width}) {
      
  // State to store the remaining time
  const [timeLeft, setTimeLeft] = useState(targetTime - Date.now());

  const smallTitle = {
    fontSize: fontSize,
    color: 'var(--text-black)',
    lineHeight: "20px",
    fontWeight: "700",
    fontFamily: 'var(--font-title)',
    width: width
}

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const timeRemaining = targetTime - currentTime;

      if (timeRemaining <= 0) {
        clearInterval(intervalId);
        setTimeLeft(0);
      } else {
        setTimeLeft(timeRemaining);
      }
    }, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, [targetTime]);

  // Helper function to format time
  const formatTime = (time) => {
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Typography sx={smallTitle}>{formatTime(timeLeft)}</Typography>    
  );
}

export default CountdownTimer;