import NavBar from "../navbar";
import { useTranslation } from "react-i18next";
import useShallowRounds from "../rounds/useShallowRounds";
import RoundHistoryItem from "./roundhistoryitem";
import { Grid, Pagination, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { ShallowRound } from "../rounds/types";
import { useState } from "react";

const NoRounds = () => {
  const { t } = useTranslation();

  var containerStyle: SxProps<Theme> = {
    padding: "20px",
    flexDirection: "var(--flex-direction)",
    marginBottom: "5px",
  }

  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" sx={containerStyle}>
      <Typography sx={{ fontWeight: "bold", color: "var(--text-black-dark-background)" }}>{t('no_rounds_available')}</Typography>
    </Grid>
  );
}

function sortRounds(roundA: ShallowRound, roundB: ShallowRound) {

  if (roundA.openTo === undefined || roundA.openTo === undefined) {
    return 0;
  }

  if (roundA.openTo > roundB.openTo) {
    return 1;
  }

  if (roundA.openTo < roundB.openTo) {
    return -1;
  }
  return 0;
}


function RoundHistory() {

  const [pageActive, setPageActive] = useState(0);
  const [pageSettled, setPageSettled] = useState(0);

  const [active] = useShallowRounds(true, false, pageActive);
  const [settled] = useShallowRounds(false, true, pageSettled);

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery('(max-width: 620px)');

  const activeRounds = active && active.rounds.filter(round => (round.status === 'ACTIVE' || round.status === 'PAUSED')).sort(sortRounds);
  const finishedRounds = settled && settled.rounds.filter(round => (round.status === 'FINISHED' || round.status === 'VOIDED')).sort((a, b) => sortRounds(b, a));

  var wrapperStyle: SxProps<Theme> = {
    paddingBottom: "120px",
    background: "var(--list-background)",
    overflowY: "auto"
  }

  let contentStyle: SxProps<Theme> = {
    background: "var(--list-background)",
    width: "595px",
    marginTop: "100px"
  }

  let paginationStyle: SxProps<Theme> = {
    marginTop: "20px",
    "& .MuiPaginationItem-root": {
      color: "var(--pagination-text)",
      backgroundColor: "var(--pagination-background)"
    }
    ,
    "& button[aria-current=true]": {
      color: "var(--pagination-selected-text)",
      backgroundColor: "var(--pagination-selected-background)"
    },
  }

  if (isSmallScreen) {
    contentStyle.width = "90%"
    contentStyle.marginTop = "50px"
  }

  return (
    <>

      <NavBar whichToUnderline="4" balanceUpdateId="0" />

      <Grid container direction="column" alignItems="center" sx={wrapperStyle}>

        <Grid sx={contentStyle}>

          <Grid container sx={{ flexDirection: "var(--flex-direction)" }}>
            <Typography sx={{ fontWeight: "bold", color: "var(--text-black-dark-background)" }}>{t('current_rounds')}</Typography>
          </Grid>

          <Grid sx={{ marginTop: "18px" }}>
            {activeRounds !== undefined && activeRounds.length > 0 ?
              activeRounds.map((round, i) => {
                return (
                  <RoundHistoryItem key={`activeRound ${i}`} active={true} round={round} />);
              }) :
              <NoRounds />}
          </Grid>
          <Grid container justifyContent="center">
            <Pagination sx={paginationStyle} count={active?.totalPages} onChange={(e, page) => setPageActive(page - 1)} />
          </Grid>
        </Grid>

        <Grid sx={contentStyle}>

          <Grid container sx={{ flexDirection: "var(--flex-direction)" }}>
            <Typography sx={{ fontWeight: "bold", color: "var(--text-black-dark-background)" }}>{t('settled_rounds')}</Typography>
          </Grid>

          <Grid sx={{ marginTop: "18px" }}>
            {finishedRounds !== undefined && finishedRounds.length > 0 ?
              finishedRounds.map((round, i) => {
                return (
                  <RoundHistoryItem key={`finishedRound ${i}`} active={false} round={round} />);
              }) :
              <NoRounds />}
          </Grid>
          <Grid container justifyContent="center">
            <Pagination sx={paginationStyle} count={settled?.totalPages} onChange={(e, page) => setPageSettled(page - 1)} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default RoundHistory;
