import fetchApi, { getApiUrl, getCurrentToken } from "../backend/fetchApi";
import { usePlayer } from "../player/playerContext";
import { CreateCouponResponse, RoundCoupon } from "./types";

const apiUrl = getApiUrl();

const usePurchaseWithBody = () => {
  const [player] = usePlayer() as any;

  return (body: string | FormData) => {
    return fetchApi(player._links.coupons.href, {
      method: 'POST',
      body: body,
    }).then(response => {
        if (response.ok) {
            return response.json()
        }
        return Promise.reject(response);
    })
      .then((couponResult: CreateCouponResponse) => {
      });
  }

}

export const usePurchaseCoupon = () => {
  const purchaseWithBody = usePurchaseWithBody();

  return (coupon: RoundCoupon) => purchaseWithBody(JSON.stringify(coupon))
}

export const usePurchaseFromFile = () => {
  const purchaseWithBody = usePurchaseWithBody();
  return (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return purchaseWithBody(formData);
  }
}

export const useSimplePurchase = (round: any, stakeMultiplier: number) => {
  const [player] = usePlayer() as any;
  const headers = new Headers({
    Accept: 'application/json',
  });

  const token = getCurrentToken();
  if (token) {
    headers.set('Authorization', "Bearer " + token);
  }

  let feePerRow = round.operatorFeePerRow;
  round.stakeMultiplier = stakeMultiplier;

  return async (coupon: any) => {
    try {
      headers.set('Content-Type', 'application/json');

      coupon.feePerRow = feePerRow;
      coupon.stakeMultiplier = Number(stakeMultiplier);

      const res = await fetch(new URL(player._links.coupons.href, apiUrl + "/").href.replace(/\/$/, ""), {
        headers,
        method: 'POST',
        body: JSON.stringify(coupon),
      });

      return res;
    }
    catch (err) {
      return console.error(err);
    }
  }
}
