import styled from 'styled-components';

export const ReducedSystemSelectorWrapper = styled.div`
  .grid-wrapper {
    background: var(--background_2);
    width: 310px;
    padding: 20px;
    margin: 10px 0 16px 0;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .grid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 4px;
    margin-top: 18px;
  }

  .system {
    border: solid 1px var(--off-black);
    padding: 11px 11px 9px 11px;
    font-weight: bold;
  }

  .cursor {
    cursor: pointer;
  }

  .white {
    color: var(--background_2);
  }

  .selected {
    background: var(--background_4);
  }

  .no-system {
    height: 50px;
    width: 330px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background: var(--off-black);
    color: var(--text-black);
  }

  .info {    
    width: 330px;
    display: flex;    
    align-items: flex-start;     
    text-align: left;   
  }
`;