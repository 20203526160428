import useCouponShares from "../couponShares/useCouponShares";
import NavBar from "../navbar";
import { useTranslation } from "react-i18next";
import Progress from "../common/progress";
import { Grid, Pagination, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { themeC } from '../theme/themehelper';
import CouponShareListItemTomato from './couponsharelistitemtomato';
import { useState } from "react";
import CouponShareListItem from "./couponsharelistitem2";

const NoCoupons = () => {
  const { t } = useTranslation();

  var containerStyle: SxProps<Theme> = {
    padding: "20px",
    flexDirection: "var(--flex-direction)",
    marginBottom: "5px",
  }

  return (
    <Grid container direction="row" alignItems="center" justifyContent="center" sx={containerStyle}>
      <Typography sx={{ fontWeight: "bold", color: "var(--text-black-dark-background)" }}>{t('no_coupons')}</Typography>
    </Grid>
  );
}

const CouponShareList = () => {

  const [pageActive, setPageActive] = useState(0);
  const [pageSettled, setPageSettled] = useState(0);

  const [couponSharesResponseActive] = useCouponShares(true, false, pageActive, 10);
  const [couponSharesResponseSettled] = useCouponShares(false, true, pageSettled, 10);
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery('(max-width: 620px)');

  if (couponSharesResponseActive === undefined || couponSharesResponseSettled === undefined) {
    return (
      <>
        <NavBar whichToUnderline="3" balanceUpdateId="0" />
        <div style={{ paddingTop: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Progress />
        </div>
      </>
    )
  }

  const activeCoupons = couponSharesResponseActive.couponShares;
  const settledCoupons = couponSharesResponseSettled.couponShares;

  let isTomato = window.theme === themeC.Tomato;

  if (isTomato) {
    return (

      <>
        <NavBar whichToUnderline="3" balanceUpdateId="0" />
        <Grid container sx={{ backgroundColor: 'var(---list-background)', justifyContent: 'center', marginBottom: '20px' }}>
          <Grid>
            {activeCoupons.length > 0 ? activeCoupons.map((couponShare, i) => {

              return (<CouponShareListItemTomato key={'active' + i} active={true} couponShare={couponShare} />)

            }
            ) : <div></div>}

            {settledCoupons.length > 0 ? settledCoupons.map((couponShare, i) => {

              return (<CouponShareListItemTomato key={'settled' + i} active={false} couponShare={couponShare} />)

            }
            ) : <div></div>}
          </Grid>
        </Grid >
      </>
    )
  }

  var wrapperStyle: SxProps<Theme> = {
    paddingBottom: "120px",
    background: "var(--list-background)",
    overflowY: "auto"
  }

  let contentStyle: SxProps<Theme> = {
    background: "var(--list-background)",
    width: "595px",
    marginTop: "100px"
  }

  let paginationStyle: SxProps<Theme> = {
    marginTop: "20px",
    "& .MuiPaginationItem-root": {
      color: "var(--pagination-text)",
      backgroundColor: "var(--pagination-background)"
    }
    ,
    "& button[aria-current=true]": {
      color: "var(--pagination-selected-text)",
      backgroundColor: "var(--pagination-selected-background)"
    },
  }

  if (isSmallScreen) {
    contentStyle.width = "90%"
    contentStyle.marginTop = "50px"
  }

  return (
    <>
      <NavBar whichToUnderline="3" balanceUpdateId="0" />
      <Grid container direction="column" alignItems="center" sx={wrapperStyle}>

        <Grid sx={contentStyle}>
          <Grid container sx={{ flexDirection: "var(--flex-direction)" }}>
            <Typography sx={{ fontWeight: "bold", color: "var(--text-black-dark-background)" }}>{t('unsettled_coupons')}</Typography>
          </Grid>

          <Grid sx={{ marginTop: "18px" }}>
            {activeCoupons !== undefined && activeCoupons.length > 0 ? activeCoupons.map((couponShare, i) =>
              <CouponShareListItem key={`couponShare ${i}`} isActive={true} couponShare={couponShare} t={t} />) :
              <NoCoupons />}
          </Grid>
          <Grid container justifyContent="center">
            <Pagination sx={paginationStyle} count={couponSharesResponseActive?.totalPages} onChange={(e, page) => setPageActive(page - 1)} />
          </Grid>
        </Grid>

        <Grid sx={contentStyle}>
          <Grid container sx={{ flexDirection: "var(--flex-direction)" }}>
            <Typography sx={{ fontWeight: "bold", color: "var(--text-black-dark-background)" }}>{t('settled_coupons')}</Typography>
          </Grid>

          <Grid sx={{ marginTop: "18px" }}>
            {settledCoupons !== undefined && settledCoupons.length > 0 ? settledCoupons.map((couponShare, i) =>
              <CouponShareListItem key={`settledcouponshare ${i}`} isActive={false} couponShare={couponShare} t={t} />)
              : <NoCoupons />}
          </Grid>
          <Grid container justifyContent="center">
            <Pagination sx={paginationStyle} count={couponSharesResponseSettled?.totalPages} onChange={(e, page) => setPageSettled(page - 1)} />
          </Grid>
        </Grid>

      </Grid >
    </>
  )
}

export default CouponShareList;
