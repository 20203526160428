import styled from 'styled-components';

export const Wrapper = styled.div`
  .green-div {
    background: var(--list-background));
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 42px;
  }

  .title-margin {
    margin-bottom: 10px;
  }

  .button-margin {
    margin: 33px 0;
  }
`;

