
import { H2 } from "../../reusable-components";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./styles";
import AgentSyndicateDiv from "../agentsyndicatediv";
import NewSyndicateForm from "../newsyndicateform";
import moment from "moment";

const AgentSyndicateList = ({ agent, syndicates, shallowrounds }) => {

  const { t } = useTranslation();

  let syndicatesWithRound = [];

  syndicates.forEach(syndicate => {

    let round = shallowrounds.filter(r => r.id === syndicate.roundId)[0];

    if (round) {
      syndicatesWithRound.push({ syndicate: syndicate, round: round })
    }

  });

  syndicatesWithRound.sort((a,b)=>{
    return moment(b.round.openTo) - moment(a.round.openTo)
  })

  let activeSyndicates = syndicatesWithRound.filter(s=>s.round.status === "ACTIVE");
  let settledSyndicates = syndicatesWithRound.filter(s=>s.round.status !== "ACTIVE");

  return (
    <Wrapper>
      <div className="green-div">
        <NewSyndicateForm agent={agent} shallowrounds={shallowrounds} />

        <H2 className="title-margin">{t('syndicates')}</H2>
        {(agent && syndicates && shallowrounds &&
          activeSyndicates.map((syndicate, i) => {            
            return (
              <AgentSyndicateDiv key={`${i} agentsyndicatediv`} syndicate={syndicate.syndicate} round={syndicate.round} agent={agent} settled={false}/>
            )
          }
          ))
        }

        <H2 className="title-margin">{t('settled_syndicates')}</H2>

        {(agent && syndicates && shallowrounds &&
          settledSyndicates.map((syndicate, i) => {           
            return (
              <AgentSyndicateDiv key={`${i} agentsyndicatediv`} syndicate={syndicate.syndicate} round={syndicate.round} agent={agent} settled={true}/>
            )
          }
          ))
        }
      </div>
    </Wrapper>
  );

}

export default AgentSyndicateList;