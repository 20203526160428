import { SyndicateCouponWrapper } from './styles';
import { SignBoxWrapper } from '../../reusable-components';
import MediaQuery from "react-responsive";
import { Grid } from '@mui/material';


const SyndicateCoupon = ({
  syndicate,
  round
}) => {


  if (round === undefined) {
    return (<></>);
  }

  const roundOpenTo = Date.parse(round.openTo);
  const now = new Date().getTime();
  let showResults = false;
  if (now > roundOpenTo) {
    showResults = true;
  }

  return (

    <SyndicateCouponWrapper>
      <Grid container direction="column" sx={{alignItems:"center"}}>
      <div className="grid-div">
        <div className="match-div">
          <div className="text-div">
            <span className="match-margin">#</span>
            <span className="match-title">Match</span>
          </div>
          {round.fixtures.map((fixture, i) => {

            return (
              <div className={`match-iteration-div ${(((i + 1) % 2) > 0) ? 'green-bg' : ''}`} key={`teamName ${i}`}>
                <span className="match-margin">{i + 1}</span>
                <MediaQuery maxWidth={950}>
                  <div className="team-names">
                    <span>{fixture.homeTeam.name}</span>
                    <span>{fixture.awayTeam.name}</span>
                  </div>
                </MediaQuery>
                <MediaQuery minWidth={951} >
                  <div className="team-names">
                    <span>{fixture.homeTeam.name} - {fixture.awayTeam.name}</span>
                  </div>
                </MediaQuery>
              </div>
            );
          })}
        </div>

        <div className="systems-div">
          <div className="text-div">
            <span className="result-margin">System</span>
          </div>
          {
          
          syndicate.pendingRoundCoupon.bets[0].legs.map((arr, i) => {
            let isSecond = ((i) % 2) === 0;

            let isOne = false;
            let isX = false;
            let isTwo = false;

            if (showResults && round.preliminaryCorrectRow) {
              isOne = round.preliminaryCorrectRow[i] === 'ONE';
              isX = round.preliminaryCorrectRow[i] === 'X';
              isTwo = round.preliminaryCorrectRow[i] === 'TWO';
            }

            return (
              <div className={`match-iteration-div system-width ${isSecond ? 'green-bg' : ''}`} key={`sign ${i}`}>
                <SignBoxWrapper greenBorder={isOne} selected={arr.signs.indexOf('ONE') > -1}>1</SignBoxWrapper>
                <SignBoxWrapper greenBorder={isX} selected={arr.signs.indexOf('X') > -1}>X</SignBoxWrapper>
                <SignBoxWrapper greenBorder={isTwo} selected={arr.signs.indexOf('TWO') > -1}>2</SignBoxWrapper>
              </div>
            );
          })}
        </div>
      </div>
      </Grid>

    </SyndicateCouponWrapper>

  );
}


export default SyndicateCoupon;