
import { Grid, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import betBeto from '../images/Betbeto_900_150.png'
import betBetoMobile from '../images/Betbeto_300_350.png'

function LandingBanner({ theme }: { theme: string }) {
    const isSmallScreen = useMediaQuery('(max-width: 950px)');
    
    let width = "900px";
    let height = "150px";

    let backgroundImage = `url(${betBeto})`;
    if (isSmallScreen) {
        backgroundImage = `url(${betBetoMobile})`;
    }

    let boxInPicture: SxProps<Theme> = {
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--text-white)",
        padding: "20px",
        marginTop: isSmallScreen ? "-70px" : ""
    };

    let text: SxProps<Theme> = {
        fontFamily: "var(--font-title)",
        fontSize: "30px",
        letterSpacing: "var(--font-title-letter-spacing)",
        lineHeight: "30px",
        fontWeight: "var(--font-title-weight)",
        margin: 0,
        textTransform: "uppercase",
        color: "var(--text-white)",
    }

    return (
        <Link to={`/roundhistory/`} style={{ textDecoration: 'none' }}>
            <Grid container sx={{
                width: isSmallScreen ? "300px" : width,
                height: isSmallScreen ? "350px" : height,
                background: backgroundImage,
                backgroundSize: "cover",
                justifyContent: "center",
                alignItems: "center",
                backgroundPosition: "center center",
                marginTop: "30px",
                color: "var(--text-white)",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px"
            }} >
                <Grid container justifyContent="center" >
                    <Grid item sm={12} height="100%">
                        <Grid container direction="column" alignItems="center" sx={boxInPicture}>
                                <Typography sx={text} textAlign="center">
                                    በዕለታዊ ጃክፓት ጥቅል በ10ብር 1,000,000 ብር ያሸንፋ
                                </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    );

}

export default LandingBanner

