
const FALCON_SUBDOMAIN = 'digitain';
const FALCON_API_URL = 'https://api.falcon.poolxbet.com';
const DIGITAIN_PROD_API_URL = 'https://api.digitain.live.poolxbet.com';
const MONITORSVC_HOST = 'tracking.monitorsvc.com';
const MONITORSVC_API_URL = 'https://api.tracking.monitorsvc.com';

const apiUrl = process.env["REACT_APP_API_URL"];

export const getApiUrl = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0]

  if (subdomain === FALCON_SUBDOMAIN) {
    if (host === 'pariplay.poolx.dev') {
      return FALCON_API_URL;
    } else {
      return DIGITAIN_PROD_API_URL;
    }
  }

  if (host === MONITORSVC_HOST) {
    return MONITORSVC_API_URL
  }

  return apiUrl;
}

export const fetchApiRaw = (url, options) => {
  return fetch(new URL(url, getApiUrl() + "/").href.replace(/\/$/, ""), options);
}

const getOperatorId = () => {
  const optId = sessionStorage.getItem("operatorId");
  if (optId) {
    return optId;
  }
  // If operator id not found in session storage, attempt to get it using URL param
  let searchParams = new URLSearchParams();
  return searchParams.get("operatorId");
}

const getOperatorCurrency = () => {
  const key = 'operatorCurrency';
  const operatorCurrency = sessionStorage.getItem(key);
  if (operatorCurrency) {
    return operatorCurrency;
  }

  let searchParams = new URLSearchParams();
  return searchParams.get(key);
}

const getOperatorExtraData = () => {
  const key = 'operatorExtraData';
  const operatorExtraData = sessionStorage.getItem(key);
  if (operatorExtraData) {
    return operatorExtraData;
  }

  let searchParams = new URLSearchParams();
  return searchParams.get(key);
}


export const getCurrentToken = () => {
  const operatorId = getOperatorId();
  return localStorage.getItem(btoa("token" + operatorId));
}

const fetchApi = (url, options = {}) => {
  const headers =
    new Headers({
      Accept: 'application/json',
      ...(options.headers || {})
    });
  if (
    !headers.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET'))) {
    if (options && options.body && options.body instanceof FormData) {
      // Set automatically with boundary header
    } else {
      headers.set('Content-Type', 'application/json');

    }
  }
  headers.set('X-OperatorID', getOperatorId());
  headers.set('X-OperatorCurrency', getOperatorCurrency());
  headers.set('X-OperatorExtraData', getOperatorExtraData());

  const token = getCurrentToken();
  if (token) {
    headers.set('Authorization', "Bearer " + token);
  }

  return fetchApiRaw(url, {...options, headers});
};

export default fetchApi;
